import React, { useEffect, useState } from 'react';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import BlogContent from '../../common/BlogContent';
import { firestore } from '../../config/firebase';

const Terms = () => {
  const [terms, setTerms] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(firestore, 'footer'), where('name', '==', 'Terms & Conditions'));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs[0]?.data();
      setTerms(data);
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-4xl p-4 md:p-0 mx-auto mt-8">
      <Helmet>
        <title>Carsnus - Terms & Conditions</title>
      </Helmet>
      <BlogContent content={terms?.content} />
    </div>
  );
};

export default Terms;
