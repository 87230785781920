/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { MessageList } from 'react-chat-elements';
import { useParams } from 'react-router-dom';

import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getMessages } from '../api/functions';
import { auth, firestore } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<ChatScreen />
============================================================================= */
const ChatScreen = () => {
  const messageList = useRef();
  const { conversationId } = useParams();
  const { user, userId, updateUser } = useContext(UserContext);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getMessages(conversationId, async (payload, unseenCount) => {
      setMessages(payload.reverse());
      if (user?.unread_messages > 0 && unseenCount > 0) {
        await updateUser({
          unread_messages: user?.unread_messages - unseenCount,
        });
      }
    });
    _scrollToBottom();
  }, []);

  const messagesCollection = collection(
    firestore,
    `conversations/${conversationId}/messages`,
  );
  const getMessageRef = query(messagesCollection, where('createdAt', '>', new Date().toString()));

  // Message Listener
  useEffect(() => {
    const subscribe = onSnapshot(getMessageRef, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        const data = change?.doc?.data();

        if (change.type === 'added') {
          setMessages((prev) => [
            ...prev,
            {
              type: 'text',
              text: data?.text,
              date: data?.createdAt,
              position: data?.author === auth?.currentUser?.uid ? 'right' : 'left',
            },
          ]);
          if (!data.mark_as_seen && data?.author !== userId) {
            const messageRef = doc(messagesCollection, data?._id);
            await updateDoc(messageRef, { mark_as_seen: true });
            if (user?.unread_messages > 0) {
              await updateUser({
                unread_messages: user?.unread_messages - 1,
              });
            }
          }
        }
      });
    });
    return subscribe;
  }, []);

  const _scrollToBottom = () => messageList.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <MessageList
      lockable
      downButton
      dataSource={messages}
      referance={messageList}
      className="message-list mt-2"
      notchStyle={{ fill: '#f9f9f9' }}
      onDownButtonClick={_scrollToBottom}
      messageBoxStyles={{ backgroundColor: '#f9f9f9', color: '#000' }}
    />
  );
};

/* Export
============================================================================= */
export default ChatScreen;
