import React from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';

export default function MyDisclosure({ title, children, onClickDelete }) {
  return (
    <div className="w-full">
      <Disclosure as="div" defaultOpen={false}>

        <div className="bg-zinc-400/10 rounded-md p-3 flex gap-2 items-center">
          <DisclosureButton className="group text-zinc-800 font-medium flex w-full items-center justify-between">
            <span className="text-sm/6 font-medium ">{title}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 text-blue-500 group-data-[open]:rotate-180"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </DisclosureButton>
        </div>
        <DisclosurePanel className="mt-2 text-sm/5">{children}</DisclosurePanel>
      </Disclosure>
    </div>
  );
}
