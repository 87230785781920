import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import 'react-chat-elements/dist/main.css';

import ChatScreen from './ChatScreen';
import ChatsScreen from './ChatsScreen';

/* =============================================================================
<ChatScreens />
============================================================================= */
const ChatScreens = () => (
  <>
    <Helmet>
      <title>Chat | Carsnus</title>
    </Helmet>
    <Switch>
      <Route path="/chat" exact>
        <ChatsScreen />
      </Route>
      <Route path="/chat/:conversationId" exact>
        <ChatScreen />
      </Route>
      {/* <Route path="*">
        <Notfound />
      </Route> */}
    </Switch>
  </>
);

/* Export
============================================================================= */
export default ChatScreens;
