import React from 'react';
import { Check } from 'react-feather';

import { Container, Header } from '../../common';
import * as Colors from '../../config/colors';

/* =============================================================================
<TopPostIntructionScreen />
============================================================================= */
const TopPostIntructionScreen = () => (
  <Container>
    <Header title="How Top List Works" stack />
    <div className="space-y-10 max-w-xs mx-auto my-10">
      <div className="flex items-center space-x-8">
        <Check color={Colors.secondary} size={40} />
        <p className="text-white text-2xl font-medium">
          Get on top of Listing
        </p>
      </div>
      <div className="flex items-center space-x-8">
        <Check color={Colors.secondary} size={40} />
        <p className="text-white text-2xl font-medium">
          Appear in feature list
        </p>
      </div>
      <div className="flex items-center space-x-8">
        <Check color={Colors.secondary} size={40} />
        <p className="text-white text-2xl font-medium">
          Get more expositions
        </p>
      </div>
      <div className="flex items-center space-x-8">
        <Check color={Colors.secondary} size={40} />
        <p className="text-white text-2xl font-medium">
          Sell Faster
        </p>
      </div>
    </div>
  </Container>
);

/* Export
============================================================================= */
export default TopPostIntructionScreen;
