import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { fetchData, saveData } from '../util/functions';

export const LocationContext = createContext({});

/* =============================================================================
<LocationProvider />
============================================================================= */
export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const getLocation = await fetchData('location');
        if (getLocation) {
          setLocation(getLocation);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchLocation();
  }, []);
  const saveLocation = async (loc) => {
    setLocation(loc);
    await saveData('location', loc);
  };
  return (
    <LocationContext.Provider
      value={{
        location,
        saveLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);
