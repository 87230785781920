import React from 'react';

import { dealer } from '../../static/payments-data';

/* =============================================================================
<PostDealerPackges />
============================================================================= */
const PostDealerPackges = ({ routeKey, onPackageSelect }) => (
  <div className="space-y-5 mt-10 w-full">
    {dealer[routeKey]?.map((item, i) => (
      <button
        type="button"
        key={item.description}
        onClick={() => onPackageSelect(item)}
        className="w-full border border-secondaryDark overflow-hidden bg-gradiantColor3 pb-2 rounded-lg hover:bg-gray-800"
      >
        <div className="mb-2 bg-secondaryDark">
          <h1 className="text-center text-lg text-white">
            {item.title}
          </h1>
        </div>
        <p className="text-center text-lg text-white">
          {`${item?.description}\n$${item.price}`}
        </p>
      </button>
    ))}
  </div>
);

export default PostDealerPackges;
