/* eslint-disable valid-typeof */
import React, { useContext, useEffect, useState } from 'react';
import {
  query,
  orderBy,
  startAfter,
  collection,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import { PaginationList } from '../../../common';
import { auth, firestore } from '../../../config/firebase';
import { UserContext } from '../../../Provider/UserProvider';
import HistorySearchHistoryItem from './HomeSearchHistoryItem';

/* =============================================================================
<HomeSearchHistory />
============================================================================= */
const HomeSearchHistory = ({ onSearchSelect, containerStyles }) => {
  const { guestId } = useContext(UserContext);
  const userId = auth?.currentUser?.uid;

  const searchHistoryCollection = collection(
    firestore,
    `searchHistory/${userId || guestId}/history`,
  );
  const searchHistoryRef = query(
    searchHistoryCollection,
    orderBy('createdAt', 'desc'),
  );
  const {
    data,
    isLoading,
    hasNextPage,
    isRefetching,
    fetchNextPage,
    refetch: getHistory,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    ['searchHistory'],
    searchHistoryRef,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(searchHistoryRef, startAfter(lastDocument?.createdAt));
    },
  );

  const flattenData = data?.pages
    ? data?.pages?.flatMap(page => [...page])?.filter(d => !d?.system)
    : [];

  useEffect(() => {
    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = (item) => (
    <HistorySearchHistoryItem
      item={item}
      onSearchSelect={onSearchSelect}
      containerStyles={containerStyles}
    />
  );

  return (
    <PaginationList
      data={flattenData}
      loading={isLoading}
      renderItem={renderItem}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      emptyText="No Search History Found"
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};

/* Export
============================================================================= */
export default HomeSearchHistory;
