import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';

import { Header } from '../../common';
import * as Colors from '../../config/colors';

/* =============================================================================
<PostHeader />
============================================================================= */
const PostHeader = ({
  postTitle,
  totalPosts,
  onPrevPress,
  onNextPress,
  currentIndex,
}) => (
  <Header stack childrenContainerClass="md:relative">
    <h1 className="text-white text-4xl font-semibold text-center">{postTitle}</h1>
    <div className="h-10 mx-10 md:w-1/2 md:mx-auto mt-2" />
    <div className="absolute top-3 md:-top-2 right-5 md:right-5 lg:right-10 flex items-center space-x-2 md:space-x-5">
      <button type="button" onClick={onPrevPress}>
        <ArrowLeft className="h-6 w-6  lg:h-12 lg:w-12" color={Colors.white} />
      </button>
      <h2 className="text-white md:text-xl text-semibold">
        {`${currentIndex + 1} of ${totalPosts}`}
      </h2>

      <button type="button" onClick={onNextPress}>
        <ArrowRight className="h-6 w-6 lg:h-12 lg:w-12" color={Colors.white} />
      </button>
    </div>
  </Header>
);

PostHeader.defaultProps = {
  currentIndex: 0,
  totalPosts: 0,
  onPrevPress: () => {},
  onNextPress: () => {},
};

/* Export
============================================================================= */
export default PostHeader;
