/* eslint-disable max-len */
import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useAuthSignOut } from '@react-query-firebase/auth';

import { RWebShare } from 'react-web-share';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { Container, Header } from '../../common';
import { client } from '../../config/react-query';
import { UserContext } from '../../Provider/UserProvider';
import SettingsOptions from '../components/SettingsOptions';
import SettingsCurrentUserInfo from '../components/SettingsCurrentUserInfo';

/* =============================================================================
<SettingsScreen />
============================================================================= */
const SettingsScreen = () => {
  const history = useHistory();
  const { getUser, logout } = useContext(UserContext);
  // LOGOUT CALL
  // const { mutate: logout } = useAuthSignOut(auth, {
  //   onError(error) {
  //     toast.errors(error.message);
  //   },
  //   onSuccess() {
  //     history.push('/');
  //     client.removeQueries({ queryKey: ['users-query'] });
  //     client.removeQueries({ queryKey: ['myPosts'] });
  //     toast.success('You have signed out from your CARSNUS account.');
  //     getUser();
  //   },
  // });

  const _handleLogout = () => {
    signOut(auth);
    history.push('/');
    client.removeQueries({ queryKey: ['users-query'] });
    client.removeQueries({ queryKey: ['myPosts'] });
    toast.success('You have signed out from your CARSNUS account.');
  };

  return (
    <Container>
      <Header containerClass="h-16" title="Account" stack />
      <div className="flex flex-col md:flex-row justify-between md:max-w-screen-lg mt-4 md:items-center md:mx-auto pb-8 xsm:pb-4">
        <SettingsCurrentUserInfo />
        <SettingsOptions />
      </div>
      <div className="h-px w-full bg-gray-400 mt-3 md:mt-0 flex justify-center items-center">
        <div className="h-10 px-10 flex items-center bg-[#1DA1F2] rounded-md">
          <RWebShare
            data={{
              text: '',
              url: 'https://carsnus-1262c.web.app',
              title: 'Carsnus',
            }}
            onClick={() => {}}
          >
            <button type="button" className="w-full flex-1">
              <p className="text-center text-white">Share this website</p>
            </button>
          </RWebShare>
        </div>
      </div>
      <div className="flex flex-col items-start space-y-5 max-w-screen-lg mx-auto px-4 md:px-0 my-10">
        {Links?.map((item) => (
          <button
            key={item.title}
            type="button"
            className="py-1 pr-20 mx-4"
            onClick={async () => {
              if (item.to) {
                await history.push(item.to);
              }
              if (item.title === 'Log Out') {
                _handleLogout();
              }
            }}
          >
            <p className="text-white text-xl font-medium">
              {item.title}
            </p>
          </button>
        ))}
      </div>
    </Container>
  );
};

const Links = [
  {
    title: 'Payment History',
    to: '/payment/history',
  },
  {
    title: 'How Post Work',
    to: '/settings/feature-instructions',
  },
  {
    title: 'Terms & Condition',
    to: '/settings/terms-and-conditions',
  },
  {
    title: 'Privacy Policy',
    to: '/settings/privacy-policy',
  },
  {
    title: 'Blog',
    to: '/settings/blog',
  },
  {
    title: 'Log Out',
  },
];

/* Export
============================================================================= */
export default SettingsScreen;
