import React from 'react';
import { NavLink } from 'react-router-dom';

import { Container, Header } from '../../common';
import { postCategories } from '../../static/post';

/* =============================================================================
<PostCategorySelectScreen />
============================================================================= */
const PostCategorySelectScreen = () => (
  <Container>
    <Header title="Select a Category" containerClass="h-[4.5rem] !bg-gradient-to-t  !relative !from-black !via-red-600 !via-60% !to-primary !to-30%" stack />
    <div className="grid grid-cols-2 gap-6 md:grid-cols-3  max-w-screen-md -mt-2 md:mt-6 mb-6 px-5 md:mx-auto xsm:py-3">
      {postCategories.map(item => (
        <NavLink
          key={item.id}
          // to={`/post/create/${item.id}`}
          to={`/payment/post/${item.id}`}
          className="border-2 border-zinc-300 space-y-2 rounded-xl flex flex-col h-52 justify-center items-center xsm:h-40 xsm:rounded-3xl"
        >
          {item.icon}
          <h1 className="text-center text-zinc-100 md:text-white text-xl">
            {item.title}
          </h1>
        </NavLink>
      ))}
    </div>
  </Container>
);

/* Export
============================================================================= */
export default PostCategorySelectScreen;
