import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Link, useHistory,
} from 'react-router-dom';

export const Tabs = ({ blogs }) => {
  const [tab, setTab] = useState('');

  const { push } = useHistory();

  const filterData = () => {
    if (!tab) return blogs;
    return blogs
      .filter(item => item.category === tab)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };

  return (
    <>
      <div className="font-semibold text-center text-zinc-800 text-sm md:text-base  border-b border-gray-200">
        <ul className="flex overflow-auto whitespace-nowrap -mb-px">
          <li className="me-2">
            <button
              type="button"
              onClick={() => {
                push('/blog');
                setTab('');
              }}
              className={classNames(
                'inline-block p-4 focus:outline-none border-b-2',
                {
                  'border-[#555b66] text-[#555b66]': !tab,
                },
              )}
            >
              All
            </button>
          </li>
          <li className="me-2">
            <button
              type="button"
              onClick={() => {
                push('/blog?category=review');
                setTab('Car review');
              }}
              className={classNames(
                'inline-block p-4 focus:outline-none border-b-2',
                {
                  'border-[#555b66] text-[#555b66]': tab === 'Car review',
                },
              )}
            >
              Car Reviews
            </button>
          </li>
          <li className="me-2">
            <button
              type="button"
              onClick={() => {
                push('/blog?category=news');
                setTab('Car news');
              }}
              className={classNames(
                'inline-block p-4 focus:outline-none  border-b-2',
                {
                  'border-[#555b66] text-[#555b66]': tab === 'Car news',
                },
              )}
            >
              Car News
            </button>
          </li>
          <li className="me-2">
            <button
              type="button"
              onClick={() => {
                push('/blog?category=comparison');
                setTab('Comparison');
              }}
              className={classNames(
                'inline-block p-4 focus:outline-none border-b-2',
                {
                  'border-[#555b66] text-[#555b66]': tab === 'Comparison',
                },
              )}
            >
              Comparison
            </button>
          </li>
          <li className="me-2">
            <button
              type="button"
              onClick={() => {
                push('/blog?category=suvs-and-trucks');
                setTab('SUVs and Trucks');
              }}
              className={classNames(
                'inline-block p-4 focus:outline-none border-b-2',
                {
                  'border-[#555b66] text-[#555b66]': tab === 'SUVs and Trucks',
                },
              )}
            >
              SUVs and Trucks
            </button>
          </li>
          <li className="me-2">
            <button
              type="button"
              onClick={() => {
                push('/blog?category=sportscar');
                setTab('Sportscar/Tips');
              }}
              className={classNames(
                'inline-block p-4 focus:outline-none border-b-2',
                {
                  'border-[#555b66] text-[#555b66]': tab === 'Sportscar/Tips',
                },
              )}
            >
              Sportscar/Tips
            </button>
          </li>
        </ul>
      </div>
      <div className="mt-6 px-5 md:px-0 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filterData().map(item => (
          <Link key={item.id} to={`/blog/${item.slug}`}>
            <div className="h-56 group  hover:bg-white hover:drop-shadow-xl hover:shadow-xl rounded-xl  w-full relative  overflow-hidden border-gray-300">
              <img
                src={item?.thumbnail}
                alt=""
                className="h-full transition-transform duration-300 transform hover:scale-125 w-full object-cover rounded-xl overflow-hidden "
              />
              <div className="absolute peer mask-image-gradient p-4 w-full rounded-b-xl bottom-0 bg-black bg-opacity-80">
                <h4 className="text-lg text-white">
                  {item?.title.slice(0, 50)}
                  ...
                </h4>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};
