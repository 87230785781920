/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
import { useInfiniteQuery } from 'react-query';
import { collection, doc, Timestamp } from 'firebase/firestore';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';
import request from '../../util/request';
import { auth, firestore } from '../../config/firebase';
import { fetchItems, filterByDistance, filterCars } from '../../util/functions';
import { client } from '../../config/algolia';

/**
 * useGetSearchedPosts
 */

export const useGetSearchedPosts = (query, filters, guestId, location) => useInfiniteQuery(
  ['searchPosts', query],
  async ({ pageParam = 0 }) => {
    const response = await client.searchSingleIndex({
      indexName: 'posts',
      searchParams: {
        query,
        page: pageParam,
        hitsPerPage: 10,
      },
    });
    return response.hits;
  },
  {
    select: payload => {
      const flattenData = payload?.pages
        ? payload?.pages
          ?.flatMap(page => [...page])
          .sort((a, b) => Number(b?.isFeatured) - Number(a?.isFeatured))
          .filter((v) => {
            const searchFilter = `${v.year} ${v.make} ${v.model}`?.toLowerCase();
            return searchFilter.includes(query.trim()?.toLowerCase());
          })
        : [];
      const filterByDistanceData = filterByDistance(location, flattenData);
      const filteredData = filterCars(filterByDistanceData, filters);

      return filteredData;
    },
    enabled: false,
    getNextPageParam: ({ pagination }) => (
      pagination?.has_more_pages ? pagination.page + 1 : undefined),
  },
);
