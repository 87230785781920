import React, { useCallback, useContext, useState } from 'react';
import { Search, User } from 'react-feather';
import { useHistory } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Header,
  Input,
  Spinner,
  AuthModal,
  LocationButton,
  Modal,
} from '../../../common';

import ProfileDropDown from './ProfileDropDown';
import HomeHeaderCategories from './HomeHeaderCategories';
import { UserContext } from '../../../Provider/UserProvider';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

/* =============================================================================
<HomeHeader />
============================================================================= */
const HomeHeader = ({ onFilterClick }) => {
  const history = useHistory();
  const { control } = useForm();
  const { width } = useWindowDimensions();
  const { user, loading } = useContext(UserContext);
  const [authPage, setAuthPage] = useState(null);
  const _toggleAuthPage = useCallback((page) => setAuthPage(page), []);

  const _handlePostPress = () => {
    if (user) {
      history.push('/post/category-select');
      // if (user?.postPackage?.numberOfPosts === 0) {
      //   history.push('/payment/post');
      // } else {
      //   history.push('/post/category-select');
      // }
    } else {
      _toggleAuthPage('WELCOME TO CARSNUS');
    }
  };

  return (
    <Header appLogo childrenContainerClass="px-4">
      <div className="flex flex-col-reverse lg:flex-row  justify-between items-center space-x-2 md:space-x-5 ">
        <div className="flex-1" />
        <div className="flex items-center w-full lg:w-auto justify-center space-x-2 py-3 lg:py-0">
          <button type="button" className="w-96" onClick={() => history.push('/search')}>
            <Input
              variant="search"
              placeholder="Find any Vehicle"
              icon={<Search color="#fff" size={18} />}
              customStyleClasses="!px-5"
            />
          </button>
          <Controller
            name="location"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationButton showDistance locTitleStyle="hidden sm:block" onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className="flex items-center w-full min-h-[3rem] lg:w-auto  justify-end flex-1 sm-1:my-2 py-1 xsm:mt-px space-x-5">
          {user && (
            <Button title="Post a Car" variant="white" onClick={_handlePostPress} customStyleClasses="!py-1" customTxtStyles="xsm:text-base" />
          )}
          {loading ? <Spinner color="white" size={18} /> : (
            <>
              {!user ? (
                <>
                  <div className="lg:hidden">
                    <button
                      type="button"
                      onClick={() => {
                        if (width > 760) {
                          _toggleAuthPage('WELCOME TO CARSNUS');
                        } else {
                          history.push('/login');
                        }
                      }}
                    >
                      <User size={27} color="#fff" className="xsm:mt-2" />
                    </button>
                  </div>
                  <div className="hidden lg:block">
                    <Button
                      title="Sign in / Register"
                      loading={loading}
                      variant="white"
                      onClick={() => {
                        if (width > 760) {
                          _toggleAuthPage('WELCOME TO CARSNUS');
                        } else {
                          history.push('/login');
                        }
                      }}
                    />
                  </div>
                </>

              ) : (
                <ProfileDropDown />
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex justify-between space-x-5 lg:mt-4 items-center flex-row xl:flex-row">
        <HomeHeaderCategories />
        <div className="xsm:hidden">
          <Button title="Stories" variant="secondary" onClick={() => history.push('/stories')} />
        </div>
      </div>
      <h1 id="headerTitle" className="text-white text-2xl mb-2 md:my-3 ">Cars for Sale</h1>
      <AuthModal page={authPage} onPageOpen={_toggleAuthPage} />
    </Header>
  );
};

/* Export
============================================================================= */
export default HomeHeader;
