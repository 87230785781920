import React, { useState } from 'react';
import { Send } from 'react-feather';

import { Input } from '../../common';

/* =============================================================================
<ChatInput />
============================================================================= */
const ChatInput = ({ onSend }) => {
  const [text, setText] = useState('');

  const _handleSubmit = (e) => {
    e.preventDefault();
    if (text) {
      onSend(text);
    }
    setText('');
  };

  return (
    <form onSubmit={_handleSubmit} className="flex items-center pt-2 space-x-2 mx-4 justify-between">
      <Input
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Type a message..."
      />
      <button type="submit" className="w-10 h-10 flex items-center justify-center rounded-full bg-primary">
        <Send color="#fff" size={18} />
      </button>
    </form>
  );
};

/* Export
============================================================================= */
export default ChatInput;
