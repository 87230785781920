import React, { useContext, useState } from 'react';
import { doc } from 'firebase/firestore';
import { useHistory, useParams } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';

import toast from 'react-hot-toast';
import { FaArrowRightLong } from 'react-icons/fa6';
import PaymentModal from '../components/PaymentModal';
import { UserContext } from '../../Provider/UserProvider';
import { useCreatePayment } from '../api/mutations';
import { auth, userCollectionRef } from '../../config/firebase';
import { Header, Container, Tabs } from '../../common';
import PostDealerPackges from '../components/PostDealerPackages';
import { seller } from '../../static/payments-data';
import * as Colors from '../../config/colors';
/* =============================================================================
<PostPackagesScreen />
============================================================================= */
const PostPackagesScreen = () => {
  const navigation = useHistory();
  const stripe = useStripe();
  const param = useParams();

  const elements = useElements();
  const currentUserId = auth?.currentUser?.uid || 'none';
  const [selectedPackage, setSelectedPackage] = useState('');
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // USER GET
  const { user, getUser } = useContext(UserContext);
  const userType = user?.type;

  // UPDATE USER CALL
  const docRef = doc(userCollectionRef, currentUserId);
  const { mutateAsync: updateUser } = useFirestoreDocumentMutation(docRef, {
    merge: true,
  });

  // BUY POST PACKAGE CALL
  const { mutateAsync: createdPackagePayment } = useCreatePayment();

  const _togglePaymentModal = () => setPaymentModal(prev => !prev);
  // const _handlePackageSelect = _package => {
  //   if (_package?.priceId !== 'free') {
  //     _togglePaymentModal();
  //   } else {
  //     toast.error(
  //       'You only have 3 free posts, you must buy a package to make another post.',
  //     );
  //   }
  //   setSelectedPackage(_package);
  // };
  const handleFreeTrial = async _package => {
    await updateUser({
      postPackage: {
        numberOfPosts: _package.posts,
        packageId: _package.priceId,
        freeTrial: 'consumed',
      },
    });
    toast.success(
      'Congratulations 🎉, Your free Post Package has been selected',
    );
    getUser();
    navigation.push(`/post/create/${param.id}`);
    // navigation.navigate('CreatePost', { category: params?.category });
  };
  const _handlePackageSelect = async _package => {
    const { postPackage } = user;
    const freeTrialConsumed = postPackage?.freeTrial === 'consumed';
    const isFreePackage = _package.priceId === 'free';
    const isCurrentPackage = _package.priceId === postPackage.packageId;
    const hasPostsRemaining = postPackage?.numberOfPosts > 0;

    if (!freeTrialConsumed && isFreePackage) {
      handleFreeTrial(_package);
    } else if ((!isCurrentPackage || !hasPostsRemaining) && !isFreePackage) {
      _togglePaymentModal();
    } else if (isCurrentPackage && hasPostsRemaining) {
      navigation.push(`/post/create/${param.id}`);
      // navigation.navigate('CreatePost', { category: params?.category });
    } else if (
      freeTrialConsumed
      && isFreePackage
      && (!isCurrentPackage || !hasPostsRemaining)
    ) {
      toast.error('You Free Trial has been consumed');
    }
    setSelectedPackage(_package);
  };

  const _handlePayment = async name => {
    try {
      if (!stripe || !elements) {
        return;
      }

      setLoading(true);

      const { clientSecret } = await createdPackagePayment(
        {
          priceId: selectedPackage?.priceId,
          type: 'postPackage',
        },
      );

      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
          },
        },
      });

      if (error) {
        throw new Error(error?.localizedMessage);
      }

      if (paymentIntent.status === 'succeeded') {
        await updateUser({
          postPackage: {
            packageId: selectedPackage?.priceId,
            numberOfPosts: selectedPackage?.posts,
          },
        });

        toast.success('Your Package is been updated');

        _togglePaymentModal();
        navigation.push(`/post/create/${param.id}`);
        // navigation.push('/post/category-select');
      }
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header title="Select a Package" stack />
      <div className="max-w-screen-md md:mx-auto mx-5 mb-10 space-y-10 py-10">
        {/* <h1 className="text-white text-3xl xsm:text-2xl font-semibold text-center">
          Selected Package
        </h1> */}
        {userType === 'private seller' && (
          <SellerPackages
            onPackageSelect={_handlePackageSelect}
            currentPackage={user?.postPackage}
          />
        )}
        {userType === 'dealer' && (
          <DealerPackages onPackageSelect={_handlePackageSelect} />
        )}
      </div>
      <PaymentModal
        isVisible={paymentModal}
        title={selectedPackage?.title}
        loading={loading}
        onCancel={_togglePaymentModal}
        onSubmit={_handlePayment}
      />
    </Container>
  );
};

const SellerPackages = ({ onPackageSelect, currentPackage }) => {
  const { numberOfPosts } = currentPackage;
  const selectedPackage = item => currentPackage.packageId === item.priceId;

  return (
    <>
      {seller.map(item => (
        <button
          type="button"
          onClick={() => onPackageSelect(item)}
          key={item.description}
          className="w-full border border-secondaryDark overflow-hidden bg-gradiantColor3 pb-2 rounded-lg hover:bg-gray-800"
        >
          <div className="mb-2 bg-secondaryDark">
            <h1 className="text-center text-lg text-white">
              {item.title}
            </h1>
          </div>
          <p className="text-center text-lg text-white">
            {`${item?.description}\n$${item.price}`}
          </p>
          {selectedPackage(item) && numberOfPosts > 0 && (
            <div className=" flex space-x-2 items-center justify-end px-2">
              <h6 className="text-white">Continue</h6>
              <FaArrowRightLong size={18} color="white" />
            </div>
          )}
        </button>
      ))}
    </>
  );
};

const DealerPackages = ({ onPackageSelect }) => {
  const [selectedTab, setSelectedTab] = useState('Month');
  const [routes] = useState([
    {
      key: 'month',
      name: 'Month',
    },
    {
      key: 'year',
      name: 'Year',
    },
  ]);

  return (
    <div>
      <Tabs
        tabs={routes}
        isActive={selectedTab}
        onChange={setSelectedTab}
        switchToDropdownOnSmallScreens={false}
      />
      {selectedTab === 'Month' && (
        <PostDealerPackges
          routeKey="month"
          onPackageSelect={onPackageSelect}
        />
      )}
      {selectedTab === 'Year' && (
        <PostDealerPackges
          routeKey="year"
          onPackageSelect={onPackageSelect}
        />
      )}
    </div>
  );
};

/* Export
============================================================================= */
export default PostPackagesScreen;
