import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export const SideSheet = ({
  title,
  sidebarOpen,
  setSidebarOpen,
  handleClickPrimary,
  children,
}) => (
  <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={setSidebarOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/20" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md border-l border-zinc-200">
                <div className="flex h-full flex-col overflow-y-auto bg-white">
                  <Dialog.Title className="flex gap-4 items-center justify-between font-libre-b border-b py-3.5 px-6">
                    <p className="text-xl text-zinc-600 font-semibold">{title}</p>
                    <button
                      type="button"
                      className="text-gray-600 focus:outline-none"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </Dialog.Title>
                  <div className="h-full flex-1 px-6 py-4">{children}</div>

                  <div className="border-t flex gap-4 p-5">
                    <button
                      type="submit"
                      className="px-4 py-2 w-full bg-green-500 text-white rounded-md"
                      onClick={() => handleClickPrimary && handleClickPrimary()}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="px-4 bg-primary text-white rounded-md py-1.5 w-full "
                      onClick={() => setSidebarOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
