import React from 'react';
import classNames from 'classnames';
import { Info } from 'react-feather';

const SelectButton = ({
  data, value, onChange, label, multiSelect = false,
}) => {
  const handleSelection = (item) => {
    let selectedValues = value ? value.split(',').map(val => val.trim()) : [];

    if (multiSelect) {
      if (selectedValues.includes(item)) {
        selectedValues = selectedValues.filter(v => v !== item);
      } else {
        selectedValues.push(item); // Add item if not selected
      }
      onChange(selectedValues.join(', ')); // Join selected values with comma and space
    } else {
      onChange(selectedValues.includes(item) ? '' : item); // Toggle single item
    }
  };
  const selectedValuesArray = value ? value.split(',').map(val => val.trim()) : [];

  return (
    <div>
      <p htmlFor={label} className="block text-lg font-medium text-white">
        {label}
      </p>
      <div className="flex gap-4 overflow-x-auto mt-2">
        {data.map(item => {
          const isSelected = selectedValuesArray.includes(item);

          return (
            <button
              key={item}
              className={classNames(
                'text-white focus:outline-none flex items-center justify-center gap-2 rounded-full whitespace-nowrap px-6 py-2 ',
                isSelected
                  ? 'bg-secondary ' // Selected state
                  : 'bg-gradient-to-b border border-white from-gray-900 to-black hover:border-secondary hover:bg-secondary hover:from-transparent hover:to-transparent', // Default state
              )}
              type="button"
              onClick={() => handleSelection(item)}
            >
              {item}
              {/* {isSelected && <X size={17} />} */}
            </button>
          );
        })}
      </div>
      {/* {!!errorText && (
        <div className="flex items-center space-x-1 mt-2">
          <Info color="#dc2626" size={20} />
          <p className="text-md font-medium  py-white text-red-500">
            {errorText}
          </p>
        </div>
      )} */}
    </div>
  );
};

export default SelectButton;
