import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

export const NavLink = ({
  active, to, icon, label, className,
}) => (
  <div className="flex items-center group">
    <div
      className={cn({
        'w-1 h-8  bg-red-400 rounded-md': active,
      })}
    />
    <Link
      to={to}
      className={cn(
        `flex py-1 px-8 items-center w-full ${className}`,
        {
          'text-zinc-700  ': !active,
        },
      )}
    >
      <div
        className={cn(
          'flex items-center  gap-x-6 w-full rounded-lg p-3',
          {
            'text-red-500 bg-red-300/20 bg-opacity-20':
                                active,
            'hover:text-red-500 text-zinc-700 hover:bg-red-400/20': !active,
          },
        )}
      >
        {icon}
        {label}
      </div>
    </Link>
  </div>
);
