/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { ChevronLeft } from 'react-feather';
import {
  Modal,
  SearchInput,
} from '../../common';

/* =============================================================================
<SearchModal />
============================================================================= */
const SearchModal = ({
  visible,
  onClose,
  localSearch,
  searchTxt,
  onSearch,

}) => (
  <Modal
    showHeader={false}
    visible={visible}
    onClose={onClose}
    childrenStyles="!p-0"
    transitionChildStyles="xsm:p-0 !h-full"
    customStyles="xsm:relative h-2/3 xsm:h-full !w-full xsm:rounded-none"
  >
    <div className="flex-1 flex items-center justify-between pt-8 pb-7 pl-3 pr-5  space-x-3 bg-gradient-to-t from-black to-lightBlack' : 'bg-gradient-to-t from-black to-primary">
      <button type="button" onClick={onClose}>
        <ChevronLeft color="white" size={35} />
      </button>
      <SearchInput
        localSearch={localSearch}
        searchTxt={searchTxt}
        placeholder="Search any vehicle"
        onSearch={onSearch}
      />
    </div>
  </Modal>
);

/* Export
============================================================================= */
export default SearchModal;
