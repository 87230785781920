import React from 'react';
import moment from 'moment';
import { doc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import { Avatar } from '../../common';
import { userCollectionRef } from '../../config/firebase';

/* =============================================================================
<StoryReplyCard />
============================================================================= */
const StoryReplyCard = ({ reply }) => {
  const navigation = useHistory();
  const authorId = reply?.author || 'none';
  const createdAt = reply?.createdAt;
  const text = reply?.text;

  // GET AUTHOR
  const authorRef = doc(userCollectionRef, authorId);
  const { data: author } = useFirestoreDocumentData(
    ['users', authorId],
    authorRef,
  );

  return (
    <button type="button" className="space-x-2 pt-5 w-full text-left rounded-lg flex items-center" onClick={() => navigation.push(`/profile/${authorId}`)}>
      <Avatar source={author?.avatar} size="medium" />
      <div className="flex-1">
        <h1 className="text-sm text-medium text-white">
          {author?.name}
        </h1>
        <ShowMoreText lines={4} className="text-white text-left mb-1 text-sm">
          <p className="text-white text-md">
            {`${text}`}
          </p>
        </ShowMoreText>
        <p className="text-xs text-left text-white">{`${moment(createdAt?.toDate()).fromNow()}`}</p>
      </div>
    </button>
  );
};

export default StoryReplyCard;
