import React, { useContext, useEffect, useState } from 'react';
import {
  Bell,
  Heart,
  Inbox,
  List,
  MessageSquare,
  Pocket,
} from 'react-feather';
import { useHistory } from 'react-router-dom';
import {
  limit,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';

import { UserContext } from '../../Provider/UserProvider';
import { notificationsCollectionRef, savedAlertsCollectionRef } from '../../config/firebase';

/* =============================================================================
<SettingsOptions />
============================================================================= */
const SettingsOptions = () => {
  const history = useHistory();
  const { userId, user } = useContext(UserContext);
  const [notifications, setNotifications] = useState(0);
  const [savedAlerts, setAlerts] = useState(0);

  const notificationsRef = query(
    notificationsCollectionRef,
    where('userId', '==', userId),
    limit(1),
  );

  const savedAlertsRef = query(
    savedAlertsCollectionRef,
    where('userId', '==', userId),
    limit(1),
  );

  useEffect(() => {
    onSnapshot(notificationsRef, (notificationsDoc) => {
      notificationsDoc.forEach((snap) => setNotifications(snap.data()));
    });
    onSnapshot(savedAlertsRef, (savedAlertsDoc) => {
      savedAlertsDoc.forEach((snap) => setAlerts(snap.data()));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col md:flex-row flex-wrap  md:space-x-5 md:justify-center ">
      <button type="button" className="mb-0 rounded-lg px-6 md:px-0 hover:bg-neutral-600 bg-opacity-20 md:mb-5 flex md:block items-center relative" onClick={() => history.push('/chat')}>
        <div
          className="md:w-12 md:h-12 w-16 h-16 mb-2 md:mr-auto md:ml-auto flex items-center md:justify-center rounded-full "
        >
          {user?.unread_messages > 0 && (
            <div className="absolute top-0 md:right-0 bg-primary md:w-7 md:h-7 h-6 w-6 rounded-full flex justify-center items-center">
              <p className="text-white text-lg">
                {user?.unread_messages}
              </p>
            </div>
          )}
          <MessageSquare size={30} color="white" />
        </div>
        <p className=" px-3 py-1.5 rounded-full  text-white text-md md:text-lg font-semibold md:text-center">
          Message
        </p>
      </button>

      <button
        type="button"
        className="mb-0 px-6 md:px-0 rounded-lg hover:bg-neutral-600 bg-opacity-20 md:mb-5 flex md:block items-center relative"
        onClick={() => history.push('/post/my-posts')}
      >
        <div
          className="md:w-12 md:h-12 w-16 h-16 mb-2 md:mr-auto md:ml-auto flex items-center md:justify-center rounded-full "
        >
          <List size={30} color="white" />
        </div>
        <p className="hover:bg-neutral-600 px-3 py-1.5 rounded-full bg-opacity-20 text-white text-md md:text-lg font-semibold text-center">
          My Post
        </p>
      </button>

      <button
        type="button"
        className="mb-0 px-6 md:px-0 rounded-lg hover:bg-neutral-600 bg-opacity-20 md:mb-5 flex md:block items-center relative"
        onClick={() => history.push('/favorites')}
      >
        <div
          className="md:w-12 md:h-12 w-16 h-16 mb-2 flex md:mr-auto md:ml-auto items-center md:justify-center rounded-full"
        >
          <Heart size={30} color="white" />
        </div>
        <p className="hover:bg-neutral-600 px-3 py-1.5 rounded-full bg-opacity-20 text-white text-md md:text-lg font-semibold text-center">
          Favorites
        </p>
      </button>

      <button
        type="button"
        className="mb-0 px-6 md:px-0 rounded-lg hover:bg-neutral-600 bg-opacity-20 md:mb-5 flex md:block items-center relative"
        onClick={() => history.push('/saved-alerts')}
      >
        <div
          className="md:w-12 md:h-12 w-16 h-16 mb-2 flex items-center md:mr-auto md:ml-auto md:justify-center rounded-full"
        >
          {savedAlerts?.unreadCount > 0 && (
            <div className="absolute top-0 right-0 bg-primary w-10 h-10 rounded-full flex justify-center items-center">
              <p className="text-white text-lg">
                {savedAlerts?.unreadCount}
              </p>
            </div>
          )}
          <Pocket size={30} color="white" />
        </div>
        <p className="hover:bg-neutral-600 px-3 py-1.5 rounded-full bg-opacity-20 text-white text-md md:text-lg font-semibold text-center">
          Save Alerts
        </p>
      </button>

      <button type="button" className="mb-0 rounded-lg px-6 md:px-0 hover:bg-neutral-600 bg-opacity-20 md:mb-5 flex md:block items-center relative" onClick={() => history.push('/notifications')}>
        <div
          className="md:w-12 md:h-12 w-16 h-16 mb-2 md:ml-auto md:mr-auto flex items-center md:justify-center rounded-full"
        >
          {notifications?.unreadCount > 0 && (
          <div className="absolute top-0 right-0 bg-primary w-10 h-10 rounded-full flex justify-center items-center">
            <p className="text-white text-lg">
              {notifications?.unreadCount}
            </p>
          </div>
          )}
          <Bell size={30} color="white" />
        </div>
        <p className="hover:bg-neutral-600 px-3 py-1.5 rounded-full bg-opacity-20 text-white text-md md:text-lg font-semibold text-center">
          Notification
        </p>
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default SettingsOptions;
