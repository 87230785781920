import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { firestore } from '../../config/firebase';
import { Tabs } from '../../components/Tabs';

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'blogs'));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setBlogs(data);
    };

    fetchData();
  }, []);

  return (
    <div className="container mx-auto">
      <Helmet>
        <title>Carsnus - Blogs</title>
      </Helmet>
      <div className="flex gap-2 mt-4">
        <Link to="/home">Home /</Link>
        <Link to="/blog" className="text-primary">
          Blogs
        </Link>
      </div>
      <div className="h-14 md:h-20 text-center mx-auto flex items-center justify-center">
        <img alt="" src="/blog.png" className="w-full mx-auto h-full object-contain" />
      </div>
      <Tabs blogs={blogs} />
    </div>
  );
};

export default AllBlogs;
