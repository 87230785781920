import React from 'react';
import { classNames } from '../util/functions';

/* =============================================================================
<Container />
============================================================================= */
function Container({ children, classes }) {
  return (
    <div className={classNames('bg-black h-full flex-1 overflow-y-auto ', classes)}>
      {children}
    </div>
  );
}

/* Export ============================================================================= */
export default Container;
