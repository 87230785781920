import React from 'react';
import { Info } from 'react-feather';

/* =============================================================================
<EmptyState />
============================================================================= */
function EmptyState({ title, description }) {
  return (
    <div className="flex flex-col text-center items-center justify-center space-y-1">
      <div><Info color="#fff" size={35} /></div>
      <p className="font-[Inter] text-sm font-medium text-white">
        {title}
      </p>
      <p className="font-[Inter] text-sm font-normal text-white max-w-48">
        {description}
      </p>
    </div>
  );
}

/* Export
============================================================================= */
export default EmptyState;
