import React from 'react';
import { NavLink } from 'react-router-dom';

import { Avatar, Spinner } from '../../common';

/* =============================================================================
<ChatHeader />
============================================================================= */
const ChatHeader = ({ otherUser, isLoading }) => (
  <NavLink to={`/profile/${otherUser?.id}`} className="flex  items-center justify-center py-5 mx-20 xsm:py-3">
    {isLoading ? (
      <Spinner color="#fff" />
    ) : (
      <div className="flex items-center space-x-3">
        <Avatar source={otherUser?.avatar} name={otherUser?.name} size="semi" />
        <h1 className="text-2xl md:text-4xl text-white font-semibold ">
          {otherUser?.name}
        </h1>
      </div>
    )}
  </NavLink>
);

/* Export
============================================================================= */
export default ChatHeader;
