import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { classNames } from '../util/functions';
import chevronRight from '../assets/icons/chevronRight.png';

const Breadcrumb = ({ contents = [], containerStyle }) => (
  <nav className={classNames('text-black  font-bold my-2 whitespace-nowrap', containerStyle)} aria-label="Breadcrumb">
    <ol className="flex list-none items-center space-x-1">
      {contents.map((content, index) => (
        <>
          <li className={`text-gray-200  md:text-lg ${contents.length - 1 === index ? 'truncate' : ''}`} key={index}>
            <NavLink to={content.url}>
              {content.name}
            </NavLink>
          </li>
          <img src={chevronRight} alt="icon" className={`h-4 w-4 object-contain ${contents.length - 1 === index ? 'hidden' : 'block'}`} />
        </>
      ))}
    </ol>
  </nav>
);
export default Breadcrumb;
