import React, { useState } from 'react';

export const Contact = () => {
  const [state, setState] = useState({
    email: '',
    phone: '',
  });
  return (
    <div className="border border-zinc-200 rounded-lg p-3">
      <p className="font-semibold text-lg mb-2 text-zinc-800">Contact us</p>
      <form className="mt-3 max-w-3xl">
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Contact email
            <input
              id="email"
              name="email"
              placeholder="Enter contact email"
              value={state.email}
              onChange={(e) => setState({
                ...state,
                email: e.target.value,
              })}
              type="email"
              className="appearance-none block w-full px-3 mt-1 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </label>
        </div>

        <div className="mt-3">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Phone
            <input
              id="phone"
              name="phone"
              placeholder="Enter phone number"
              value={state.phone}
              onChange={(e) => setState({
                ...state,
                phone: e.target.value,
              })}
              type="text"
              className="appearance-none block w-full px-3 mt-1 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </label>

        </div>
        <button type="submit" className="mt-3 bg-green-600 text-white rounded-md px-3 py-2">
          Save
        </button>
      </form>
    </div>
  );
};
