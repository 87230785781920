import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LandingSidebar } from '../../components/landing/LandingSidebar';
import { Footer } from '../../components/landing/Footer';
import { UserContext } from '../../Provider/UserProvider';
import Spinner from '../Spinner';

export default function LandingLayout({ children }) {
  const { user, loading } = useContext(UserContext);
  return (
    <div className="h-screen  flex flex-col">
      <div className="py-2 md:py-4 border-b border-zinc-200 border-opacity-50 bg-primary">
        <header className="mx-auto container px-4 xl:px-0">
          <div className="flex items-center justify-between">
            <Link to="/home">
              <img
                className="w-20 md:w-32 h-6 md:h-auto "
                src="/carsnus.png"
                alt="logo"
              />
            </Link>
            <div>
              <LandingSidebar />
              {loading ? <Spinner color="white" size={18} />
                : (
                  <div id="menu" className="md:block lg:block hidden">
                    <ul className="flex md:flex text-3xl md:text-base text-white lg:text-base lg:flex flex-col md:flex-row lg:flex-row justify-center items-center fixed md:relative lg:relative top-0 bottom-0 left-0 right-0 z-20">
                      <li className="mr-4 bg-white rounded-md px-4 py-2 bg-opacity-20 flex items-center">
                        <Link
                          className="hover:text-gray-200 pt-10 md:pt-0"
                          to={user ? '/post/category-select' : '/login'}
                        >
                          Sell a Car
                        </Link>
                      </li>
                      {!user && (
                      <li className="mr-4 bg-white rounded-md px-4 py-2 bg-opacity-20 flex items-center">
                        <Link
                          className="hover:text-gray-200 pt-10 md:pt-0"
                          to="/login"
                        >
                          Login/Register
                        </Link>
                      </li>
                      )}
                    </ul>
                  </div>
                )}
            </div>
          </div>
        </header>
      </div>
      <div className="flex-grow !bg-white">{children}</div>
      <Footer />
    </div>
  );
}
