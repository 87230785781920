import React, { useContext } from 'react';
import { Edit } from 'react-feather';
import { useHistory } from 'react-router-dom';

import { Avatar } from '../../common';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<SettingsCurrentUserInfo />
============================================================================= */
const SettingsCurrentUserInfo = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  const userAvatar = user?.avatar;
  const userName = user?.name;

  const _handleEditPress = () => {
    history.push('/settings/user-edit');
  };

  return (
    <div className="space-y-2 mx-5 flex flex-col items-center mb-5 md:mb-0 xsm:mt-2">
      <div className="relative w-32 flex flex-col items-center justify-center">
        <Avatar
          size="large"
          name={userName}
          source={userAvatar}
        />
        <button type="button" className="absolute flex items-center justify-center right-4 bottom-0  w-7 h-7 rounded-full bg-secondary" onClick={_handleEditPress}>
          <Edit color="white" size={16} />
        </button>
      </div>
      <h2 className="text-white text-center font-medium text-2xl">
        {userName}
      </h2>
    </div>
  );
};

/* Export
============================================================================= */
export default SettingsCurrentUserInfo;
