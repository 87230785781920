import React from 'react';
import { doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Radio, Sun, Sunrise } from 'react-feather';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import { Container, Header, Spinner } from '../../common';
import CarIcon from '../../assets/images/edit-car-icon.png';
import Car2Icon from '../../assets/images/car-2.png';
import { postCollectionRef } from '../../config/firebase';

/* =============================================================================
<PostFeaturesScreen />
============================================================================= */
const PostFeaturesScreen = () => {
  const { id } = useParams();

  const postRef = doc(postCollectionRef, id);
  const { data: post, isLoading } = useFirestoreDocumentData(
    ['posts', id],
    postRef,
  );

  const features = post?.features;

  return (
    <Container>
      <Header title="Features" stack />
      <div className="flex justify-center items-center flex-col my-5 space-y-5">
        <div className="flex flex-col items-center justify-center w-52 h-52 border border-white rounded-full">
          {isLoading ? (
            <Spinner color="#fff" size={22} />
          ) : (
            <>
              <img src={CarIcon} alt="meters" className="w-20 h-20" />
              <p className="text-white text-2xl text-medium">
                {`${post?.miles?.toString() || 0} m`}
              </p>
            </>
          )}
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Spinner color="#fff" size={22} />
          </div>
        ) : (
          features?.map(feature => (
            <div
              key={feature.id}
              className="flex w-48 items-center space-x-5"
            >
              <div className="w-20 h-20 border border-white flex justify-center items-center rounded-full">
                {icons[feature]}
              </div>
              <p className="text-white text-xl text-medium">
                {feature}
              </p>
            </div>
          ))
        )}
      </div>
    </Container>
  );
};

const icons = {
  Sunroof: <Sunrise size={30} color="#F5F5F5" />,
  Wheels: <img src={Car2Icon} alt="wheels" className="w-8 h-8 " />,
  Radio: <Radio size={26} color="#F5F5F5" />,
  Abs: <Radio size={26} color="#F5F5F5" />,
  Aluminum: <Sun size={28} color="#F5F5F5" />,
};

/* Export
============================================================================= */
export default PostFeaturesScreen;
