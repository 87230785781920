import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import PostFeatureScreen from './PostFeatureScreen';
import PostPackagesScreen from './PostPackagesScreen';
import PaymentHistoryScreen from './PaymentHistoryScreen';
import PaymentDetailsScreen from './PaymentDetailsScreen';

/* =============================================================================
<PaymentScreens />
============================================================================= */
function PaymentScreens() {
  return (
    <>
      <Helmet>
        <title>Settings | CARSNUS</title>
      </Helmet>
      <Switch>
        <Route path="/payment/featured/:id">
          <PostFeatureScreen />
        </Route>
        <Route path="/payment/post/:id">
          <PostPackagesScreen />
        </Route>
        <Route path="/payment/history">
          <PaymentHistoryScreen />
        </Route>
        <Route path="/payment/:id">
          <PaymentDetailsScreen />
        </Route>
        {/* <Route path="*">
          <Notfound />
        </Route> */}
      </Switch>
    </>
  );
}

/* Export
============================================================================= */
export default PaymentScreens;
