import React from 'react';

export const Tooltip = ({ message, children }) => (
  <div className="group z-100 relative flex">
    {children}
    <span className=" whitespace-break-spaces break-words overflow-auto absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
      {message}
    </span>
  </div>
);
