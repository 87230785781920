'use client';

import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../Provider/UserProvider';

export const LandingSidebar = () => {
  const { user } = useContext(UserContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSidebarOpen(true);
          }
        }}
        onClick={() => setSidebarOpen(true)}
        className="sm:block md:hidden lg:hidden text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-white"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="4" y1="6" x2="20" y2="6" />
          <line x1="4" y1="12" x2="20" y2="12" />
          <line x1="4" y1="18" x2="20" y2="18" />
        </svg>
      </div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed w-80 inset-0 flex z-10 outline-none"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              role="button"
              aria-label="click"
              tabIndex={0}
              className="fixed blur inset-1/2 bg-black bg-opacity-25 outline-none"
              onClick={() => setSidebarOpen(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSidebarOpen(false);
                }
              }}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="flex-1 flex flex-col max-w-xs w-full h-full outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0  pt-2">
                  <button
                    type="button"
                    className="ml-1 text-white flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex md:flex-shrink-0 h-full">
                <div className="flex flex-col w-80">
                  <div className="flex flex-col flex-grow shadow-sm bg-primary overflow-y-auto">
                    <div className="flex-grow text-white mt-8 font-semibold text-lg flex divide-y divide-zinc-200 divide-opacity-30 flex-col">
                      <p className="px-8 py-3"><Link to="/login"> Sign In</Link></p>
                      <p className="px-8 py-3"><Link to="/register"> Register</Link></p>
                      <p className="px-8 py-3"><Link to={user ? '/post/category-select' : '/login'}> Sell a car</Link></p>
                      <p className="px-8 py-3"><Link to="/about"> About Us</Link></p>
                      <p className="px-8 py-3"><Link to="/contact"> Contact</Link></p>
                      <p className="px-8 py-3"><Link to="/privacy"> Privacy</Link></p>
                      <p className="px-8 py-3"><Link to="/terms"> Terms & Conditions</Link></p>
                      <p className="px-8 py-3"><Link to="/faq"> Frequently Questions</Link></p>
                      <p className="px-8 py-3"><Link to="/dealer"> Become a Dealer</Link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
};
