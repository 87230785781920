import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { firestore } from '../../config/firebase';

export const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'blogs'));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setBlogs(data);
    };

    fetchBlogs();
  }, []);

  return (
    <div className="px-4">
      <div className="text-primary font-semibold text-5xl mb-8 text-center">
        <Link to="/blog">
          <div className="h-20 mx-auto">
            <img alt="" src="/Screenshot.png" className="h-full w-full mx-auto object-contain" />
          </div>
        </Link>
      </div>
      <div className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {blogs.slice(0, 4).map((item) => (
          <Link key={item.id} to={`/blog/${item.slug}`}>
            <div className="h-56 group hover:bg-white hover:drop-shadow-xl  rounded-xl w-full relative  overflow-hidden border-gray-300">
              <img
                src={item?.thumbnail}
                alt=""
                className="transition-transform duration-300 transform hover:scale-125 h-full w-full object-cover rounded-xl overflow-hidden "
              />
              <div className="absolute p-4 w-full rounded-b-xl mask-image-gradient bottom-0 bg-black bg-opacity-80">
                <h4 className="text-lg text-white">
                  {item?.title.slice(0, 70)}
                  ...
                </h4>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
