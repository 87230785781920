import {
  addDoc, collection, doc, getDoc,
  updateDoc,
} from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import slugify from 'slugify';
import { firestore, storage } from '../../config/firebase';
import MyEditor from '../../common/MyEditor';
import withAuth from '../../util/withAuth';

const AddBlog = () => {
  const [state, setState] = React.useState({
    title: '',
    content: '',
    imageUrl: '',
    author: '',
    thumbnail: '',
    category: 'Car review',
  });
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const { push } = useHistory();
  const location = useLocation();

  const { id } = new URLSearchParams(location.search);

  async function fetchData() {
    try {
      // Reference to the document
      const docRef = doc(firestore, 'blogs', id);

      // Get the document
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // Document data
        setState((prev) => ({
          ...prev,
          title: docSnap.data().title,
          content: docSnap.data().content,
          imageUrl: docSnap.data().thumbnail,
          thumbnail: docSnap.data().thumbnail,
          author: docSnap.data().author,
          category: docSnap.data().category,
        }));
      } else {
        // Document not found
        console.log('No such document!');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error getting document:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      setLoading(true);

      fetchData();
    }
  }, [id]);

  const handleBlogSubmit = async () => {
    setSubmitting(true);
    try {
      let thumbnailUrl = '';
      if (typeof state.thumbnail !== 'string') {
        const storageRef = ref(
          storage,
          `blogs/thumbnails/${state.thumbnail.name}`,
        );
        await uploadBytes(storageRef, state.thumbnail);
        thumbnailUrl = await getDownloadURL(storageRef);
      }
      const slug = slugify(state.title, {
        lower: true, // Convert to lowercase
        strict: true, // Remove special characters
        remove: /[*+~.()'"!:@]/g, // Remove additional characters, including colons
      });

      if (id) {
        const body = {
          id,
          title: state.title,
          slug,
          author: state.author,
          category: state.category,
          content: state.content,
          thumbnail: thumbnailUrl || state.thumbnail,
          createdAt: new Date().toDateString(),
        };
        const docRef = doc(firestore, 'blogs', body.id);
        delete body.id;

        // Update the document
        await updateDoc(docRef, body);
      } else {
        await addDoc(collection(firestore, 'blogs'), {
          title: state.title,
          slug,
          author: state.author,
          category: state.category,
          content: state.content,
          thumbnail: thumbnailUrl,
          createdAt: new Date().toDateString(),
        });
      }
      push('/dashboard/blogs');
    } catch (error) {
      console.error('Error adding blog: ', error);
      alert('Error adding blog. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setState((prev) => ({
      ...prev,
      thumbnail: file,
    }));
    reader.onload = () => {
      if (reader.readyState === 2) {
        setState((prev) => ({
          ...prev,
          imageUrl: reader.result,
        }));
      }
    };
    reader.readAsDataURL(file);
  };

  if (loading) {
    return 'Loading...';
  }
  return (
    <div className="flex flex-col gap-4">
      <div>
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Blog Title
          <input
            id="title"
            name="title"
            placeholder="Enter your blog title"
            value={state.title}
            onChange={(e) => setState({
              ...state,
              title: e.target.value,
            })}
            type="text"
            className="appearance-none block w-full px-3 mt-1 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          />
        </label>

      </div>
      <div>
        <label
          htmlFor="author"
          className="block text-sm font-medium text-gray-700"
        >
          Author
          <input
            id="author"
            name="author"
            placeholder="Author name"
            value={state.author}
            onChange={(e) => setState({
              ...state,
              author: e.target.value,
            })}
            type="text"
            className="appearance-none block w-full px-3 mt-1 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          />
        </label>

      </div>
      <div className="">
        <label
          htmlFor="category"
          className="block text-sm font-medium text-gray-700"
        >
          Category
          <select
            defaultValue={state.category}
            onChange={(e) => setState({
              ...state,
              category: e.target.value,
            })}
            className="appearance-none block w-full px-3 mt-1 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          >
            <option value="Car review">Car review</option>
            <option value="Car news">Car news</option>
            <option value="Comparison">Comparison</option>
            <option value="Trucks">Trucks</option>
            <option value="Sportscar/Tips">Sportscar/Tip</option>
          </select>
        </label>

      </div>
      <div className="flex items-center justify-center w-full">
        {state.imageUrl ? (
          <div className="h-96 w-full relative">
            <img
              alt=""
              src={state.imageUrl}
              className="h-full w-full object-cover object-center"
            />
            <button
              type="button"
              onClick={() => {
                setState({
                  ...state,
                  thumbnail: '',
                  imageUrl: '',
                });
              }}
              className="bg-gray-200 rounded-full p-2 absolute right-2 top-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        ) : (
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 "
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload thumbnail</span>
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>
            <input
              onChange={handleUpload}
              id="dropzone-file"
              type="file"
              className="hidden"
            />
          </label>
        )}
      </div>
      <MyEditor
        content={state.content}
        onChange={(value) => setState({
          ...state,
          content: value,
        })}
      />
      <button
        type="button"
        onClick={handleBlogSubmit}
        disabled={submitting}
        className="disabled:bg-opacity-20 bg-primary rounded-md text-white px-4 py-2"
      >
        {submitting ? 'Saving...' : 'Save'}
      </button>
    </div>
  );
};

export default withAuth(AddBlog);
