import React from 'react';

import Button from '../Button';

/* =============================================================================
<Welcome />
============================================================================= */
const Welcome = ({ onPageChange }) => (
  <div className="space-y-5 -mt-4">
    <Button title="Sign in" customStyleClasses="bg-secondaryDark mx-auto w-36" onClick={() => onPageChange('Sign in')} />
    <div className="flex items-center space-x-4">
      <div className="w-full h-px bg-gray-400" />
      <p className="text-white text-lg">Or</p>
      <div className="w-full h-px bg-gray-400" />
    </div>
    <button
      type="button"
      style={{ backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQyTvqc57xROyd7lKTbbS8-MULOkz9mmP9oQ&usqp=CAU)' }}
      onClick={() => onPageChange('Register')}
      className="relative w-full h-52 rounded-xl bg-no-repeat bg-blend-hard-light bg-cover overflow-hidden bg-gray-500"
    >
      <h1 className="text-white text-2xl font-bold">REGISTER AS PRIVATE</h1>
    </button>
    <button
      type="button"
      style={{ backgroundImage: 'url(https://editorial.pxcrush.net/carsales/general/editorial/vfacts-price-cap-increase-2.jpg)' }}
      onClick={() => onPageChange('Dealer Register')}
      className="relative w-full h-52 rounded-xl bg-no-repeat bg-blend-hard-light bg-cover overflow-hidden bg-gray-500"
    >
      <h1 className="text-white text-2xl font-bold">REGISTER AS DEALER</h1>
    </button>
  </div>
);

/* Export
============================================================================= */
export default Welcome;
