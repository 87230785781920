export const grey1 = '#939CAB';
export const grey2 = '#A7AEBA';
export const grey3 = '#242C30';
export const black = '#1C1C1C';
export const white = '#F5F5F5';
export const accent = '#F5F5F55F3';
export const border = '#F1F1F1';
export const ripple = 'rgba(0,0,0,0.2)';
export const success = '#619E4A';
export const warning = '#f2c200';
export const danger = '#85232C';
export const orange = '#E97938';
export const primary = '#be0f02';
export const secondary = '#4472c2';
export const secondaryLight = '#00BEFF';
export const primaryLight = '#d15049';
export const primaryLight10 = 'rgba(209,80,73,0.1)';
export const outline = '#ededed';
export const background = '#FAFAFA';
export const placeholder = '#D1D1D1';
