import React from 'react';
import toast from 'react-hot-toast';
import { ThumbsUp } from 'react-feather';
import { doc } from 'firebase/firestore';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';

import * as Colors from '../../config/colors';
import { client } from '../../config/react-query';
import { auth, storiesCollectionRef } from '../../config/firebase';
import { useSendNotification } from '../../notifications/api/mutations';

/* =============================================================================
<StoryLikeButton />
============================================================================= */
const StoryLikeButton = ({ storyId, authorId, likes }) => {
  const currentUserId = auth?.currentUser?.uid || 'none';

  const isLiked = likes?.find(l => l === currentUserId);

  const { mutateAsync: sendNotification } = useSendNotification();

  // UPDATE Story Likes CALL
  const storyRef = doc(storiesCollectionRef, storyId);
  const { mutateAsync: updatedStory } = useFirestoreDocumentMutation(storyRef, {
    merge: true,
  });

  const _handleLikePress = async () => {
    try {
      if (isLiked) {
        await updatedStory({
          likes: likes?.filter(l => l !== currentUserId),
        });
        client.setQueryData(['stories', storyId], oldStory => ({
          ...oldStory,
          likes: likes?.filter(l => l !== currentUserId),
        }));
      } else {
        await updatedStory({
          likes: [...likes, currentUserId],
        });

        sendNotification({
          to: authorId,
          type: 'like',
          from: currentUserId,
        });
        client.invalidateQueries(['stories']);
      }
    } catch (e) {
      toast.error('Something went wrong', e?.message);
    }
  };

  return (
    <button type="button" className="flex items-center pr-2 py-2 space-x-2" onClick={_handleLikePress}>
      <ThumbsUp color={isLiked ? Colors.primary : '#f1f1f1'} size={22} />
      <p className="text-white">{likes?.length}</p>
    </button>
  );
};

const propsAreEqual = (prevProps, nextProps) => prevProps?.authorId === nextProps?.authorId
  && prevProps?.storyId === nextProps?.storyId
  && prevProps?.likes === nextProps?.likes;

/* Export
============================================================================= */
export default React.memo(StoryLikeButton, propsAreEqual);
