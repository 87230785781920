import React, { useContext, useState } from 'react';
import { useAuthSignInWithEmailAndPassword } from '@react-query-firebase/auth';
import { useHistory } from 'react-router-dom/';
import toast from 'react-hot-toast';
import { UserContext } from '../../Provider/UserProvider';
import { auth } from '../../config/firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { getUser } = useContext(UserContext);

  const {
    isLoading,
    mutate: signInWithEmailAndPassword,
  } = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      if (error.message === 'Firebase: Error (auth/invalid-login-credentials).') {
        toast.error('Invalid login credentials: Check your email or password');
        return;
      }
      toast.error(error.message);
    },
    onSuccess(payload) {
      if (payload.user) {
        getUser();
        history.push('/dashboard');
      }
    },
  });

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword({ email, password });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center ">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white shadow sm:rounded-lg ">
          <div className="bg-primary bg-opacity-90 rounded-t-lg p-5">
            <div className="w-56 md:w-72 mx-auto">
              <img
                alt="logo"
                src="/carsnus.png"
                className="h-full w-full mx-auto object-contain"
              />
            </div>
          </div>
          <form className="space-y-6 py-8 px-4 sm:px-10" onSubmit={onLogin}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    autoComplete="email"
                    required=""
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </label>

            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    autoComplete="current-password"
                    required=""
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </label>

            </div>

            <button
              type="submit"
              className={`${
                isLoading
                  ? 'bg-primary/40 cursor-not-allowed'
                  : ' bg-primary  hover:bg-opacity-80'
              } w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 `}
            >
              {isLoading ? 'Loading...' : 'Sign in'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
