import React, { useContext } from 'react';
import {
  doc, limit, query, where,
} from 'firebase/firestore';
import {
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';
import toast from 'react-hot-toast';
import { Bell, Check } from 'react-feather';

import { Spinner } from '../../common';
import * as Colors from '../../config/colors';
import { auth, savedAlertsCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';
import { classNames } from '../../util/functions';

/* =============================================================================
<HomeCategorySavedButton />
============================================================================= */
const HomeCategorySavedButton = ({ categoryId, customStylesClasses }) => {
  const { userId, user } = useContext(UserContext);

  // GET SAVED ALERT
  const savedAlertQuery = query(
    savedAlertsCollectionRef,
    where('userId', '==', userId),
    limit(1),
  );
  const { data: savedAlert } = useFirestoreQueryData(
    ['savedAlert', userId],
    savedAlertQuery,
    {
      subscribe: true,
    },
    {
      select(snapshot) {
        return snapshot[0];
      },
    },
  );

  // UPDATE SAVED ALERT CALL
  const saveAlertRef = doc(savedAlertsCollectionRef, userId);
  const {
    isLoading,
    mutateAsync: updatedSavedAlert,
  } = useFirestoreDocumentMutation(saveAlertRef, { merge: true });

  const isAdded = savedAlert?.savedCategories?.find(s => s === categoryId);

  const _handleSavePress = async () => {
    try {
      if (user) {
        if (!isAdded) {
          await updatedSavedAlert({
            userId,
            unreadCount: 0,
            savedCategories: [
              ...(savedAlert ? savedAlert?.savedCategories : []),
              categoryId,
            ],
          });
          toast.success('You will be notified with similar post');
        } else {
          await updatedSavedAlert({
            savedCategories: savedAlert?.savedCategories?.filter(
              s => s !== categoryId,
            ),
          });
        }
      } else {
        toast.error('Please Sign in first');
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  return (
    <button type="button" onClick={_handleSavePress} className={classNames('flex items-center space-x-2', customStylesClasses)}>
      {!isLoading ? (
        <>
          <p className="text-white font-semibold xsm:text-sm ">Save</p>
          {isAdded ? (
            <Check color={Colors.primary} size={22} />
          ) : (
            <Bell color="#F5F5F5" size={22} />
          )}
        </>
      ) : (
        <Spinner color="#fff" size={16} />
      )}
    </button>
  );
};

/* Export
============================================================================= */
export default HomeCategorySavedButton;
