import { combineReducers } from 'redux';

import postsReducer from '../post/redux/reducer';

/* ============================================================================
  Combine ALl Reducers
============================================================================= */
const rootReducer = combineReducers({
  Post: postsReducer,
});

export default rootReducer;
