import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => (
  <footer className="bg-primary pt-8 px-4 md:pt-16 mt-8">
    <div className="container mx-auto">
      <div className="border-b pb-5 border-zinc-100 border-opacity-30 grid font-medium grid-cols-2 md:grid-cols-4">
        <div className="flex flex-col gap-4 text-white">
          <Link to="/about">About Us</Link>
          <Link to="/dealer">Become a Dealer</Link>
        </div>
        <div className="flex flex-col gap-4 text-white">
          <Link to="/faqs">Frequently Questions</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
        <div className="flex flex-col gap-4 text-white">
          <Link to="/login" className="mt-3.5 md:mt-0">Sign In</Link>

          <Link to="/terms">Terms & Conditions</Link>
        </div>
        <div className="flex flex-col gap-4 text-white">
          <Link to="/register" className="mt-3.5 md:mt-0">Register</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
      </div>
      <p className="text-center text-white font-medium py-6">2024 &copy; Carsnus LLC All Rights Reserved</p>
    </div>
  </footer>
);
