import {
  collection, getDocs, onSnapshot, query,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { firestore } from '../../config/firebase';
import { BlogsList } from '../../components/dashboard/BlogsList';
import withAuth from '../../util/withAuth';

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Create a query against the collection
    const q = query(collection(firestore, 'blogs'));
    // Real-time updates listener
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const blogsArray = [];

      for (const doc of querySnapshot.docs) {
        const blog = {
          id: doc.id,
          ...doc.data(),
        };
        blogsArray.push(blog);
      }

      setData(blogsArray);
      setLoading(false);
    });

    // Detach listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="place-conent-end place-items-end flex mb-3 text-right">
        <Link
          className="bg-primary text-white rounded-md px-3 py-2 place-content-end"
          to="/dashboard/add-blog"
        >
          Add Blog
        </Link>
      </div>
      <BlogsList loading={loading} blogs={data} />
    </div>
  );
};

export default withAuth(Blogs);
