import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import StoryScreen from './StoryScreen';
import StoriesScreen from './StoriesScreen';
import CreateStoryScreen from './CreateStoryScreen';

/* =============================================================================
<StoriesScreens />
============================================================================= */
const StoriesScreens = () => (
  <>
    <Helmet>
      <title>Stories | Carsnus</title>
    </Helmet>
    <Switch>
      <Route path="/stories" exact>
        <StoriesScreen />
      </Route>
      <Route path="/stories/create" exact>
        <CreateStoryScreen />
      </Route>
      <Route path="/stories/:id" exact>
        <StoryScreen />
      </Route>
      {/* <Route path="*">
        <Notfound />
      </Route> */}
    </Switch>
  </>
);

/* Export
============================================================================= */
export default StoriesScreens;
