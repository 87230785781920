import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import {
  Timestamp, doc, getDoc, setDoc,
} from 'firebase/firestore';
import { AppleFilled, FacebookFilled, GoogleOutlined } from '@ant-design/icons';
import { useAuthSignInWithPopup } from '@react-query-firebase/auth';

import Spinner from '../Spinner';
import { auth, userCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<SocialAuthButton />
============================================================================= */
const SocialAuthButton = ({ provider, onClose }) => {
  const { getUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { mutateAsync: signInWithPopup } = useAuthSignInWithPopup(auth);

  const googleLogin = async () => {
    try {
      setLoading(true);

      const googleProvider = new GoogleAuthProvider();
      googleProvider.setCustomParameters({ prompt: 'select_account' });

      const authUser = await signInWithPopup({ provider: googleProvider });

      // // Get the record of the user from database
      const userDocRef = doc(userCollectionRef, authUser.user.uid);
      const userSnap = await getDoc(userDocRef);

      if (!userSnap.exists()) {
        await setDoc(userDocRef, {
          type: 'private seller',
          name: authUser.user.displayName,
          email: authUser.user.email,
          phone: authUser.user.phoneNumber,
          avatar: authUser.user.photoURL,
          postPackage: {
            packageId: 'free',
            numberOfPosts: 3,
          },
          favoritePosts: [],
          followers: [],
          id: authUser.user.uid,
          googleId: authUser.providerId,
          createdAt: Timestamp.now(),
        });
      }

      getUser();
      onClose();
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  const facebookLogin = async () => {
    try {
      setLoading(true);

      const facebookProvider = new FacebookAuthProvider();
      facebookProvider.setCustomParameters({ prompt: 'select_account' });

      const authUser = await signInWithPopup({ provider: facebookProvider });

      // // Get the record of the user from database
      const userDocRef = doc(userCollectionRef, authUser.user.uid);
      const userSnap = await getDoc(userDocRef);

      if (!userSnap.exists()) {
        await setDoc(userDocRef, {
          type: 'private seller',
          name: authUser.user.displayName,
          email: authUser.user.email,
          phone: authUser.user.phoneNumber,
          avatar: authUser.user.photoURL,
          postPackage: {
            packageId: 'free',
            numberOfPosts: 3,
          },
          favoritePosts: [],
          followers: [],
          id: authUser.user.uid,
          googleId: authUser.providerId,
          createdAt: Timestamp.now(),
        });
      }

      getUser();
      onClose();
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  const _handlePress = async () => {
    if (provider === 'Gg') {
      googleLogin();
    }
    if (provider === 'Fb') {
      facebookLogin();
    }
  };

  return (
    <button type="button" className="w-14 h-14 flex justify-center items-center border border-white p-2 rounded-full hover:bg-gray-900" onClick={_handlePress}>
      {!loading ? (
        <>
          {provider === 'Gg' && (
          <GoogleOutlined style={{ fontSize: '26px', color: '#dc2626' }} />
          )}
          {provider === 'Fb' && (
          <FacebookFilled style={{ fontSize: '26px', color: '#dc2626' }} />
          )}
          {provider === 'Ap' && (
          <AppleFilled style={{ fontSize: '26px', color: '#dc2626' }} />
          )}
        </>

      ) : <Spinner size={20} />}
    </button>
  );
};

/* Export
============================================================================= */
export default SocialAuthButton;
