import { searchClient } from '@algolia/client-search';

const ALGOLIA_ID = 'ERS0WR8KDH';
const ALGOLIA_ADMIN_KEY = '0119d2c4c14731bb7057b40b522af252';

export const client = searchClient(
  ALGOLIA_ID,
  ALGOLIA_ADMIN_KEY,
);

// // Initialize Algolia, requires installing Algolia dependencies:
// // https://www.algolia.com/doc/api-client/javascript/getting-started/#install
// //
// // App ID and API Key are stored in functions config variables
