import React, { useContext, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../Provider/UserProvider';

export default function withAuth(Component) {
  return function WithAuth(props) {
    const { push: redirect } = useHistory();
    const { user, loading } = useContext(UserContext);

    useLayoutEffect(() => {
      if (!user && !loading && user?.type !== 'Admin') {
        redirect('/dashboard/login');
      }
    }, [user, redirect, loading]);

    if (loading) {
      return 'Loading...';
    }

    return <Component {...props} />;
  };
}
