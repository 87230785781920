import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Send } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Timestamp, doc } from 'firebase/firestore';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from 'firebase/storage';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';

import {
  Input,
  Header,
  Spinner,
  Container,
  FormDropDown,
  ImageMuiltipleUpload,
} from '../../common';
import { auth, storage, storiesCollectionRef } from '../../config/firebase';
import { client } from '../../config/react-query';
import storyTopics from '../../static/story-topics';
import { getBlobFroUri } from '../../util/functions';

/* =============================================================================
<CreateStoryScreen />
============================================================================= */
const CreateStoryScreen = () => {
  const navigation = useHistory();
  const [loading, setLoading] = useState();
  const currentUserId = auth?.currentUser?.uid;
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateStoryScheme),
  });
  const topicSelected = watch('topic');

  // STORY ID
  const newStoryId = useMemo(
    () => `storyId${Math.random().toString(16).slice(2)}`,
    [],
  );

  // STORY CREATE CALL
  const ref = doc(storiesCollectionRef, newStoryId);
  const { mutateAsync: createStory } = useFirestoreDocumentMutation(ref);

  const onSubmit = async values => {
    try {
      setLoading(true);
      const { topic, photos, text } = values;
      const story = {
        text,
        likes: [],
        id: newStoryId,
        isDeleted: false,
        author: currentUserId,
        createdAt: Timestamp.now(),
      };

      if (topic) {
        story.topic = topic;
      }
      if (photos?.length > 0) {
        const photoURLs = await Promise.all(
          photos.map(async photo => {
            const id = `id${Math.random().toString(16).slice(2)}`;
            const _storageRef = storageRef(storage, `story_images/${id}`);

            const imageBlob = await getBlobFroUri(photo);
            const snapshot = await uploadBytesResumable(_storageRef, imageBlob);
            const url = await getDownloadURL(snapshot.ref);

            return url;
          }),
        );

        story.photos = photoURLs;
      }

      await createStory(story);

      client.invalidateQueries(['stories']);

      navigation.goBack();
    } catch (e) {
      toast.error('Something went wrong', e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header title="Create Story" stack />
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-screen-lg my-10 mx-auto px-5 space-y-5">
        <div className="flex items-start w-full space-x-1">
          <Input
            formProps={register('text')}
            placeholder="What's on your mind?"
            errorText={errors?.text?.message}
          />
          <button type="submit" className="py-3 px-4 ">
            {loading ? (
              <Spinner color="#f1f1f1" size={22} />
            ) : (
              <Send size={22} color="#f1f1f1" />
            )}
          </button>
        </div>
        <FormDropDown
          name="topic"
          data={storyTopics}
          control={control}
          label="Topics (optional)"
          errorText={errors?.topic?.message}
        />
        {topicSelected && (
        <div>
          <ImageMuiltipleUpload
            name="photos"
            control={control}
            numOfImgs={2}
            errorText={errors?.photos?.message}
          />
        </div>
        )}
      </form>
    </Container>
  );
};

const CreateStoryScheme = Yup.object({
  text: Yup.string().required('Please Enter Story'),
});

/* Export
============================================================================= */
export default CreateStoryScreen;
