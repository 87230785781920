import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { ChevronLeft, Eye, EyeOff } from 'react-feather';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthSignInWithEmailAndPassword } from '@react-query-firebase/auth';

import { useHistory } from 'react-router-dom';
import Input from '../Input';
import Button from '../Button';
import { auth } from '../../config/firebase';
import SocialAuthButton from './SocialAuthButton';
import { UserContext } from '../../Provider/UserProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { classNames } from '../../util/functions';

/* =============================================================================
<Login />
============================================================================= */
const Login = ({ onPageChange, modal, onClose }) => {
  const { getUser } = useContext(UserContext);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [togglePassword, setTogglePassword] = useState(false);

  const _togglePassword = () => setTogglePassword((prev) => !prev);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(LoginFormSchema),
  });

  const {
    isLoading,
    mutate: signInWithEmailAndPassword,
  } = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      if (error.message === 'Firebase: Error (auth/invalid-login-credentials).') {
        toast.error('Invalid login credentials: Check your email or password');
        return;
      }
      toast.error(error.message);
    },
    onSuccess(payload) {
      if (payload.user) {
        getUser();
        if (width > 768) {
          onClose();
        } else {
          history.push('/');
        }
      }
    },
  });

  const onSubmit = ({ email, password }) => {
    signInWithEmailAndPassword({ email, password });
  };

  return (
    <div className={`bg-black ${!modal && 'h-screen'}`}>
      <div className={classNames('flex  items-center justify-between p-5 bg-gradient-to-t from-black to-primary', modal && 'hidden')}>
        <button type="button" onClick={history.goBack} className="z-50  top-3  left-3 cursor-pointer">
          <ChevronLeft color="white" className="h-6 w-6 lg:h-12 lg:w-12" />
        </button>
        <h1 className="text-center text-2xl font-semibold text-white">Login</h1>
        <div />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={`md:max-w-md md:mx-auto md:flex md:flex-col md:items-center md:justify-center ${!modal && 'h-[calc(100%-130px)]'} px-4 py-3 md:px-0 md:py-0 mt-12 md:mt-0`}>
        <div className="space-y-3 w-full">
          <Input type="email" label="Email" errorText={errors?.email?.message} formProps={register('email')} />
          <Input
            type={togglePassword ? 'text' : 'password'}
            label="Password"
            errorText={errors?.password?.message}
            onIconClick={_togglePassword}
            icon={togglePassword ? <Eye color="#fff" size={22} /> : <EyeOff color="#fff" size={22} />}
            formProps={register('password')}
          />
        </div>
        <Button type="submit" loading={isLoading} title="Sign in" size="block" customStyleClasses="mt-5" />
        <div className="flex justify-center space-x-2 my-5">
          <SocialAuthButton provider="Fb" onClose={onClose} />
          <SocialAuthButton provider="Gg" onClose={onClose} />
          <SocialAuthButton provider="Ap" onClose={onClose} />
        </div>
        <p className="text-white text-center">
          {"Don't have and Account? "}
          <button
            type="button"
            className="text-primary hover:opacity-80"
            onClick={() => {
              if (modal && width > 768) {
                onPageChange('Register');
              } else {
                history.push('/register');
              }
            }}
          >
            Register here
          </button>
        </p>
        <p className="text-white text-center">
          {'Are you a Dealer? '}
          <button
            type="button"
            className="text-primary hover:opacity-80"
            onClick={() => {
              if (modal && width > 768) {
                onPageChange('Dealer Register');
              } else {
                history.push('/dealer/register');
              }
            }}
          >
            Dealer Register
          </button>
        </p>
      </form>
    </div>
  );
};

const LoginFormSchema = yup
  .object({
    email: yup.string().email().required('Please enter email'),
    password: yup.string().required('Please enter password'),
  })
  .required();

/* Export
============================================================================= */
export default Login;
