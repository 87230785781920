/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Info } from 'react-feather';

import { classNames } from '../util/functions';

function Input({
  label,
  type,
  iconBtnType,
  variant,
  errorText,
  icon,
  onIconClick,
  disabled,
  value,
  onChange,
  formProps,
  customStyleClasses,
  leftIcon,
  onRightIconClick,
  rightIcon,
  inputRef,
  autoFocus,
  ...props
}) {
  return (
    <div className="w-full">
      {label && (
        <div className="flex justify-between mb-1 ml-2">
          <label className="block text-lg font-medium text-white">
            {label}
          </label>
        </div>
      )}
      <div className="relative">
        {leftIcon && (
        <button
          type={iconBtnType || 'button'}
          onClick={onIconClick}
          className="absolute left-2 bottom-0 top-0 justify-center align-center px-3 py-1 rounded-sm hover:opacity-80"
          tabIndex="-1"
        >
          {leftIcon}
        </button>
        )}
        <input
          type={type || 'text'}
          className={classNames(
            disabled ? 'text-gray-500' : null,
            variant === 'search' ? 'block w-full px-3  md:py-2.5 bg-lightWhite text-white rounded-full shadow-sm placeholder-white focus:outline-none text-md border focus:border-white focus:ring-white border-white '
              : `appearance-none block w-full px-3 md:py-2.5 text-white bg-gradient-to-b from-gray-900 to-black rounded-full shadow-sm placeholder-white focus:outline-none border 
               ${(errorText || value) ? 'border-primary focus:border-primary focus:ring-primary' : 'border-white py-3 shadow-gray-500 shadow-2xl focus:border-primary focus:ring-primary'}`,
            customStyleClasses,
          )}
          disabled={disabled}
          value={value}
          onChange={onChange}
          autoComplete={type === 'email' ? true : 'off'}
          ref={inputRef}
          autoFocus={autoFocus}
          {...formProps}
          {...props}
        />
        {icon && (
          <button
            type={iconBtnType || 'button'}
            onClick={onIconClick}
            className="absolute right-2 bottom-0 top-0 justify-center align-center px-2 py-1 rounded-sm hover:opacity-80"
            tabIndex="-1"
          >
            {icon}
          </button>
        )}
        {rightIcon && (
          <button
            type={iconBtnType || 'button'}
            onClick={onRightIconClick}
            className="absolute right-2 bottom-0 top-0 justify-center align-center px-2 py-1 rounded-sm hover:opacity-80"
            tabIndex="-1"
          >
            {rightIcon}
          </button>
        )}
      </div>
      {!!errorText && (
        <div className="flex items-center space-x-1 mt-2">
          <Info color="#dc2626" size={20} />
          <p className="text-md font-medium  py-white text-red-500">
            {errorText}
          </p>
        </div>
      )}
    </div>
  );
}

export default Input;
