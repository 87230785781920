import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import LandingLayout from '../common/layouts/LandingLayout';
import LandingPage from '../LandingPage';
import About from './about';
import AllBlogs from './all-blogs';
import Blog from './blog';
import Contact from './contact';
import Terms from './terms';
import Faq from './faq';
import Privacy from './privacy';
import Dealer from './dealer';

/* =============================================================================
<PostScreens />
============================================================================= */
const LandingScreens = () => (
  <>
    <Helmet>
      <title>Carsnus | Home</title>
    </Helmet>
    <Switch>
      <LandingLayout>
        <Route path="/home" exact>
          <LandingPage />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/blog" exact>
          <AllBlogs />
        </Route>
        <Route path="/blog/:slug" exact>
          <Blog />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/terms" exact>
          <Terms />
        </Route>
        <Route path="/faqs" exact>
          <Faq />
        </Route>

        <Route path="/privacy" exact>
          <Privacy />
        </Route>
        <Route path="/dealer" exact>
          <Dealer />
        </Route>
      </LandingLayout>
    </Switch>
  </>
);

/* Export
============================================================================= */
export default LandingScreens;
