import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';

import { Avatar, Spinner } from '../../common';
import { auth, userCollectionRef } from '../../config/firebase';
import StoryCardFollowButton from './StoryFollowButton';
import { classNames } from '../../util/functions';

/* =============================================================================
<StoryAuthorInfo />
============================================================================= */
const StoryAuthorInfo = ({ authorId, createdAt, type }) => {
  const navigation = useHistory();
  const currentUserId = auth?.currentUser?.uid;
  const [user, setUser] = useState(null);
  const IsCurrentUserNotAuthor = currentUserId !== authorId;

  const userDocRef = doc(userCollectionRef, authorId);

  useEffect(() => {
    onSnapshot(userDocRef, async (_userDoc) => {
      setUser(_userDoc.data());
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorId]);

  if (!user) {
    return (
      <div className="py-5 px-4">
        <Spinner color="#fff" size={22} />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <button type="button" className="flex items-center space-x-2 flex-1 text-left" onClick={() => navigation.push(`/profile/${authorId}`)}>
        <Avatar source={user?.avatar} name={user?.name} size={type === 'large' ? 'large' : 'medium'} />
        <div className="flex-1">
          <h1 className={classNames('text-white', type === 'large' ? 'text-2xl' : 'text-lg')}>
            {user?.name}
          </h1>
          <p className={classNames('text-white text-grey', type === 'large' ? 'text-md' : 'text-xs')}>
            {`${user?.followers?.length || 0} followers ${moment(
              createdAt?.toDate(),
            ).format('LLL')}`}
          </p>
        </div>
      </button>
      {IsCurrentUserNotAuthor && <StoryCardFollowButton author={user} type={type} />}
    </div>
  );
};

StoryAuthorInfo.defaultProps = {
  type: 'small',
};

const propsAreEqual = (prevProps, nextProps) => prevProps?.authorId
 === nextProps?.authorId;

/* Export
============================================================================= */
export default React.memo(StoryAuthorInfo, propsAreEqual);
