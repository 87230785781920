import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
  doc,
  query,
  limit,
  where,
  orderBy,
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { useHistory, NavLink } from 'react-router-dom';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import { firestore, userCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';
import { Avatar } from '../../common';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/* =============================================================================
<ConversationCard />
============================================================================= */
const ConversationCard = ({ conversation }) => {
  console.log('🚀 ~ ConversationCard ~ conversation:', conversation);
  const navigation = useHistory();
  const { width } = useWindowDimensions();
  const { userId } = useContext(UserContext);
  const [lastMessage, setLastMessage] = useState('no text');
  const [unreadMessages, setUnreadMessages] = useState(0);

  // GET OTHER USER OF THIS CONVERSATION
  const otherUserId = conversation?.members?.find(m => m !== userId) || 'nonoen';
  const otherUserRef = doc(userCollectionRef, otherUserId);
  const { data: otherUser } = useFirestoreDocumentData(
    ['users', otherUserId],
    otherUserRef,
    {},
    { retry: 3 },
  );

  // GET LAST MESSAGE OF THIS CONVERSATION

  const messagesCollection = collection(
    firestore,
    `conversations/${conversation?.id}/messages`,
  );
  const lastMessagesQuery = query(
    messagesCollection,
    orderBy('createdAt', 'desc'),
    limit(1),
  );

  // GET UNREAD MESSAGES COUNT
  const messagesCountQuery = query(
    messagesCollection,
    where('author', '!=', userId),
    where('mark_as_seen', '==', false),
  );

  useEffect(() => {
    onSnapshot(lastMessagesQuery, async (lastMessageDoc) => {
      lastMessageDoc?.forEach((snap) => setLastMessage(snap.data()?.text));
    });
    onSnapshot(messagesCountQuery, async (messageDoc) => {
      setUnreadMessages(messageDoc?.size);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavLink
      to={`/chat/${conversation?.id}`}
      className="flex items-center justify-between"
    >
      <div className="flex items-center flex-1 space-x-5">
        <Avatar source={otherUser?.avatar} name={otherUser?.name} size={width < 480 ? 'semi' : 'large'} />
        <div className="flex-1">
          <h1 className="text-white text-lg">
            {otherUser?.name}
          </h1>
          {/* <p className="text-white text-sm font-medium">
            {lastMessage || '...'}
          </p> */}
          <p className="text-white text-sm font-medium">
            {conversation?.postTitle}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-end space-y-2 justify-end">
        {unreadMessages > 0 && (
        <div className="w-5 h-5 bg-primary rounded-full flex items-center justify-center">
          <p className="text-white text-xs font-medium">
            {unreadMessages}
          </p>
        </div>
        )}
        <Avatar source={conversation.postImg} size={width < 480 ? 'semi' : 'large'} />
        {/* <p className="text-white text-sm text-medium text-gray-500">
          {moment(conversation?.updatedAt.toDate()).format('LT')}
        </p> */}
      </div>
    </NavLink>
  );
};

/* Export
============================================================================= */
export default ConversationCard;
