import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import {
  doc,
  limit,
  query,
  orderBy,
  collection,
  startAfter,
} from 'firebase/firestore';
import { Bell, Info } from 'react-feather';
import {
  useFirestoreDocumentMutation,
  useFirestoreInfiniteQueryData,
} from '@react-query-firebase/firestore';

import {
  Header,
  Container,
  PaginationList,
} from '../../common';
import {
  auth,
  firestore,
  notificationsCollectionRef,
} from '../../config/firebase';
import useIntersectionObserver from '../../util/useIntersectionObserver';

/* =============================================================================
<NotificationsScreen />
============================================================================= */
const NotificationsScreen = () => {
  const currentUserId = auth?.currentUser?.uid || 'none';
  // GETTING NOTIFICATIONS WITH PAGINATION
  const notificationHistoryRef = collection(
    firestore,
    `notifications/${currentUserId}/history`,
  );
  const ref = query(
    notificationHistoryRef,
    limit(10),
    orderBy('createdAt', 'desc'),
  );
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    ['notificationsHistory', currentUserId],
    ref,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(ref, startAfter(lastDocument?.createdAt || ''));
    },
  );
  const notificationRef = doc(notificationsCollectionRef, currentUserId);
  const { mutateAsync: updatedNotifcation } = useFirestoreDocumentMutation(
    notificationRef,
    {
      merge: true,
    },
  );

  useEffect(() => {
    updatedNotifcation({
      unreadCount: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flattenData = data?.pages
    ? data?.pages?.flatMap(page => [...page])
    : [];

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  return (
    <Container>
      <Header title="Notifications" stack />
      <PaginationList
        data={flattenData}
        loading={isLoading}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        emptyText="No notifications found"
        isFetchingNextPage={isFetchingNextPage}
      />
    </Container>
  );
};

const renderItem = (item) => (

  <li className="flex items-center w-full space-x-10 shadow-md shadow-gray-700 rounded-full px-4 xsm:px-2 xsm:space-x-7 md:px-8 py-2 md:py-4 bg-neutral-700">
    <div className="w-16 h-16 md:w-20 md:h-20 rounded-full bg-black flex items-center justify-center">
      <Bell color="#404040" fill="#404040" size={40} />
    </div>
    <div className="flex-1 h-full">
      <h1 className="text-white text-xl md:text-3xl font-medium mini:text-base">
        {item?.title || 'test'}
      </h1>
      <p className="text-white text-sm md:text-lg">
        {moment(item?.createdAt?.toDate()).fromNow() || '8 mins ago'}
      </p>
    </div>
  </li>
);

/* Export
============================================================================= */
export default NotificationsScreen;
