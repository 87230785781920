import React, { useState } from 'react';
import { connect } from 'react-redux';
import { doc } from 'firebase/firestore';
import ShowMoreText from 'react-show-more-text';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import {
  Link,
  Button,
  Container,
  LinkHeader,
  PhotosCarousel,
} from '../../common';
import PostHeader from '../components/PostHeader';
import CategoryData from '../../static/categories-data';
import PostSellerInfo from '../components/PostSellerInfo';
import { userCollectionRef } from '../../config/firebase';
import PostInteractionButtons from '../components/PostInteractionButtons';

import { getPostData } from '../redux/selectors';
import PostFeatured from '../components/PostFeatured';
import PostInfoCard from '../components/PostInfoCard';
import { useLocation } from '../../Provider/LocationProvider';

/* =============================================================================
<PostQueryScreen />
============================================================================= */
const PostQueryScreen = ({ postData }) => {
  const { index, data } = postData;
  console.log('🚀 ~ PostQueryScreen ~ index:', index);
  console.log('🚀 ~ PostQueryScreen ~ data:', data);
  const [currentPostIndex, setCurrentPostIndex] = useState(index);
  const { location } = useLocation();
  const posts = data?.length > 0
    ? data
      ?.map(d => d)
      // ?.sort((a, b) => (!location ? Number(b?.isFeatured) - Number(a?.isFeatured) : 1))
    : [];

  const authorId = posts[currentPostIndex]?.author || 'nonoe';

  // GET AUTHOR
  const ref = doc(userCollectionRef, authorId);
  const { data: author, isLoading: authorLoading } = useFirestoreDocumentData(
    ['users', authorId],
    ref,
  );

  const post = posts[currentPostIndex];
  const postId = post?.id;
  const totalPosts = posts?.length;
  const postCategory = post?.category;
  const postTitle = `${post?.year || ''} ${post?.make || post?.titleVehicle} ${
    post?.model || ''
  }`;
  const photos = post?.photos;

  const _handleNextPostPress = async () => {
    if (currentPostIndex + 1 !== totalPosts) {
      setCurrentPostIndex(currentPostIndex + 1);
    }
  };
  const _handlePrevPostPress = () => {
    if (currentPostIndex > 0) {
      setCurrentPostIndex(currentPostIndex - 1);
    }
  };

  const links = [
    {
      name: 'Home',
      url: '/home/cars',
    },
    {
      name: CategoryData?.find((c) => c.id === postCategory)?.title,
      url: `/category/${postCategory}`,
    },
    {
      name: postTitle,
      url: `/post/${postId}`,
    },
  ];

  return (
    <Container classes="!bg-[#262626]  md:!bg-black">
      <PostHeader
        totalPosts={totalPosts}
        currentIndex={currentPostIndex}
        onNextPress={_handleNextPostPress}
        onPrevPress={_handlePrevPostPress}
      />
      <div className="max-w-screen-xl mx-5 xsm:mx-0 xl:mx-auto pb-10">
        <div className="hidden md:block xsm:mx-3">
          <LinkHeader contents={links} />
        </div>
        <div className="flex flex-col w-full lg:flex-row lg:space-x-5">
          <div className="w-full -mt-2 lg:w-2/3 space-y-2 xsm:space-y-0">
            <PhotosCarousel photos={photos} customStyles="md:w-full" postId={postId}>
              <PostInteractionButtons postId={postId} />
            </PhotosCarousel>
            <div className="block lg:hidden">
              <PostInfoCard post={post} author={author} />
            </div>
            <div className="xsm:mx-3 pt-5 p-2">
              <div className="flex items-center justify-between">
                {post?.description && (
                <h1 className="text-2xl font-semibold text-primary underline underline-offset-1">Description</h1>
                )}
                {post?.features?.length > 0 ? (
                  <Link label="Features" to={`/post/${postId}/features`} textLarge />
                ) : <div />}
              </div>
              {post?.description && (
              <ShowMoreText lines={4} className="text-white mt-2 text-left mb-1 text-xl">
                <p className="text-white text-lg">
                  {`${post?.description}`}
                </p>
              </ShowMoreText>
              )}
              {!authorLoading && (
              <PostSellerInfo author={author} location={post?.location} id={post?.id} />
              )}
            </div>

          </div>
          <div className="w-full lg:w-1/3 h-full rounded-lg space-y-16 hidden lg:block">
            <PostInfoCard post={post} author={author} />
            <div className="bg-secondaryDark h-96">
              <p>Google Ad</p>
            </div>
          </div>
        </div>
        {postCategory && (
          <PostFeatured categoryId={postCategory} currentPostId={postId} />
        )}

      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  postData: getPostData(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(PostQueryScreen);
