import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { doc } from 'firebase/firestore';
import ShowMoreText from 'react-show-more-text';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import {
  Link,
  Button,
  Container,
  LinkHeader,
  PhotosCarousel,
  Spinner,
} from '../../common';
import PostInfo from '../../post/components/PostInfo';
import CategoryData from '../../static/categories-data';
import PostHeader from '../../post/components/PostHeader';
import PostActions from '../../post/components/PostActions';
import PostSellerInfo from '../../post/components/PostSellerInfo';
import { postCollectionRef, userCollectionRef } from '../../config/firebase';
import PostInteractionButtons from '../../post/components/PostInteractionButtons';

import { getPostData } from '../../post/redux/selectors';

/* =============================================================================
<FavoritePostScreen />
============================================================================= */
const FavoritePostScreen = ({ postData }) => {
  const { index, data: favorites } = postData;
  const [currentPostIndex, setCurrentPostIndex] = useState(index);

  // // GET POST BY ID
  const currentPostId = favorites[currentPostIndex];
  const postRef = doc(postCollectionRef, currentPostId);
  const { data: post, isLoading: postLoading } = useFirestoreDocumentData(
    ['posts', currentPostId],
    postRef,
  );

  const authorId = post?.author || 'nonee';

  // // GET POST AUTHOR BY ID
  const ref = doc(userCollectionRef, authorId);
  const { data: author, isLoading: authorLoading } = useFirestoreDocumentData(
    ['users', authorId],
    ref,
  );

  const postId = post?.id;
  const totalPosts = favorites?.length;
  const postCategory = post?.category;
  const postTitle = `${post?.year || ''} ${post?.make || post?.titleVehicle} ${
    post?.model || ''
  }`;

  const _handleNextPostPress = async () => {
    if (currentPostIndex + 1 !== totalPosts) {
      setCurrentPostIndex(currentPostIndex + 1);
    }
  };
  const _handlePrevPostPress = () => {
    if (currentPostIndex > 0) {
      setCurrentPostIndex(currentPostIndex - 1);
    }
  };

  const links = [
    {
      name: 'Home',
      url: '/home/cars',
    },
    {
      name: CategoryData?.find((c) => c.id === postCategory)?.title,
      url: `/home/${postCategory}`,
    },
    {
      name: postTitle,
      url: `/post/${postId}`,
    },
  ];

  if (postLoading) {
    return (
      <Container classes="flex justify-center items-center">
        <div>
          <Spinner size={40} />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <PostHeader
        postTitle={postTitle}
        totalPosts={totalPosts}
        currentIndex={currentPostIndex}
        onNextPress={_handleNextPostPress}
        onPrevPress={_handlePrevPostPress}
      />
      <div className="max-w-screen-xl mx-5 md:mx-auto py-10">
        <LinkHeader contents={links} />
        <div className="flex flex-col md:flex-row md:space-x-5">
          <PhotosCarousel photos={post?.photos}>
            <PostInteractionButtons postId={postId} />
          </PhotosCarousel>
          <div className="bg-lightBlack w-full md:w-1/3 h-full rounded-lg p-4">
            <div className="bg-neutral-800 p-4 rounded-xl space-y-2">
              <h1 className="text-white text-xl font-semibold">{postTitle}</h1>
              <div className="flex justify-end items-center">
                <Button title={`$ ${post?.price}`} customTxtStyles="text-xl" />
              </div>
            </div>
            <div className="flex items-center my-5 justify-between">
              {post?.title && (
              <div className="bg-lightWhite px-5 py-1 rounded-full">
                <p className="text-white text-semibold text-center font-bold">{`${post?.title} Title`}</p>
              </div>
              )}
              {post?.condition && (
              <div className="bg-lightWhite px-5 py-1 rounded-full">
                <p className="text-white text-semibold text-center font-bold">{`${post?.condition} Condition`}</p>
              </div>
              )}
            </div>
            <PostInfo post={post} />
            <PostActions author={author} />
          </div>
        </div>
        <div className="flex items-center justify-between">
          {post?.description && (
            <h1 className="text-2xl font-semibold text-primary underline underline-offset-1">Description</h1>
          )}
          {post?.features?.length > 0 ? (
            <Link label="Features" to={`/post/${postId}/features`} textLarge />
          ) : <div />}
        </div>
        {post?.description && (
        <ShowMoreText lines={4} className="text-white mt-2 text-left mb-1 text-xl">
          <p className="text-white text-lg">
            {`${post?.description}`}
          </p>
        </ShowMoreText>
        )}
        <PostSellerInfo author={author} loading={authorLoading} location={post?.location} />
        {/* <PostFeatured categoryId={postCategory} currentPostId={postId} /> */}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  postData: getPostData(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(FavoritePostScreen);
