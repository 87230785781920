import React from 'react';
import { Controller } from 'react-hook-form';

import DropDown from './DropDown';

/* =============================================================================
<FormDropDown />
============================================================================= */
const FormDropDown = ({
  control, label, name, data, errorText, customContainerStyles, ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <DropDown
        name={name}
        value={value}
        items={data}
        customContainerStyles={customContainerStyles}
        label={label}
        onChange={onChange}
        errorText={errorText}
        {...props}
      />
    )}
  />
);
/* Export
============================================================================= */
export default FormDropDown;
