import React, { useRef } from 'react';
import { Plus } from 'react-feather';
import { Draggable } from 'react-beautiful-dnd';
import Avatar from '../Avatar';

const ImageUploadImageCard = ({ onChange, value, name }) => {
  const fileInput = useRef();

  const _handleImageSelect = async (e) => {
    const selectedProfileImg = URL.createObjectURL(e.target.files[0]);
    onChange(selectedProfileImg);
  };

  return (
    <li className="relative  h-full">
      <Avatar source={value} size="extraLarge" name={name} />
      <button
        type="button"
        className="absolute bg-primary w-8 h-8 rounded-full flex justify-center items-center top-5 right-10 md:right-0"
        onClick={() => fileInput.current.click()}
      >
        <Plus color="#fff" size={22} />
      </button>
      <input
        type="file"
        ref={fileInput}
        id="file-input"
        name="file-input"
        className="w-0 h-0 opacity-0  overflow-hidden absolute z-0"
        accept="image/png, image/jpeg"
        onChange={_handleImageSelect}
      />
    </li>
  );
};
export default ImageUploadImageCard;
