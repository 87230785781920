import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  limit, orderBy, query, startAfter, where,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';
import { classNames, filterCars } from '../../util/functions';
import { emptyFilterForm } from '../../static/filter';
import { postCollectionRef } from '../../config/firebase';
import {
  Container, LinkHeader, PaginationList, PostCard,
} from '../../common';

import { setPostData as setPostDataAction } from '../redux/actions';
import HomePriceHeader from '../../home/components/HomePriceHeader';

/* =============================================================================
<HomeCategoryScreen />
============================================================================= */
const PostByPrice = ({ setPostData }) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [searchTxt, setSearchTxt] = useState('');
  const [filters, setFilters] = useState(emptyFilterForm);
  const [price, setPrice] = useState(+params.get('price') ?? 3000);

  const queryKey = ['priceFilteredPosts', price]; // Update the query key since category is removed
  const queryRef = query(
    postCollectionRef,
    where('price', '<=', price), // Filter for posts with price less than or equal to 3000
    orderBy('createdAt', 'desc'),
    limit(40),
  );

  const {
    data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(queryKey, queryRef, snapshot => {
    const lastDocument = snapshot[snapshot.length - 1];

    if (!lastDocument) {
      return undefined;
    }

    return query(queryRef, startAfter(lastDocument?.createdAt || ''));
  });

  const flattenData = data?.pages
    ? data?.pages
      ?.flatMap(page => [...page])
      .sort((a, b) => Number(b?.isFeatured) - Number(a?.isFeatured))
    : [];

  const searchFiltered = useMemo(
    () => (searchTxt
      ? flattenData?.filter(p => {
        const searchFilter = `${p?.year} ${p?.make} ${p?.model} `.toLowerCase();
        return searchFilter.includes(searchTxt?.trim()?.toLowerCase());
      })
      : filterCars(flattenData, filters)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTxt, filters, flattenData?.length],
  );

  const renderItem = (item, index) => (
    <PostCard
      item={item}
      key={item.id}
      onPress={() => {
        setPostData({ index, data: searchFiltered });
        history.push('/post/data');
      }}
    />
  );
  const links = [
    {
      name: 'Home',
      url: '/home',
    },
    {
      name: 'Car',
      url: '/cars',
    },
    {
      name: `${price}`,
      url: '/cars',
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>{'Carsnus '}</title>
      </Helmet>
      <div
        className={classNames(
          'bg-gradient-to-t px-8 flex items-center md:items-start justify-between  from-black to-primary  py-3',
        )}
      >
        <button
          type="button"
          onClick={history.goBack}

        >
          <ChevronLeft color="white" className="h-6 w-6 lg:h-10 lg:w-10" />
        </button>
        <h1
          className={classNames(
            'text-white xsm:text-xl text-3xl font-semibold text-center ',
          )}
        >
          Cars by Price
        </h1>
        <div />
      </div>
      <div className="mx-5 xl:mx-12">
        <div className="">
          <div className="hidden md:block">
            <LinkHeader contents={links} />
          </div>
        </div>
        <div className="flex overflow-x-auto gap-4 my-4">
          <button
            type="button"
            onClick={() => {
              setPrice(2000);
              history.push(`/cars?price=${2000}`);
            }}
            className={`whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary text-white px-2 md:px-4 py-2 ${
              price === 2000 ? 'bg-primary' : ''
            }`}
          >
            Cars Under $2,000
          </button>
          <button
            type="button"
            onClick={() => {
              setPrice(4000);
              history.push(`/cars?price=${4000}`);
            }}
            className={`whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary text-white px-2 md:px-4 py-2 ${
              price === 4000 ? 'bg-primary' : ''
            }`}
          >
            Cars Under $4,000
          </button>

          <button
            type="button"
            onClick={() => {
              setPrice(6000);
              history.push(`/cars?price=${6000}`);
            }}
            className={`whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary text-white px-2 md:px-4 py-2 ${
              price === 6000 ? 'bg-primary' : ''
            }`}
          >
            Cars Under $6,000
          </button>
          <button
            type="button"
            onClick={() => {
              setPrice(8000);
              history.push(`/cars?price=${8000}`);
            }}
            className={`whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary text-white px-2 md:px-4 py-2 ${
              price === 8000 ? 'bg-primary' : ''
            }`}
          >
            Cars Under $8,000
          </button>
          <button
            type="button"
            onClick={() => {
              setPrice(10000);
              history.push(`/cars?price=${10000}`);
            }}
            className={`whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary text-white px-2 md:px-4 py-2 ${
              price === 10000 ? 'bg-primary' : ''
            }`}
          >
            Cars Under $10,000
          </button>
        </div>
      </div>

      <PaginationList
        horizontal
        center={false}
        data={searchFiltered}
        loading={isLoading}
        styles="mx-5 xsm:my-1 sm-1:my-1 xl:mx-12 xsm:mt-1"
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        emptyText="No post found in this category"
      />
      {/* <H
        filters={filters}
        visible={filtersModal}
        category={categoryId}
        onFilterChange={setFilters}
        onClose={_toggleFiltersModal}
      /> */}
    </Container>
  );
};

const mapDispatchToProps = {
  setPostData: setPostDataAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(PostByPrice);
