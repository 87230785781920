/* eslint-disable no-param-reassign */
// app/blogs/[slug]/BlogContent.js

import React, { useEffect, useState } from 'react';

const BlogContent = ({ content }) => {
  const [processedContent, setProcessedContent] = useState('');

  useEffect(() => {
    const applyTailwindClasses = (htmlContent) => {
      const tempDiv = document.createElement('div');
      tempDiv.className = 'quill-editor';
      tempDiv.innerHTML = htmlContent;

      tempDiv.querySelectorAll('h1').forEach(element => {
        element.className = 'text-xl md:text-3xl font-bold mb-2';
      });

      tempDiv.querySelectorAll('blockquote').forEach(element => {
        element.className = 'border-l-4 border-gray-300 pl-4 italic text-gray-600';
      });

      tempDiv.querySelectorAll('h2').forEach(element => {
        element.className = 'text-lg md:text-2xl font-bold mb-2';
      });

      tempDiv.querySelectorAll('p').forEach(element => {
        element.className = 'text-base my-2';
      });

      tempDiv.querySelectorAll('img').forEach(element => {
        element.className = 'my-4';
      });

      // Style list elements
      tempDiv.querySelectorAll('ul').forEach(element => {
        element.className = 'list-disc pl-5';
      });

      tempDiv.querySelectorAll('ol').forEach(element => {
        element.className = 'list-decimal pl-5';
      });

      tempDiv.querySelectorAll('li').forEach(element => {
        element.className = 'text-base my-1';
      });

      return tempDiv.innerHTML;
    };

    setProcessedContent(applyTailwindClasses(content));
  }, [content]);

  return <div dangerouslySetInnerHTML={{ __html: processedContent }} />;
};

export default BlogContent;
