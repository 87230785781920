import React, { useContext } from 'react';
import { Info } from 'react-feather';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../Provider/UserProvider';
import FavoritePostCard from '../components/FavoritePostCard';
import {
  Header,
  Container,
  EmptyState,
} from '../../common';

import { setPostData as setPostDataAction } from '../../post/redux/actions';

/* =============================================================================
<FavoritePostsScreen />
============================================================================= */
const FavoritePostsScreen = ({ setPostData }) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const favorites = user?.favoritePosts;

  return (
    <Container>
      <Header title="Favorites" stack />
      <ul className="max-w-screen-lg mx-auto space-y-5 sm:my-10 my-5 px-4 sm:px-6 lg:px-8">
        {favorites?.map((item, index) => (
          <FavoritePostCard
            id={item}
            key={item}
            index={index}
            onClick={() => {
              setPostData({ index, data: favorites });
              history.push('/favorites/post');
            }}
          />
        ))}
      </ul>

      {/* Empty State */}
      {favorites?.length === 0 && (
        <div className="text-center mt-52">
          <EmptyState
            title="No results"
            icon={<Info color="#fff" size={35} />}
            description="No notifications found"
          />
        </div>
      )}
    </Container>
  );
};

const mapDispatchToProps = {
  setPostData: setPostDataAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(FavoritePostsScreen);
