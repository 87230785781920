import React from 'react';
import kconvert from 'k-convert';
import { ArrowRight, Clock } from 'react-feather';
import fuelIcon from '../../assets/images/fuel.png';
import gearIcon from '../../assets/images/gearbox.png';
import milesIcon from '../../assets/images/speed.png';
import engineIcon from '../../assets/images/engine.png';

/* =============================================================================
<PostInfo />
============================================================================= */
const PostInfo = ({ post }) => {
  if (post?.category === 'other' || post?.category === 'part') {
    if (!post?.year && !post?.parts) {
      return null;
    }

    return (
      <div
        className="flex items-center justify-between  bg-neutral-800 p-4 rounded-xl shadow-lg"
        style={{ paddingHorizontal: 25 }}
      >
        <div className="space-y-2">
          {post?.year && (
            <div className="flex items-center justify-between space-x-5">
              <p className="text-white font-semibold text-xl">Year :</p>
              <p className="text-white font-semibold text-lg">{post?.year}</p>
            </div>
          )}
          {post?.parts && (
            <div className="flex items-center justify-between space-x-5">
              <p className="text-white font-semibold text-xl">Vehicle :</p>
              <p className="text-white font-semibold text-lg">{post?.parts}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (
    !post?.transmission
    && !post?.miles
    && !post?.fuel
    && !post?.engine
    && !post?.length
    && !post?.hours
  ) {
    return null;
  }

  return (
    <div className="md:grid flex items-center justify-between md:grid-cols-2 gap-4 mt-4  rounded-xl">
      {post?.transmission && (
        <div className="md:bg-neutral-800 flex-1 rounded-xl flex flex-col p-0 md:p-4  items-center space-y-1">
          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-grey1">
            <img
              src={gearIcon}
              style={{ width: 40, height: 30, objectFit: 'contain' }}
              alt="transmission"
            />
          </div>
          <p className="text-base md:text-lg text-white whitespace-nowrap">{`${post?.transmission}`}</p>
        </div>
      )}
      {post?.miles?.toString() && (
        <div className=" md:bg-neutral-800 flex-1 rounded-xl flex flex-col p-0 md:p-4 justify-center items-center space-y-1">
          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-grey1">
            <img
              src={milesIcon}
              style={{ width: 50, height: 34, objectFit: 'contain' }}
              alt="transmission"
            />
          </div>
          <p className="text-base md:text-lg text-white whitespace-nowrap">
            {`${kconvert.convertTo(post?.miles)} m.`}
          </p>
        </div>
      )}
      {post?.fuel && (
        <div className="md:bg-neutral-800 flex-1 rounded-xl flex flex-col p-0 md:p-4 justify-center items-center space-y-1">
          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-grey1">
            <img
              src={fuelIcon}
              style={{ width: 40, height: 25, objectFit: 'contain' }}
              alt="transmission"
            />
          </div>
          <p className="text-base md:text-lg text-white whitespace-nowrap">{post?.fuel}</p>
        </div>
      )}
      {post?.engine && (
        <div className="md:bg-neutral-800 flex-1 rounded-xl flex flex-col p-0 md:p-4 justify-center items-center space-y-1">
          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-grey1">
            <img
              src={engineIcon}
              style={{ width: 40, height: 35, objectFit: 'contain' }}
              alt="transmission"
            />
          </div>
          <p className="text-base md:text-lg text-white whitespace-nowrap">{post?.engine}</p>
        </div>
      )}
      {post?.length && (
        <div className="md:bg-neutral-800 flex-1 rounded-xl flex flex-col p-0 md:p-4 justify-center items-center space-y-1">
          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-grey1">
            <ArrowRight size={31} color="#BFBFBF" />
          </div>
          <p className="text-base md:text-lg text-white whitespace-nowrap">{post?.length}</p>
        </div>
      )}
      {post?.hours && (
        <div className="flex flex-col flex-1 justify-center items-center space-y-1">
          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-grey1">
            <Clock size={31} color="#BFBFBF" />
          </div>
          <p className="text-base md:text-lg text-white whitespace-nowrap">{post?.hours}</p>
        </div>
      )}
    </div>
  );
};

/* Export
============================================================================= */
export default PostInfo;
