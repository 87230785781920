import React, { useEffect, useState } from 'react';
import { Share } from 'react-feather';
import ShowMoreText from 'react-show-more-text';
import { useHistory } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';

import { Button, Spinner } from '../../common';
import StoryLikeButton from './StoryLikeButton';
import StoryAuthorInfo from './StoryAuthorInfo';
import { auth, storiesCollectionRef } from '../../config/firebase';

/* =============================================================================
<StoryCard />
============================================================================= */
const StoryCard = ({ storyId }) => {
  const navigation = useHistory();
  const [story, setStory] = useState(null);
  const authorId = story?.author || 'none';
  const currentUserId = auth?.currentUser?.uid;

  const IsCurrentUserNotAuthor = currentUserId !== authorId;

  const storyDoc = doc(storiesCollectionRef, storyId);

  useEffect(() => {
    onSnapshot(storyDoc, async (_storyDoc) => {
      setStory(_storyDoc.data());
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorId]);

  return (
    <div className="p-3 space-y-2 lg:mx-28 flex flex-col justify-between rounded-xl overflow-hidden bg-gradient-to-b from-gradiantColor1 to-gradiantColor2">
      <StoryAuthorInfo
        authorId={authorId}
        createdAt={story?.createdAt}
        IsCurrentUserNotAuthor={IsCurrentUserNotAuthor}
      />
      <button type="button" onClick={() => navigation.push(`/stories/${storyId}`)}>
        <ShowMoreText lines={4} className="text-white text-left mb-1 text-md">
          <p className="text-white text-md">
            {`${story?.text}`}
          </p>
        </ShowMoreText>
        {story?.photos?.length > 0 && (
        <div className="relative">
          <img src={story?.photos[0]} className="w-full h-96 xsm:h-60 rounded-xl z-0" alt={story?.id} />
          <div className="absolute bottom-0 left-0 top-0 right-0 bg-gradient-to-b from-transparent to-white/25 z-50 rounded-xl">
            <p className="text-white text-xl font-semibold text-center absolute bottom-5 left-0 right-0">{story?.topic}</p>
          </div>
        </div>
        )}
      </button>
      <div className="flex items-center justify-between">
        <StoryLikeButton
          likes={story?.likes}
          authorId={authorId}
          storyId={storyId}
        />
        <Button
          title="reply"
          onClick={() => navigation.push(`/stories/${storyId}`)}
          icon={<Share color="#fff" size={16} />}
        />
      </div>
    </div>
  );
};

const propsAreEqual = (prevProps, nextProps) => prevProps?.story?.id === nextProps?.story?.id;

/* Export
============================================================================= */
export default React.memo(StoryCard, propsAreEqual);
