import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { State } from 'country-state-city';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  useAuthUpdateProfile,
  useAuthUpdatePassword,
} from '@react-query-firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

import { auth, storage } from '../../config/firebase';
import { getBlobFroUri } from '../../util/functions';
import { UserContext } from '../../Provider/UserProvider';
import {
  Button, FormDropDown, ImageUpload, Input,
} from '../../common';

/* =============================================================================
<EditDealerProfileForm />
============================================================================= */
const EditDealerProfileForm = () => {
  const navigation = useHistory();
  const statesData = State.getStatesOfCountry('US')?.map((s, i) => s?.name);
  const [loading, setLoading] = useState(false);
  const { user, updateUser } = useContext(UserContext);
  const { mutateAsync: updateProfile } = useAuthUpdateProfile();
  const { mutateAsync: updatePassword } = useAuthUpdatePassword();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      avatar: user?.avatar,
      name: user?.name,
      address: user?.business.address,
      city: user?.business.city,
      contactName: user?.business.contactName,
      phone: user?.business.phone.toString(),
      postcode: user?.business.postcode.toString(),
      state: user?.business.state,
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(EditDealerForm),
  });

  const onSubmit = async values => {
    try {
      setLoading(true);
      const {
        city,
        phone,
        name,
        state,
        avatar,
        address,
        postcode,
        contactName,
        newPassword,
      } = values;

      const changes = {
        business: {
          city,
          state,
          phone,
          address,
          postcode,
          contactName,
        },
      };

      if (avatar !== user?.avatar) {
        const storageRef = ref(storage, `profile_images/${name}`);

        const imageBlob = await getBlobFroUri(avatar);

        const snapshot = await uploadBytesResumable(storageRef, imageBlob);

        const url = await getDownloadURL(snapshot.ref);

        await updateProfile({
          user: auth?.currentUser,
          photoURL: url,
        });

        changes.avatar = url;
      }
      if (newPassword) {
        await updatePassword({
          user: auth?.currentUser,
          newPassword,
        });
      }
      if (name !== user?.name) {
        changes.name = name;
      }

      await updateUser({
        ...changes,
        updatedAt: Timestamp.now(),
      });

      navigation.goBack();
    } catch (e) {
      toast(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-screen-sm mx-5 md:mx-auto py-8 space-y-5">
      <ImageUpload control={control} errorText={errors?.avatar?.message} name="avatar" userName={user?.name} />
      <Input label="Bussiness name" errorText={errors?.name?.message} formProps={register('name')} />
      <div className="flex items-start space-x-5">
        <Input label="City" errorText={errors?.city?.message} formProps={register('city')} />
        <Input label="Zip Code" errorText={errors?.postcode?.message} formProps={register('postcode')} />
      </div>
      <div className="flex items-start space-x-5">
        <FormDropDown
          name="state"
          label="State"
          control={control}
          data={statesData}
          errorText={errors?.state?.message}
        />
        <Input label="Phone" errorText={errors?.phone?.message} formProps={register('phone')} />
      </div>
      <Input label="Contact Name" errorText={errors?.contactName?.message} formProps={register('contactName')} />
      <Input label="Address" errorText={errors?.address?.message} formProps={register('address')} />
      <Input
        type="password"
        label="New Password"
        formProps={register('newPassword')}
        errorText={errors?.newPassword?.message}
      />
      <Input
        type="password"
        label="Confirm New Password"
        formProps={register('confirmNewPassword')}
        errorText={errors?.confirmNewPassword?.message}
      />
      <Button type="submit" loading={loading} title="Edit" size="block" />
    </form>
  );
};

const EditDealerForm = yup.object({
  name: yup.string().required('Please enter business name'),
  city: yup.string().required('Please enter city'),
  postcode: yup.number().required('Please enter zip code'),
  state: yup.string().required('Please enter state'),
  phone: yup.number().required('Please enter phone'),
  address: yup.string().required('Please enter address'),
  contactName: yup.string().required('Please enter contact name'),
  newPassword: yup.string(),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

/* Export
============================================================================= */
export default EditDealerProfileForm;
