import React from 'react';
import { doc } from 'firebase/firestore';
import ShowMoreText from 'react-show-more-text';
import { useLocation, useParams } from 'react-router-dom';

import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import {
  Link,
  Header,
  Button,
  Spinner,
  Container,
  PhotosCarousel,
  LinkHeader,
} from '../../common';
import PostInfoCard from '../components/PostInfoCard';
import PostFeatured from '../components/PostFeatured';
import CategoryData from '../../static/categories-data';
import PostSellerInfo from '../components/PostSellerInfo';
import PostInteractionButtons from '../components/PostInteractionButtons';
import { postCollectionRef, userCollectionRef } from '../../config/firebase';

/* =============================================================================
<PostScreen />
============================================================================= */
const PostScreen = () => {
  const params = useParams();
  const { id } = params;
  const docRef = doc(postCollectionRef, id);
  // GET POST
  const {
    data: post,
    isLoading: postLoading,
  } = useFirestoreDocumentData(['post', id], docRef);

  const authorId = post?.author || 'none';

  // GET AUTHOR
  const ref = doc(userCollectionRef, authorId);
  const { data: author, isLoading: authorLoading } = useFirestoreDocumentData(
    ['users', authorId],
    ref,
  );

  const postId = post?.id;
  const postPrice = post?.price;
  const postCategory = post?.category;
  const postTitle = `${post?.year || ''} ${post?.make || post?.titleVehicle} ${
    post?.model || ''
  }`;
  const photos = post?.photos;

  const links = [
    {
      name: 'Home',
      url: '/home/cars',
    },
    {
      name: CategoryData?.find((c) => c.id === postCategory)?.title,
      url: `/category/${postCategory}`,
    },
    {
      name: postTitle,
      url: `/post/${postId}`,
    },
  ];

  if (authorLoading || postLoading) {
    return (
      <Container classes="flex justify-center items-center">
        <div>
          <Spinner size={40} />
        </div>
      </Container>
    );
  }

  return (
    <Container classes="!bg-[#262626] md:!bg-black">
      <Header stack childrenContainerClass="">
        <div className="h-10 mx-10 md:w-1/2 md:mx-auto mt-0 md:mt-2" />
      </Header>
      <div className="max-w-screen-xl mx-5 xl:mx-auto pb-10 xsm:mx-0">
        <div className="hidden md:block xsm:mx-3">
          <LinkHeader contents={links} />
        </div>
        <div className="flex flex-col w-full lg:flex-row lg:space-x-5">
          <div className="w-full space-y-2 xsm:space-y-0">
            <PhotosCarousel photos={photos} customStyles="md:w-full">
              <PostInteractionButtons postId={postId} />
            </PhotosCarousel>
            <div className="block lg:hidden">
              <PostInfoCard post={post} author={author} />
            </div>
            <div className="xsm:mx-3 pt-5 p-2 md:p-0">
              <div className="flex items-center justify-between">
                {post?.description && (
                <h1 className="text-2xl font-semibold underline-offset-2 text-primary underline underline-offset-1">Description</h1>
                )}
                {post?.features?.length > 0 ? (
                  <Link label="Features" to={`/post/${postId}/features`} textLarge />
                ) : <div />}
              </div>
              {post?.description && (
              <ShowMoreText lines={4} className="text-white mt-2 text-left mb-1 text-xl">
                <p className="text-white text-lg">
                  {`${post?.description}`}
                </p>
              </ShowMoreText>
              )}
              {!authorLoading && (
              <PostSellerInfo author={author} location={post?.location} id={post?.id} />
              )}
            </div>
          </div>
          <div className="md:w-1/2 h-full rounded-lg space-y-16 hidden lg:block">
            <PostInfoCard post={post} author={author} />
            <div className="bg-secondaryDark h-96">
              <p>Google Ad</p>
            </div>
          </div>
        </div>
        {postCategory && (
        <PostFeatured categoryId={postCategory} currentPostId={postId} />
        )}
      </div>
    </Container>
  );
};

/* Export
============================================================================= */
export default PostScreen;
