import {
  addDoc, collection, deleteDoc, doc, onSnapshot, query, setDoc,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { firestore } from '../../config/firebase';
import { Table } from '../../common/Table/Table';
import { SideSheet } from '../../common/Sidesheet';
import withAuth from '../../util/withAuth';

const Categories = () => {
  const [id, setId] = useState('');
  const columns = [
    {
      key: 'name',
      title: 'Category Name',
      dbColName: 'name',
    },
    {
      key: 'cretted',
      title: 'Created Date',
      dbColName: 'created',
      render: (date) => new Date(date).toDateString(),
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (values) => (
        <div className="flex gap-2">
          <button type="button" onClick={() => handleDelete(values.id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={() => {
              setIsOpen(true);
              setId(values.id);
              setEditing(true);
              setState({
                name: values.name,
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-blue-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [state, setState] = useState({
    name: '',
  });

  useEffect(() => {
    setLoading(true);
    // Create a query against the collection
    const q = query(collection(firestore, 'categories'));
    // Real-time updates listener
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const usersArray = [];

      for (const d of querySnapshot.docs) {
        const inventory = {
          id: d.id,
          ...d.data(),
        };
        usersArray.push(inventory);
      }

      setData(usersArray);
      setLoading(false);
    });

    // Detach listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleDelete = async (deleteId) => {
    if (window.confirm('Are you sure you want to delete the category?')) {
      // Save it!
      await deleteDoc(doc(firestore, 'categories', deleteId));
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  };

  const handleSubmit = async () => {
    try {
      // Add a new document with a generated id.
      if (editing) {
        await setDoc(doc(firestore, 'categories', id), state);
      } else {
        await addDoc(collection(firestore, 'categories'), {
          ...state,
          created: new Date().toUTCString(),
        });
      }
      // Create empty subcollections for subjects, notes, and quizzes
      // await Promise.all([
      //   setDoc(doc(collection(subcourseRef, 'subjects'))),
      //   setDoc(doc(collection(subcourseRef, 'notes'))),
      //   setDoc(doc(collection(subcourseRef, 'quizzes'))),
      // ]);

      setIsOpen(false);
      setId('');
      alert('Category added successfully');
    } catch (error) {
      console.error('Error adding package: ', error);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Categories
          </h1>
          <button
            onClick={() => {
              setEditing(false);
              setIsOpen(!isOpen);
            }}
            type="button"
            className="bg-primary px-3 py-1.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 "
          >
            Add New
          </button>
        </div>
        <p className="text-gray-500 text-sm">View all categories</p>
      </div>

      <Table
        data={data}
        columns={columns}
        totalItems={data?.length}
        loadingData={loading}
      />
      <SideSheet
        sidebarOpen={isOpen}
        setSidebarOpen={setIsOpen}
        handleClickPrimary={handleSubmit}
        title={editing ? 'Update Category' : 'Add New Category'}
      >
        <form className="flex flex-col gap-3.5">
          <div className="flex flex-col gap-1.5">
            <label
              htmlFor="name"
              className="text-zinc-800"
            >
              Category Name
              <input
                type="text"
                id="name"
                value={state.name}
                name="name"
                onChange={e => setState({
                  name: e.target.value,
                })}
                placeholder="Enter category name"
                className="rounded-md mt-1 border border-zinc-200 px-3 py-2 w-full shadow-sm"
              />
            </label>
          </div>
        </form>
      </SideSheet>
    </div>
  );
};

export default withAuth(Categories);
