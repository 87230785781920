import React, { useContext } from 'react';
import { collection, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import moment from 'moment';
import { Header, Container, Spinner } from '../../common';
import { firestore } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';
import { dealer, featuredPackages, seller } from '../../static/payments-data';

/* =============================================================================
<PaymentDetailsScreen />
============================================================================= */
const PaymentDetailsScreen = () => {
  const { id } = useParams();
  const { userId, user } = useContext(UserContext);

  const paymentCollectionRef = collection(
    firestore,
    `paymentLog/${userId}/history`,
  );
  const paymentDocRef = doc(paymentCollectionRef, id);
  const { data: payment, isLoading } = useFirestoreDocumentData(['pamentLog', id], paymentDocRef);

  const type = payment?.type;

  const paymentPackage = type === 'featured'
    ? featuredPackages.find(i => i?.priceId === payment?.priceId)
    : user?.type === 'private seller'
      ? seller.find(i => i?.priceId === payment?.priceId)
      : Object.values(dealer)
        ?.flatMap(d => [...d])
        ?.find(d => d?.priceId === payment?.priceId);

  return (
    <Container>
      <Header title="Payment Details" stack />
      {isLoading || !paymentPackage ? (
        <div className="flex justify-center items-center mt-52">
          <Spinner color="#fff" size={35} />
        </div>
      ) : (
        <div className="text-white space-y-8 max-w-screen-sm my-10 mx-auto">
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Payment Name :</h1>
            <p className="ml-5 text-xl">
              {`${paymentPackage?.title} Package`}
            </p>
          </div>
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Price :</h1>
            <p className="ml-5 text-xl">
              {`$${paymentPackage.price}`}
            </p>
          </div>
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Description :</h1>
            <p className="ml-5 text-xl">
              {`${paymentPackage?.description}`}
            </p>
          </div>
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Transaction id :</h1>
            <p className="ml-5 text-xl">
              {`${payment?.id}`}
            </p>
          </div>
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Status :</h1>
            <p className="ml-5 text-xl">
              Completed
            </p>
          </div>
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Date :</h1>
            <p className="ml-5 text-xl">
              {moment(payment?.createdAt.toDate()).format('LL')}
            </p>
          </div>
        </div>
      )}
    </Container>
  );
};

/* Export
============================================================================= */
export default PaymentDetailsScreen;
