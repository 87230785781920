import React, { useEffect, useState } from 'react';
import { getDownloadURL, uploadBytes, ref } from 'firebase/storage';
import { storage } from '../../config/firebase';
import withAuth from '../../util/withAuth';

const Home = () => {
  const [image, setImage] = useState('');

  useEffect(() => {
    const heroImageRef = ref(storage, 'hero/image.jpg');
    // Get the download URL
    getDownloadURL(heroImageRef)
      .then((url) => {
        // `url` is the download URL of the image
        setImage(url);
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error getting image URL:', error);
      });
  }, []);

  const handleUpload = async (e) => {
    const uploadFile = e.target.files[0];
    const storageRef = ref(storage, 'hero/image.jpg');
    await uploadBytes(storageRef, uploadFile);
    const url = await getDownloadURL(storageRef);
    setImage(url);
  };

  return (
    <div>
      {image ? (
        <div className="h-96 w-full relative">
          <img
            src={image}
            alt=""
            className="h-full w-full object-cover object-center"
          />
          <button type="button" onClick={() => setImage('')} className="bg-gray-200 rounded-full  p-2 absolute right-2 top-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      ) : (
        <label
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 "
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Click to upload thumbnail</span>
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG, JPG or GIF (MAX. 800x400px)
            </p>
          </div>
          <input
            onChange={handleUpload}
            id="dropzone-file"
            type="file"
            className="hidden"
          />
        </label>
      )}
    </div>
  );
};

export default withAuth(Home);
