import React, { useState } from 'react';
import MyEditor from '../../../common/MyEditor';
import request from '../../../util/request';

export const Privacy = ({ id, content: selected }) => {
  const [content, setContent] = useState(selected);

  const handleSave = async () => {
    request({
      url: '/updateFooter',
      method: 'POST',
      data: { id, content },
    })
      .then(() => {
        alert('Data updated successfully');
      }).catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="border border-zinc-200 rounded-lg p-3">
      <p className="font-semibold text-lg mb-2 text-zinc-800">Privacy</p>
      <MyEditor content={content} onChange={(value) => setContent(value)} />
      <button
        type="submit"
        onClick={handleSave}
        className="mt-3 bg-green-600 text-white rounded-md px-3 py-2"
      >
        Save
      </button>
    </div>
  );
};
