/* eslint-disable max-len */
/* eslint-disable no-shadow */
import { distanceBetween, geohashForLocation } from 'geofire-common';
import * as Yup from 'yup';
import { fromAddress } from 'react-geocode';
import {
  collection, getDocs, orderBy, query, where,
} from 'firebase/firestore';
import request from './request';
import { emptyFilterForm } from '../static/filter';
import { firestore } from '../config/firebase';

export const filterByDistance = (location, flattenPostData) => {
  try {
    if (
      !location?.radius
      || !flattenPostData
      || !Array.isArray(flattenPostData)
    ) {
      return flattenPostData;
    }
    const center = [Number(location.lat), Number(location.lng)];

    const radiusInM = parseFloat(location?.radius) * 1609.34;
    const matchingPosts = [];

    for (const post of flattenPostData) {
      const { location } = post;

      const distanceInKm = distanceBetween(
        [Number(location.lat), Number(location.lng)],
        center,
      );
      const distanceInM = distanceInKm * 1000;
      if (distanceInM <= radiusInM) {
        matchingPosts.push({ ...post, distance: distanceInM });
      }
    }
    const result = matchingPosts?.sort(
      (itemA, itemB) => itemA.distance - itemB.distance,
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const getActiveFilterCount = (filterValues) => {
  const count = Object.keys(
    Object.keys(filterValues).reduce((acc, key) => {
      const value = filterValues[key];
      if (typeof value === 'string' && value !== emptyFilterForm[key]) {
        acc[key] = value;
      }
      if (
        Array.isArray(value)
        && !value.every((item, index) => item === emptyFilterForm[key][index])
      ) {
        acc[key] = value;
      }
      return acc;
    }, {}),
  )?.length;
  return count;
};

export const removeString = (str, partToRemove) => {
  if (str?.includes(partToRemove)) {
    return str.replace(partToRemove, '');
  }
  return str;
};

export const generateFormDefaultValues = (fields = [], values) => {
  const defaultValues = {
    location: values
      ? values?.location
      : {
        name: '',
        lat: 0,
        lng: 0,
        zipCode: '',
      },
    includePhone: values ? values.includePhone : false,
    photos: values ? values?.photos : [],
  };

  fields?.forEach((field) => {
    const { name, type } = field;
    console.log('🚀 ~ fields?.forEach ~ name, type:', name, type);

    switch (type) {
      case 'select':
      case 'text':
      case 'textArea':
        defaultValues[name] = values ? values[name] : '';
        break;
      case 'number':
        defaultValues[name] = values ? values[name].toString() : '';
        break;
      case 'modal':
        defaultValues[name] = field.isMultiSelect
          ? values
            ? values[name]
            : []
          : values
            ? values[name]
            : '';
        break;
      // Add more cases for other field types as needed
      default:
        break;
    }
  });

  return defaultValues;
};

export const generateYupSchema = (fields) => {
  const schema = {
    location: Yup.object({
      zipCode: Yup.string(),
      name: Yup.string(),
      lat: Yup.number(),
      lng: Yup.number(),
    }).test({
      test: (d) => d?.zipCode,
      message: 'Please add location',
    }),
    photos: Yup.array()
      .of(Yup.string())
      .test({
        message: 'Select atlest 1 image',
        test: (images) => Object.keys(images).length >= 1,
      })
      .required('Select atlest 1 image'),
  };

  fields.forEach((field) => {
    const { name, type } = field;

    switch (type) {
      case 'number':
        if (name === 'year' || name === 'price') {
          schema[name] = Yup.number()
            .typeError('Must be a valid number')
            .required('This field is required');
        } else {
          schema[name] = Yup.string().typeError('Must be a valid number');
        }
        break;
      case 'text':
      case 'textArea':
        if (name === 'model') {
          schema[name] = Yup.string().trim().required('This field is required');
        } else {
          schema[name] = Yup.string().trim();
        }
        break;
      case 'select':
        schema[name] = Yup.string().required('Atlest select one option');
        break;
      case 'modal':
        if (name === 'make') {
          schema[name] = field.isMultiSelect
            ? Yup.array()
              .of(Yup.string().required('Text Required'))
              .test({
                message: 'At least select one feature',
                test: (arr) => arr.length !== 0,
              })
              .required('This field is required')
            : Yup.string().required('This field is required');
        } else {
          schema[name] = field.isMultiSelect
            ? Yup.array().of(Yup.string())
            : Yup.string();
        }
        break;
      default:
        break;
    }
  });

  return Yup.object().shape(schema);
};
export const generateGuestId = () => {
  const id = JSON.parse(localStorage.getItem('@guestId'));
  if (id) {
    return id;
  }
  const generateId = `GuestId${Math.random().toString(14).slice(2)}`;
  localStorage.setItem('@guestId', JSON.stringify(generateId));
  return generateId;
};
export const getBlobFroUri = async (uri) => {
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function onLoad() {
      resolve(xhr.response);
    };
    xhr.onerror = function onError(e) {
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });

  return blob;
};

export const getEditFormDefaultValues = (post) => {
  const fields = { ...post };

  for (const key in fields) {
    if (typeof fields[key] === 'number') {
      fields[key] = fields[key].toString();
    } else {
      fields[key] = fields[key];
    }
  }

  delete fields.author;
  delete fields.createdAt;
  delete fields.photos;
  delete fields.includePhone;
  delete fields.updatedAt;

  return fields;
};

export const filterCars = (carData = [], filters) => {
  console.log('🚀 ~ filterCars ~ carData:', carData);
  console.log('🚀 ~ filterCars ~ filters:', filters);
  if (filters === undefined) {
    return carData; // Return the entire carData array if filters is undefined
  }
  // Check each filter condition
  return carData.filter(
    (car) => (filters.bodyStyle.length === 0
        || filters.bodyStyle.includes(car.bodyStyle))
      && (filters.condition.length === 0 || filters.condition.includes(car.condition))
      && (filters.fuel.length === 0 || filters.fuel.includes(car.fuel))
      && (filters.location === '' || filters.location === car.location)
      && (filters.makes.length === 0 || filters.makes.includes(car.make))
      && (filters.model === ''
        || filters.model?.toLowerCase() === car.model?.toLowerCase())
      && (filters.priceFrom === ''
        || car.price >= parseFloat(filters.priceFrom))
      && (filters.priceTo === '' || car.price <= parseFloat(filters.priceTo))
      && (filters.sellerType === '' || filters.sellerType === car.sellerType)
      && (filters.title.length === 0 || filters.title.includes(car.title))
      && (filters.transmission.length === 0
        || filters.transmission.includes(car.transmission))
      && (filters.yearFrom === '' || car.year >= parseInt(filters.yearFrom))
      && (filters.yearTo === '' || car.year <= parseInt(filters.yearTo))
      && (filters.zipCode === '' || filters.zipCode === car.location.zipCode)
      && (filters.miles.every((item, i) => item === emptyFilterForm?.miles[i])
        || (Number(car.miles) >= filters.miles[0]
          && filters.miles[1] === emptyFilterForm?.miles[1])
        || (Number(car.miles) >= filters.miles[0]
          && Number(car.miles) <= filters.miles[1])),
  );
};
export const getDistance = (lat1, lon1, center) => new Promise((resolve, reject) => {
  const currentLocation = window.navigator && window.navigator.geolocation;
  if (center) {
    const d = calculateDistance(lat1, lon1, center);
    resolve(Math.round(d));
  }
  if (currentLocation) {
    currentLocation.getCurrentPosition(
      (position) => {
        const loc = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const d = calculateDistance(lat1, lon1, loc);
        resolve(Math.round(d));
      },
      (error) => {
        reject(error.message);
      },
    );
  }
});
function calculateDistance(lat1, lon1, center) {
  const { lat: lat2, lng: lon2 } = center;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1))
      * Math.cos(deg2rad(lat2))
      * Math.sin(dLon / 2)
      * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function getInitials(name) {
  if (typeof name !== 'string' || !name.trim()) {
    return '';
  }

  const words = name.split(' ');
  let initials = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (!word) {
      // eslint-disable-next-line no-continue
      continue;
    }

    initials += word[0].toUpperCase();

    if (initials.length >= 2) {
      break;
    }
  }

  return initials;
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const saveData = async (key, value) => {
  try {
    await localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchData = async (key) => {
  try {
    const jsonValue = await localStorage.getItem(key);
    return jsonValue !== null ? JSON.parse(jsonValue) : null;
  } catch (err) {
    throw new Error(err);
  }
};

export const getLocation = async (code) => {
  try {
    const COUNTRY = 'United States';
    const { results } = await fromAddress(`${COUNTRY} ${code?.trim()}`);
    const addressComponents = results[0]?.address_components;
    if (addressComponents?.length > 1) {
      const formattedAddress = results[0]?.formatted_address;
      const state = getAddress(
        addressComponents,
        'administrative_area_level_1',
      );
      const postalCode = getAddress(addressComponents, 'postal_code');
      const city = extractCity(formattedAddress);
      const coordinates = results[0]?.geometry.location;
      const { lat, lng } = coordinates;
      const location = {
        ...coordinates,
        zipCode: code?.trim(),
        hash: geohashForLocation([lat, lng]),
        formattedAddress: results[0]?.formatted_address,
        filteredAddress: `${postalCode}, ${state}`,
        state,
        city,
      };
      return location;
    }
    throw new Error('No location found from this zip code');
  } catch (e) {
    if (e?.origin?.status) {
      throw new Error('No location found from this zip code');
    } else {
      throw new Error(e?.message);
    }
  }
};

const getAddress = (addressComponents, type) => {
  const address = addressComponents.find(({ types }) => types.includes(type));
  return address?.long_name || '';
};
const extractCity = (formattedAddress) => formattedAddress.split(',')[0] || '';

export async function fetchItems({ pageParam }, location, id) {
  const LIMIT = 40;
  try {
    if (!location?.radius) {
      const queryRef = id
        ? query(
          collection(firestore, 'posts'),
          where('category', '==', id),
          orderBy('createdAt', 'desc'),
        )
        : query(collection(firestore, 'posts'), orderBy('createdAt', 'desc'));

      const docs = await getDocs(queryRef);
      const data = await docs?.docs?.map((data) => data.data());

      return new Promise((resolve) => {
        resolve({
          data: data.slice(pageParam, pageParam + LIMIT),
          currentPage: pageParam,
          nextPage: pageParam + LIMIT < data.length ? pageParam + LIMIT : null,
        });
      });
    }
    const payload = await request({
      url: '/filterByLocation',
      method: 'POST',
      data: {
        pageParam,
        location,
        id,
        limit: LIMIT,
      },
    });
    return payload;
  } catch (error) {
    throw new Error(error);
  }
}
