/* eslint-disable react/button-has-type */
import React, { useId } from 'react';

import { RxCross1 } from 'react-icons/rx';
import { Filter } from 'react-feather';
import { classNames } from '../../util/functions';
import { Button } from '../../common';

function HomeFilterButton({
  size,
  customStyleClasses,
  onReset,
  activeFilters,
  onFilterPress,
}) {
  const commonStyles = 'flex justify-center relative space-x-5 hover:opacity-75 items-center appearance-none focus:outline-none';
  const sizeStyles = `rounded-full px-4 sm:px-10 py-2 h-auto sm:h-10 ${
    size === 'block' && 'w-full'
  }`;
  const variantStyles = 'border shadow-sm font-medium text-white bg-lightWhite focus:outline-none disabled:opacity-50 hover:bg-gray-25';

  // Important: The <span must be there as chrome won't show a tooltip on a disabled element.

  return (
    <div className={classNames(
      variantStyles,
      sizeStyles,
      commonStyles,
      customStyleClasses,
    )}
    >
      <Button title="Filters" variant="transparent" customTxtStyles="mini:text-sm" onClick={onFilterPress} icon={<Filter color="white" size={22} className="mini:hidden" />} size="block" />
      <button type="button" onClick={onReset} className="flex absolute right-1 hover:opacity-75 appearance-none focus:outline-none justify-between items-center rounded-full border border-solid border-white bg-primary text-white p-1 lg:px-3 space-x-1">
        <span className="text-white text-sm">{activeFilters}</span>
        <span className="text-white text-sm hidden md:flex">Reset</span>
        <RxCross1 color="white" size={14} />
      </button>
    </div>
  );
}

export default HomeFilterButton;
