import React, { useEffect, useState } from 'react';
import {
  collection, getDocs, orderBy, query, where,
} from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogContent from '../../common/BlogContent';
import { firestore } from '../../config/firebase';

const Blog = () => {
  const [blog, setBlog] = useState(null);
  const [allBlogs, setAllBlogs] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(firestore, 'blogs'),
        where('slug', '==', slug),
        orderBy('createdAt', 'desc'), // Sorting by latest
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs[0]?.data();
      setBlog(data);

      const snapshot = await getDocs(collection(firestore, 'blogs'));
      const allData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAllBlogs(allData);
    };

    fetchData();
  }, [slug]);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div>
      <Helmet>
        <title>
          Blog -
          {' '}
          {blog?.title}
        </title>
      </Helmet>
      <section className="container mx-auto px-4 xl:px-0 py-6 sm:pb-12">
        <div className="hidden md:flex text-zinc-600 flex-wrap gap-2 ">
          <Link to="/">Home /</Link>
          <Link to="/blog">Blog /</Link>
          <span className="text-primary font-semibold">{blog.title}</span>
        </div>
        <div className="md:mt-6 flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-2/3">
            <p className="text-gray-600 text-base md:text-xl mb-6 pb-3 border-b-2 border-zinc-300">
              {blog.category}
            </p>

            <h1 className="text-2xl md:text-4xl text-gray-800 font-semibold ">
              {blog?.title}
            </h1>
            <div className="flex justify-between">
              <p className="text-gray-700 mt-2 mb-4">{blog.createdAt}</p>
              <p className="text-gray-700 mt-2 mb-4">
                By:
                {blog.author}
              </p>
            </div>
            <div className="h-44 md:h-96 w-full border-b border-gray-300 mb-8">
              <img
                src={blog?.thumbnail}
                alt=""
                className="h-full w-full  object-cover overflow-hidden shadow"
              />
            </div>
            <BlogContent content={blog.content} />
          </div>
          <div className="flex-1 self-start">
            <div className="flex justify-between items-center">
              <p className="font-semibold text-zinc-600">Share</p>
              <div className="flex gap-2 py-3">
                <a href="https://x.com" target="_blank" rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler bg-zinc-100 rounded-full p-2 icons-tabler-outline text-zinc-700 icon-tabler-brand-x"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                    <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
                  </svg>
                </a>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline text-zinc-700 bg-zinc-100 rounded-full p-2 icon-tabler-brand-facebook"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                  </svg>
                </a>
                <a href="https://google.com" target="_blank" rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline text-zinc-700 bg-zinc-100 rounded-full p-2 icon-tabler-message-circle"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 20l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c3.255 2.777 3.695 7.266 1.029 10.501c-2.666 3.235 -7.615 4.215 -11.574 2.293l-4.7 1" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="shadow-sm border border-zinc-200 rounded-lg  p-4 ">
              <p className="text-zinc-600 text-lg font-semibold border-b border-zinc-200 pb-2">
                Recent Posts
              </p>
              <div className="flex flex-col gap-4 mt-4">
                {allBlogs.slice(0, 9).map(item => (
                  <div key={item.id} className="flex gap-2">
                    <div className="h-24 w-36 flex-shrink-0">
                      <img
                        alt=""
                        src={item.thumbnail}
                        className="h-full w-full rounded-lg object-cover"
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <Link
                        to={`/blog/${item.slug}`}
                        className="text-zinc-900 hover:text-primary cursor-pointer"
                      >
                        {item.title.slice(0, 60)}
                        ...
                      </Link>
                      <p className="text-xs text-zinc-700">{item.createdAt}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
