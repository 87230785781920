import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  doc,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import {
  useFirestoreDocumentData,
  useFirestoreInfiniteQueryData,
} from '@react-query-firebase/firestore';

import ProfileHeader from '../components/ProfileHeader';
import PostListItem from '../../post/components/PostListItem';
import { Container, PaginationList, PostCard } from '../../common';
import { postCollectionRef, userCollectionRef } from '../../config/firebase';

import { setPostData as setPostDataAction, setQuery as setQueryAction } from '../../post/redux/actions';

/* =============================================================================
<ProfileScreen />
============================================================================= */
const ProfileScreen = ({ setQuery, setPostData }) => {
  const history = useHistory();
  const { id } = useParams();

  // GETTING AUTHOR
  const authorDoc = doc(userCollectionRef, id);
  const { data: user } = useFirestoreDocumentData(['users', id], authorDoc);

  // GETTING USER POSTS WITH PAGINATION
  const queryKey = ['userPosts', id];
  const queryRef = query(
    postCollectionRef,
    limit(10),
    orderBy('createdAt', 'desc'),
    where('author', '==', id),
  );
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFirestoreInfiniteQueryData(
    queryKey,
    queryRef,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(queryRef, startAfter(lastDocument?.createdAt || ''));
    },
  );
  const isUserDealer = user?.type === 'dealer';

  const flattenData = data?.pages
    ? data?.pages
      ?.flatMap(page => [...page])
      .sort((a, b) => Number(b?.isFeatured) - Number(a?.isFeatured))
    : [];

  const renderItem = useCallback((item, index) => (
    <>
      {isUserDealer ? (
        <PostCard
          item={item}
          key={item.id}
          onPress={() => {
            setPostData({ index, data: flattenData });
            history.push('/post/data');
            // setQuery({ index, queryKey, queryRef });
            // history.push('/post');
          }}
        />
      ) : (
        <PostListItem
          post={item}
          onClick={() => {
            setPostData({ index, data: flattenData });
            history.push('/post/data');
            // setQuery({ index, queryKey, queryRef });
            // history.push('/post');
          }}
        />
      )}
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [flattenData?.length]);

  return (
    <Container>
      <ProfileHeader user={user} numberOfPosts={flattenData?.length} />
      <div className="w-full h-px bg-gray-400" />
      {isUserDealer && (
        <p className="text-white text-2xl md:mx-14 font-semibold mt-5">Inventory</p>
      )}
      <PaginationList
        center={!isUserDealer}
        data={flattenData}
        loading={isLoading}
        horizontal={isUserDealer}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        styles={isUserDealer && 'mx-5 xl:mx-12'}
        fetchNextPage={fetchNextPage}
        emptyText="User have no posts"
        isFetchingNextPage={isFetchingNextPage}
      />
    </Container>
  );
};

const mapDispatchToProps = {
  setPostData: setPostDataAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(ProfileScreen);
