/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  postQuery: { index: 0, queryKey: null, queryRef: null },
  postData: { index: 0, data: [] },
};

export const postSlice = createSlice({
  name: 'post',
  initialState: INITIAL_STATE,
  reducers: {
    setQuery: (state, action) => ({
      postQuery: action.payload,
    }),
    setPostData: (state, action) => ({
      postData: action.payload,
    }),
  },
});

export default postSlice.reducer;
