import React, { useEffect, useState } from 'react';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { firestore } from '../../config/firebase';
import BlogContent from '../../common/BlogContent';

const About = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(firestore, 'footer'),
        where('name', '==', 'About us'),
      );
      const querySnapshot = await getDocs(q);
      const about = querySnapshot.docs[0]?.data();
      setData(about);
    };

    fetchData();
  }, []);

  return (
    <div className="container p-4 md:p-0 mx-auto">
      <Helmet>
        <title>Carsnus - About</title>
      </Helmet>
      <h2 className="py-4 font-semibold text-4xl ">About Us</h2>
      <div className="flex flex-col md:flex-row ">
        <div className="flex-1 mt-6">
          <BlogContent content={data?.content} />
        </div>
        <div className="flex-1 flex-grow">
          <div className="md:h-screen relative">
            <img src="/wall.jpg" alt="" className="w-full object-cover h-full" />
            <div className="image-gradient-overlay absolute top-0 left-0 w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
