/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import {
  doc,
  where,
  limit,
  query,
  orderBy,
  collection,
  Timestamp,
} from 'firebase/firestore';
import {
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { Container, Header } from '../../common';
import ChatHeader from '../components/ChatHeader';
import ChatInput from '../components/ChatInput';
import {
  auth,
  firestore,
  userCollectionRef,
  conversationCollectionRef,
} from '../../config/firebase';
import ChatList from '../components/ChatList';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<ChatScreen />
============================================================================= */
const ChatScreen = () => {
  const { conversationId } = useParams();
  const { userId: currentUserId } = useContext(UserContext);

  // GETTING CONVERSATION BY ID
  const conversationRef = doc(conversationCollectionRef, conversationId);
  const { data: conversation } = useFirestoreDocumentData(
    ['conversation', conversationId],
    conversationRef,
  );
  // GETTING OTHER USER
  const otherUserId = conversation?.members?.find(m => m !== currentUserId) || 'none';
  const otherUserRef = query(
    userCollectionRef,
    where('id', '==', otherUserId),
    limit(1),
  );
  const { data: otherUser, isLoading } = useFirestoreQueryData(
    ['users-query', otherUserId],
    otherUserRef,
    {
      subscribe: false,
    },
    {
      select(snapshot) {
        return snapshot[0];
      },
    },
  );

  const { mutateAsync: updatedConversation } = useFirestoreDocumentMutation(
    conversationRef,
    { merge: true },
  );

  // GETTING MESSAGES
  const messagesCollection = collection(
    firestore,
    `conversations/${conversationId}/messages`,
  );

  // SEND MESSAGE CALL
  const sendMessageRef = doc(messagesCollection);
  const { mutateAsync: sendMessage } = useFirestoreDocumentMutation(sendMessageRef);

  // UPDATING OTHER USER UNREAD_MESSAGES COUNT CALl
  const docRef = doc(userCollectionRef, otherUserId);
  const { mutateAsync: updateOtherUser } = useFirestoreDocumentMutation(
    docRef,
    {
      merge: true,
    },
  );

  const _handleSend = async text => {
    try {
      if (text) {
        const newMessage = {
          text,
          mark_as_seen: false,
          _id: sendMessageRef.id,
          author: currentUserId,
          createdAt: new Date().toString(),
          user: {
            _id: currentUserId,
          },
        };
        await sendMessage(newMessage);

        await updatedConversation({
          lastMessage: sendMessageRef.id,
          updatedAt: Timestamp.now(),
        });

        await updateOtherUser({
          ...otherUser,
          unread_messages: (otherUser?.unread_messages || 0) + 1,
        });
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  return (
    <Container classes="flex flex-col relative ">
      <Header stack>
        <div className="flex justify-center">
          <ChatHeader otherUser={otherUser} isLoading={isLoading} />
        </div>
      </Header>
      <div className="max-h-full mt-10 py-12 pt-5  overflow-y-hidden">
        <ChatList />
      </div>
      <div className="absolute bottom-0 w-full">
        <ChatInput onSend={_handleSend} />
      </div>
    </Container>
  );
};

/* Export
============================================================================= */
export default ChatScreen;
