/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';
import { collection, doc, Timestamp } from 'firebase/firestore';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';
import {
  Header,
  Button,
  Spinner,
  PostCard,
  Container,
  EmptyState,
  SearchInput,
  PaginationList,
} from '../../common';
import { useGetSearchedPosts } from '../api/queries';
import HomeSearchHistory from '../components/HomeSearchHistory';
import { emptyFilterForm } from '../../static/filter';

import { setPostData as setPostDataAction } from '../../post/redux/actions';
import { UserContext } from '../../Provider/UserProvider';
import HomeCategoryHeader from '../components/HomeCategoryHeader';
import HomeFilterModal from '../components/HomeFilterModal';
import categoriesData from '../../static/categories-data';
import HomeCategorySavedButton from '../components/HomeCategorySavedButton';
import { useLocation } from '../../Provider/LocationProvider';
import { auth, firestore } from '../../config/firebase';

/* =============================================================================
<HomeSearchScreen />
============================================================================= */
const HomeSearchScreen = ({ setPostData }) => {
  const userId = auth?.currentUser?.uid;
  const history = useHistory();
  const resetRef = useRef(null);
  const [searchTxt, setSearchTxt] = useState('');
  const [filters, setFilters] = useState(emptyFilterForm);
  const [filtersModal, setFiltersModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const { guestId } = useContext(UserContext);
  const { location } = useLocation();
  const historyId = useMemo(
    () => `historyId${Math.random().toString(16).slice(2)}`,
    [searchTxt],
  );
  const path = userId && userId !== 'none'
    ? `searchHistory/${userId}/history`
    : `searchHistory/${guestId}/history`;
  const historyCollection = collection(firestore, path);
  const historyRef = doc(historyCollection, historyId);
  const { mutateAsync: addSearchHistroy } = useFirestoreDocumentMutation(historyRef);
  const _toggleFiltersModal = () => setFiltersModal((prev) => !prev);
  // GETTING POSTS WITH PAGINATION
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    refetch: getPosts,
    isFetchingNextPage,
  } = useGetSearchedPosts(searchTxt, filters, guestId, location);

  const category = searchTxt && data && categoriesData.find((c) => c.id == data[0]?.category);
  useEffect(() => {
    if (searchTxt) {
      getPosts();
    }
  }, [searchTxt]);
  const _handleReset = () => {
    resetRef.current.onReset();
  };
  const handleSearchText = (text) => {
    setSearchTxt(text);
    _handleReset();
    setFilterCount(0);
  };
  useEffect(() => {
    const addHistroy = async () => {
      if (data?.length > 0 && searchTxt?.length >= 3) {
        await addSearchHistroy({
          id: historyId,
          searchTxt,
          createdAt: Timestamp.now(),
        });
      }
    };
    addHistroy();
  }, [data?.length]);
  const _renderContent = useCallback(() => {
    if (searchTxt && data?.length > 0) {
      return (
        <>
          <HomeCategoryHeader
            searchTxt={searchTxt}
            setSearchTxt={handleSearchText}
            searchScreen
            onFilterPress={_toggleFiltersModal}
            categoryID={category?.id}
            onReset={_handleReset}
            filterCount={filterCount}
          />
          <div className="flex flex-1 justify-between items-center pl-7 pr-5">
            <h2 className="text-white sm:text-lg">
              {` Vehicles Found : ${data?.length}`}
            </h2>
            <HomeCategorySavedButton
              categoryId={category?.id}
              customStylesClasses="xsm:flex hidden"
            />
          </div>
          <PaginationList
            horizontal
            center={false}
            data={data}
            loading={isLoading}
            renderItem={renderItem}
            styles="mx-5 xl:mx-12 xsm:mt-0"
            hasNextPage={hasNextPage}
            onEndReached={fetchNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </>
      );
    }

    if (searchTxt && data?.length === 0) {
      return (
        <div className="mt-52 flex justify-center items-center">
          <EmptyState
            title="No results found"
            description="please search something else"
          />
        </div>
      );
    }

    if (isLoading && searchTxt) {
      return (
        <div className="mt-52 flex justify-center items-center">
          <Spinner size={22} color="#fff" />
        </div>
      );
    }
    return (
      <HomeSearchHistory
        onSearchSelect={setSearchTxt}
        containerStyles="xsm:mt-0"
      />
    );
  }, [isLoading, data?.length]);

  const renderItem = (item, index) => (
    <PostCard
      item={item}
      key={item.id}
      onPress={() => {
        setPostData({ index, data });
        history.push('/post/data');
      }}
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  return (
    <Container>
      {!(searchTxt && data?.length > 0) && (
        <div className="flex flex-1  sticky top-0 z-50 items-center justify-between pt-8 pb-7 pl-3 pr-5  space-x-3 bg-gradient-to-t from-black to-lightBlack' : 'bg-gradient-to-t from-black to-primary">
          <button type="button" onClick={history.goBack}>
            <ChevronLeft color="white" size={35} />
          </button>
          <SearchInput
            searchTxt={searchTxt}
            placeholder="Search any vehicle"
            autoFocus
            onSearch={(e) => setSearchTxt(e?.target?.value || e)}
          />
        </div>
      )}
      {_renderContent()}
      <HomeFilterModal
        category="search"
        filters={filters}
        visible={filtersModal}
        onFilterChange={setFilters}
        onClose={() => setFiltersModal(false)}
        ref={resetRef}
        addFilterCount={setFilterCount}
      />
    </Container>
  );
};

const mapDispatchToProps = {
  setPostData: setPostDataAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(HomeSearchScreen);
