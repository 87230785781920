import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { useHistory } from 'react-router-dom';
import { storage } from '../../config/firebase';

const Hero = () => {
  const [image, setImage] = useState('');
  const { push } = useHistory();

  useEffect(() => {
    const heroImageRef = ref(storage, 'hero/image.jpg');
    // Get the download URL
    getDownloadURL(heroImageRef)
      .then((url) => {
        // `url` is the download URL of the image
        setImage(url);
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error getting image URL:', error);
      });
  }, []);

  return (
    <div className="w-full">
      <div className="h-[27rem] md:h-[30rem] relative">
        <h1 className="absolute whitespace-normal md:whitespace-nowrap md:bg-zinc-700  md:bg-opacity-25 px-4 md:p-3 rounded-lg text-center md:left-1/2 md:-translate-x-1/2 text-3xl md:text-5xl top-20 font-bold text-white ">
          New & used Vehicles for Sale
        </h1>
        <img src={image} alt="" className="h-full w-full object-cover" />

        <div className="absolute mt-12 md:mt-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <input
            onClick={() => push('/search')}
            className="bg-white rounded-full focus:outline-none px-8 w-72 md:w-96 bg-opacity-100 text-zinc-900 py-3 "
            placeholder="Search a car..."
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
