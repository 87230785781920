import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { classNames } from '../util/functions';

function Link({
  label, to, textLarge,
}) {
  return (
    <NavLink
      to={to}
      style={{ fontSize: textLarge ? '1.4rem' : 14 }}
      className={classNames('font-medium text-primary hover:opacity-75 hover:text-primary', textLarge && 'text-xl')}
    >
      {label}
    </NavLink>
  );
}

Link.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Link;
