import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './page';
import DashboardLayout from '../components/dashboard/layout';
import MakeList from './make-list';
import Login from './login';
import FooterLinks from './footer-links';
import Categories from './categories';
import Home from './home';
import Blogs from './blogs';
import AddBlog from './add-blog';
import Posts from './posts';
import Admins from './admins';
import Packages from './packages';

/* =============================================================================
<DashboardScreens />
============================================================================= */
const DashboardScreens = () => (
  <>
    <Helmet>
      <title>Carsnus | Dashboard</title>
    </Helmet>
    <Switch>
      <Route path="/dashboard/login" exact>
        <Login />
      </Route>
      <DashboardLayout>
        <Route path="/dashboard" exact>
          <Dashboard />
        </Route>
        <Route path="/dashboard/make-list" exact>
          <MakeList />
        </Route>
        <Route path="/dashboard/categories" exact>
          <Categories />
        </Route>
        <Route path="/dashboard/home" exact>
          <Home />
        </Route>
        <Route path="/dashboard/blogs" exact>
          <Blogs />
        </Route>
        <Route path="/dashboard/add-blog" exact>
          <AddBlog />
        </Route>
        <Route path="/dashboard/posts" exact>
          <Posts />
        </Route>
        <Route path="/dashboard/packages" exact>
          <Packages />
        </Route>
        <Route path="/dashboard/admins" exact>
          <Admins />
        </Route>
        <Route path="/dashboard/footer-links" exact>
          <FooterLinks />
        </Route>
      </DashboardLayout>
    </Switch>
  </>
);

/* Export
============================================================================= */
export default DashboardScreens;
