import React from 'react';
import { Controller } from 'react-hook-form';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Info } from 'react-feather';

import { reorder } from '../../util/functions';
import ImageMuiltipleUploadAddBtn from './ImageMuiltipleUploadAddBtn';
import ImageMuiltipleUploadImageCard from './ImageMuiltipleUploadImageCard';

/* =============================================================================
<ImageMuiltipleUpload />
============================================================================= */
const ImageMuiltipleUpload = ({
  name, errorText, numOfImgs, control, showAdditionalInfo,
}) => (
  <Controller
    control={control}
    render={({ field: { onChange, value } }) => (
      <>
        <DragDropContext onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }

          const items = reorder(
            value,
            result.source.index,
            result.destination.index,
          );
          onChange(items);
        }}
        >
          <Droppable droppableId="droppableList" direction="horizontal">
            {(provided) => (
              <ul
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex space-x-1 overflow-x-auto md:flex-wrap"
              >
                {

                value?.map((item, index) => (
                  <ImageMuiltipleUploadImageCard
                    key={item}
                    url={item}
                    index={index}
                    onDelete={() => onChange(value?.filter((v) => v !== item))}
                  />
                ))
}
                <ImageMuiltipleUploadAddBtn
                  id="add"
                  value={value}
                  numOfImgs={numOfImgs}
                  index={value?.length}
                  onChange={onChange}
                />
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {showAdditionalInfo && (
          <p className="text-white text-center text-sm">
            {value?.length > 1
              ? 'Drag to modify positions'
              : '1st image selected will be cover'}
          </p>
        )}
        {!!errorText && (
        <div className="flex items-center justify-center space-x-1 mt-2">
          <Info color="#dc2626" size={20} />
          <p className="text-md font-medium  py-white text-red-500">
            {errorText}
          </p>
        </div>
        )}
      </>
    )}
    name={name}
  />
);

export default ImageMuiltipleUpload;
