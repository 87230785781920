import React, { useRef } from 'react';

import Spinner from './Spinner';
import EmptyState from './EmptyState';
import useIntersectionObserver from '../util/useIntersectionObserver';
import { classNames } from '../util/functions';

/* =============================================================================
<PaginationList />
============================================================================= */
const PaginationList = ({
  data,
  center,
  loading,
  emptyText,
  numColumns,
  horizontal,
  renderItem,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  styles,
}) => {
  const horizontalStyles = classNames('grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-3', numColumns === '2' ? 'md:grid-cols-2' : 'lg:grid-cols-4');
  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  return (
    <div>
      <div className={classNames('space-y-5 my-6 xsm:my-3', center && 'px-4 sm:px-6 lg:px-8 max-w-screen-lg mx-auto', styles)}>
        <ul className={classNames(`${!horizontal ? 'space-y-4' : 'md:space-y-0'}`, horizontal && horizontalStyles)}>
          {!loading && data?.map(renderItem)}
        </ul>

        {/* fetch data on scroll end */}
        <div
          ref={loadMoreRef}
          className={`${!hasNextPage ? 'hidden' : ''}`}
        />
      </div>

      {/* loading spinner */}
      {(loading || (hasNextPage && isFetchingNextPage)) && (
        <div className="text-center mt-52">
          <Spinner />
        </div>
      )}

      {/* Empty State */}
      {(!loading && data?.length === 0) && (
        <div className="text-center mt-52">
          <EmptyState
            title="No results"
            description={emptyText}
          />
        </div>
      )}
    </div>
  );
};

PaginationList.defaultProps = {
  center: true,
  numColumns: 4,
};

/* Export
============================================================================= */
export default PaginationList;
