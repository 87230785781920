import React from 'react';
import toast from 'react-hot-toast';
import { doc } from 'firebase/firestore';
import { CheckCircle } from 'react-feather';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';

import { Spinner } from '../../common';
import * as Colors from '../../config/colors';
import { client } from '../../config/react-query';
import { auth, userCollectionRef } from '../../config/firebase';
import { useSendNotification } from '../../notifications/api/mutations';
import { classNames } from '../../util/functions';

/* =============================================================================
<StoryFollowButton />
============================================================================= */
const StoryFollowButton = ({ author, type }) => {
  const authorId = author?.id;
  const currentUserId = auth?.currentUser?.uid;

  // UPDATE AUTHOR FOLLOWERS CALL
  const authorRef = doc(userCollectionRef, authorId);
  const {
    isLoading,
    mutateAsync: updatedAuthor,
  } = useFirestoreDocumentMutation(authorRef, { merge: true });

  const { mutateAsync: sendNotification } = useSendNotification();

  const isCurrentUserAFollower = author?.followers?.find(
    followerId => followerId === currentUserId,
  );

  const _handleFollowPress = async () => {
    try {
      if (currentUserId) {
        if (isCurrentUserAFollower) {
          updatedAuthor({
            ...author,
            followers: author?.followers?.filter(
              followerId => followerId !== currentUserId,
            ),
          });
        } else {
          updatedAuthor({
            ...author,
            followers: [...author?.followers, currentUserId],
          });

          sendNotification({
            to: authorId,
            type: 'follow',
            from: currentUserId,
          });
        }
      } else {
        toast.error('Please Sign in to follow others');
      }

      client.invalidateQueries(['users-query', authorId]);
    } catch (e) {
      toast.error('Something went wrong', e?.message);
    }
  };

  return (
    <button type="button" className="p-5 flex" onClick={_handleFollowPress}>
      {isCurrentUserAFollower ? (
        <CheckCircle color={Colors.primary} size={22} />
      ) : (
        <>
          {isLoading ? (
            <Spinner color={Colors.primary} size={type === 'large' ? 35 : 22} />
          ) : (
            <p className={classNames('text-primary', type === 'large' ? 'text-xl' : 'text-md')}>Follow</p>
          )}
        </>
      )}
    </button>
  );
};

const propsAreEqual = (prevProps, nextProps) => prevProps?.author?.id === nextProps?.author?.id
  && prevProps?.author?.followers?.length === nextProps?.author?.followers?.length;

/* Export
============================================================================= */
export default React.memo(StoryFollowButton, propsAreEqual);
