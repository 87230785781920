import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import BlogScreen from './BlogScreen';
import SettingsScreen from './SettingsScreen';
import EditProfileScreen from './EditProfileScreen';
import TopPostIntructionScreen from './TopPostIntructionScreen';
import TermsAndConditionScreen from './TermsAndConditionScreen';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';

/* =============================================================================
<SettingsScreens />
============================================================================= */
function SettingsScreens() {
  return (
    <>
      <Helmet>
        <title>Settings | Carsnus</title>
      </Helmet>
      <Switch>
        <Route path="/settings" exact>
          <SettingsScreen />
        </Route>
        <Route path="/settings/user-edit" exact>
          <EditProfileScreen />
        </Route>
        <Route path="/settings/feature-instructions" exact>
          <TopPostIntructionScreen />
        </Route>
        <Route path="/settings/terms-and-conditions" exact>
          <TermsAndConditionScreen />
        </Route>
        <Route path="/settings/privacy-policy" exact>
          <PrivacyPolicyScreen />
        </Route>
        <Route path="/settings/blog" exact>
          <BlogScreen />
        </Route>
        {/* <Route path="*">
          <Notfound />
        </Route> */}
      </Switch>
    </>
  );
}

/* Export
============================================================================= */
export default SettingsScreens;
