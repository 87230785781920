/* eslint-disable camelcase */
import React from 'react';
import { IoIosBoat, IoMdCar } from 'react-icons/io';
import { Tool, Truck } from 'react-feather';
import { MdOutlineTwoWheeler, MdHandyman } from 'react-icons/md';
import { FaTrailer, FaToolbox } from 'react-icons/fa';
import {
  boatMakes,
  carsMakes,
  heavy_dutyMakes,
  machineryMakes,
  motorcycleMakes,
  trailerMakes,
} from './vechile-makes';

export const features = ['Sunroof', 'Radio', 'Abs', 'Aluminum', 'Wheels'];

export const condition = ['New', 'Used'];

export const fuels = [
  'Diesel',
  'Gas',
  'Gasoline',
  'Electric',
  'Hybrid',
  'Others',
];

export const bodyStyle = [
  'Sedan',
  'Suvs',
  'Pickup',
  'Convertible',
  'Coupe',
  'Van',
  'Hatchback',
  'Minivan',
  'Other',
];

export const titles = [
  'Clean',
  'Rebuilt',
  'Salvage',
  'Lien',
  'Parts',
  'Flood',
  'Others',
];

export const postCategories = [
  {
    id: 'cars',
    title: 'Car & Truck',
    slugs: ['car', 'truck', 'van', 'sports', 'racing'],
    icon: <IoMdCar size={70} color="#F5F5F5" />,
  },
  {
    id: 'heavy_duty',
    title: 'Heavy Duty Trucks',
    slugs: [
      'trucks',
      'jeep',
    ],
    icon: <Truck size={60} color="#F5F5F5" />,
  },
  {
    id: 'motorcycle',
    title: 'Motorcycles',
    slugs: [
      'bike',
      'bikes',
      'dirt',
      'touring',
      'racing',
    ],
    icon: <MdOutlineTwoWheeler size={60} color="#F5F5F5" />,
  },
  {
    id: 'boat',
    title: 'Boats',
    slugs: [
      'boat',
      'boats',
      'yacht',
      'cruise',
      'jet ski',
      'sail boat',
    ],
    icon: <IoIosBoat size={60} color="#F5F5F5" />,
  },
  {
    id: 'trailer',
    title: 'Trailers',
    slugs: ['van', 'trailer'],
    icon: (
      <FaTrailer size={60} color="#F5F5F5" />
    ),
  },
  {
    id: 'machinery',
    title: 'Machinery',
    slugs: ['machinery'],
    icon: <Tool size={50} color="#F5F5F5" />,
  },
  {
    id: 'other',
    title: 'Others',
    slugs: [],
    icon: (
      <FaToolbox size={50} color="#F5F5F5" />
    ),
  },
  {
    id: 'part',
    title: 'Parts',
    slugs: [],
    icon: (
      <MdHandyman size={50} color="#F5F5F5" />
    ),
  },
];

export const postFormData = {
  cars: [
    {
      label: 'Title Vehicle',
      name: 'titleVehicle',
      type: 'text',
      placeholder: 'Example: 2012 ford mustang',
    },
    {
      label: 'Make',
      name: 'make',
      type: 'modal',
      isMultiSelect: false,
      data: carsMakes,
    },
    {
      label: 'Model',
      name: 'model',
      type: 'text',
      isMultiSelect: false,
      data: {
        acura: [
          {
            value: 'ilx',
            text: 'ILX',
          },
          {
            value: 'mdx',
            text: 'MDX',
          },
          {
            value: 'nsx',
            text: 'NSX',
          },
          {
            value: 'rdx',
            text: 'RDX',
          },
          {
            value: 'rsx',
            text: 'RSX',
          },
          {
            value: 'tl',
            text: 'TL',
          },
          {
            value: 'tlx',
            text: 'TLX',
          },
          {
            value: 'tsx',
            text: 'TSX',
          },
          {
            value: 'cl',
            text: 'CL',
          },
          {
            value: 'integra',
            text: 'INTEGRA',
          },
          {
            value: 'legend',
            text: 'LEGEND',
          },
          {
            value: 'rl',
            text: 'RL',
          },
          {
            value: 'rlx',
            text: 'RLX',
          },
          {
            value: 'slx',
            text: 'SLX',
          },
          {
            value: 'vigor',
            text: 'VIGOR',
          },
          {
            value: 'zdx',
            text: 'ZDX',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        chevrolet: [
          {
            value: 'astro',
            text: 'ASTRO',
          },
          {
            value: 'avalanche',
            text: 'AVALANCHE',
          },
          {
            value: 'aveo',
            text: 'AVEO',
          },
          {
            value: 'aveo5',
            text: 'AVEO5',
          },
          {
            value: 'beretta',
            text: 'BERETTA',
          },
          {
            value: 'blazer',
            text: 'BLAZER',
          },
          {
            value: 'bolt',
            text: 'BOLT',
          },
          {
            value: 'cKTruck',
            text: 'C/K TRUCK',
          },
          {
            value: 'camaro',
            text: 'CAMARO',
          },
          {
            value: 'caprice',
            text: 'CAPRICE',
          },
          {
            value: 'captivaSport',
            text: 'CAPTIVA SPORT',
          },
          {
            value: 'cavalier',
            text: 'CAVALIER',
          },
          {
            value: 'celebrity',
            text: 'CELEBRITY',
          },
          {
            value: 'chevette',
            text: 'CHEVETTE',
          },
          {
            value: 'citation',
            text: 'CITATION',
          },
          {
            value: 'cityExpress',
            text: 'CITY EXPRESS',
          },
          {
            value: 'cobalt',
            text: 'COBALT',
          },
          {
            value: 'colorado',
            text: 'COLORADO',
          },
          {
            value: 'corsica',
            text: 'CORSICA',
          },
          {
            value: 'corvette',
            text: 'CORVETTE',
          },
          {
            value: 'cruze',
            text: 'CRUZE',
          },
          {
            value: 'elCamino',
            text: 'EL CAMINO',
          },
          {
            value: 'equinox',
            text: 'EQUINOX',
          },
          {
            value: 'express1500',
            text: 'EXPRESS 1500',
          },
          {
            value: 'express2500',
            text: 'EXPRESS 2500',
          },
          {
            value: 'express3500',
            text: 'EXPRESS 3500',
          },
          {
            value: 'express4500',
            text: 'EXPRESS 4500',
          },
          {
            value: 'g10',
            text: 'G10',
          },
          {
            value: 'g20',
            text: 'G20',
          },
          {
            value: 'g30',
            text: 'G30',
          },
          {
            value: 'hhr',
            text: 'HHR',
          },
          {
            value: 'impala',
            text: 'IMPALA',
          },
          {
            value: 'kodiacC4500',
            text: 'KODIAC C4500',
          },
          {
            value: 'lumina',
            text: 'LUMINA',
          },
          {
            value: 'luminaApv',
            text: 'LUMINA APV',
          },
          {
            value: 'luv',
            text: 'LUV',
          },
          {
            value: 'malibu',
            text: 'MALIBU',
          },
          {
            value: 'metro',
            text: 'METRO',
          },
          {
            value: 'monteCarlo',
            text: 'MONTE CARLO',
          },
          {
            value: 'nova',
            text: 'NOVA',
          },
          {
            value: 'prizm',
            text: 'PRIZM',
          },
          {
            value: 's10Blazer',
            text: 'S10 BLAZER',
          },
          {
            value: 's10Pickup',
            text: 'S10 PICKUP',
          },
          {
            value: 'silverado1500',
            text: 'SILVERADO 1500',
          },
          {
            value: 'silverado2500',
            text: 'SILVERADO 2500',
          },
          {
            value: 'silverado3500',
            text: 'SILVERADO 3500',
          },
          {
            value: 'sonic',
            text: 'SONIC',
          },
          {
            value: 'spark',
            text: 'SPARK',
          },
          {
            value: 'spectrum',
            text: 'SPECTRUM',
          },
          {
            value: 'sprint',
            text: 'SPRINT',
          },
          {
            value: 'ss',
            text: 'SS',
          },
          {
            value: 'ssr',
            text: 'SSR',
          },
          {
            value: 'suburban',
            text: 'SUBURBAN',
          },
          {
            value: 'tahoe',
            text: 'TAHOE',
          },
          {
            value: 'tracker',
            text: 'TRACKER',
          },
          {
            value: 'trailblazer',
            text: 'TRAILBLAZER',
          },
          {
            value: 'trailblazerExt',
            text: 'TRAILBLAZER EXT',
          },
          {
            value: 'traverse',
            text: 'TRAVERSE',
          },
          {
            value: 'trax',
            text: 'TRAX',
          },
          {
            value: 'uplander',
            text: 'UPLANDER',
          },
          {
            value: 'venture',
            text: 'VENTURE',
          },
          {
            value: 'volt',
            text: 'VOLT',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        ford: [
          {
            value: 'aerostar',
            text: 'AEROSTAR',
          },
          {
            value: 'aspire',
            text: 'ASPIRE',
          },
          {
            value: 'bronco',
            text: 'BRONCO',
          },
          {
            value: 'broncoII',
            text: 'BRONCO II',
          },
          {
            value: 'cMax',
            text: 'C-MAX',
          },
          {
            value: 'contour',
            text: 'CONTOUR',
          },
          {
            value: 'courier',
            text: 'COURIER',
          },
          {
            value: 'crownVictoria',
            text: 'CROWN VICTORIA',
          },
          {
            value: 'e100',
            text: 'E-100',
          },
          {
            value: 'e150AndEconoline150',
            text: 'E-150 AND ECONOLINE 150',
          },
          {
            value: 'e250AndEconoline250',
            text: 'E-250 AND ECONOLINE 250',
          },
          {
            value: 'e350AndEconoline350',
            text: 'E-350 AND ECONOLINE 350',
          },
          {
            value: 'e450AndEconoline450',
            text: 'E-450 AND ECONOLINE 450',
          },
          {
            value: 'ecosport',
            text: 'ECOSPORT',
          },
          {
            value: 'edge',
            text: 'EDGE',
          },
          {
            value: 'escape',
            text: 'ESCAPE',
          },
          {
            value: 'escort',
            text: 'ESCORT',
          },
          {
            value: 'excursion',
            text: 'EXCURSION',
          },
          {
            value: 'exp',
            text: 'EXP',
          },
          {
            value: 'expedition',
            text: 'EXPEDITION',
          },
          {
            value: 'expeditionEl',
            text: 'EXPEDITION EL',
          },
          {
            value: 'expeditionMax',
            text: 'EXPEDITION MAX',
          },
          {
            value: 'explorer',
            text: 'EXPLORER',
          },
          {
            value: 'explrerSportTrac',
            text: 'EXPLRER SPORT TRAC',
          },
          {
            value: 'f100',
            text: 'F100',
          },
          {
            value: 'f150',
            text: 'F150',
          },
          {
            value: 'f250',
            text: 'F250',
          },
          {
            value: 'f350',
            text: 'F350',
          },
          {
            value: 'f450',
            text: 'F450',
          },
          {
            value: 'fairmont',
            text: 'FAIRMONT',
          },
          {
            value: 'festiva',
            text: 'FESTIVA',
          },
          {
            value: 'fiesta',
            text: 'FIESTA',
          },
          {
            value: 'fiveHundred',
            text: 'FIVE HUNDRED',
          },
          {
            value: 'flex',
            text: 'FLEX',
          },
          {
            value: 'focus',
            text: 'FOCUS',
          },
          {
            value: 'freestar',
            text: 'FREESTAR',
          },
          {
            value: 'freestyle',
            text: 'FREESTYLE',
          },
          {
            value: 'fusion',
            text: 'FUSION',
          },
          {
            value: 'granada',
            text: 'GRANADA',
          },
          {
            value: 'gt',
            text: 'GT',
          },
          {
            value: 'ltd',
            text: 'LTD',
          },
          {
            value: 'mustang',
            text: 'MUSTANG',
          },
          {
            value: 'probe',
            text: 'PROBE',
          },
          {
            value: 'ranger',
            text: 'RANGER',
          },
          {
            value: 'taurus',
            text: 'TAURUS',
          },
          {
            value: 'taurusX',
            text: 'TAURUS X',
          },
          {
            value: 'tempo',
            text: 'TEMPO',
          },
          {
            value: 'thunderbird',
            text: 'THUNDERBIRD',
          },
          {
            value: 'transit150',
            text: 'TRANSIT 150',
          },
          {
            value: 'transit250',
            text: 'TRANSIT 250',
          },
          {
            value: 'transit350',
            text: 'TRANSIT 350',
          },
          {
            value: 'transitConnect',
            text: 'TRANSIT CONNECT',
          },
          {
            value: 'windstar',
            text: 'WINDSTAR',
          },
          {
            value: 'zx2Escort',
            text: 'ZX2 ESCORT',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        freightliner: [
          {
            text: 'SPRINTER',
            value: 'sprinter',
          },
        ],
        gmc: [
          {
            value: 'ACADIA',
            text: 'ACADIA',
          },
          {
            value: 'CABALLERO',
            text: 'CABALLERO',
          },
          {
            value: 'CANYON',
            text: 'CANYON',
          },
          {
            value: 'ENVOY',
            text: 'ENVOY',
          },
          {
            value: 'ENVOY XL',
            text: 'ENVOY XL',
          },
          {
            value: 'ENVOY XUV',
            text: 'ENVOY XUV',
          },
          {
            value: 'G1500',
            text: 'G1500',
          },
          {
            value: 'G2500',
            text: 'G2500',
          },
          {
            value: 'G3500',
            text: 'G3500',
          },
          {
            value: 'JIMMY',
            text: 'JIMMY',
          },
          {
            value: 'S15 JIMMY',
            text: 'S15 JIMMY',
          },
          {
            value: 'S15 PICKUP',
            text: 'S15 PICKUP',
          },
          {
            value: 'SAFARI',
            text: 'SAFARI',
          },
          {
            value: 'SAVANA 1500',
            text: 'SAVANA 1500',
          },
          {
            value: 'SAVANA 2500',
            text: 'SAVANA 2500',
          },
          {
            value: 'SAVANA 3500',
            text: 'SAVANA 3500',
          },
          {
            value: 'SAVANA 4500',
            text: 'SAVANA 4500',
          },
          {
            value: 'SIERRA 1500',
            text: 'SIERRA 1500',
          },
          {
            value: 'SIERRA 2500',
            text: 'SIERRA 2500',
          },
          {
            value: 'SIERRA 3500',
            text: 'SIERRA 3500',
          },
          {
            value: 'SONOMA',
            text: 'SONOMA',
          },
          {
            value: 'SUBURBAN',
            text: 'SUBURBAN',
          },
          {
            value: 'SYCLONE',
            text: 'SYCLONE',
          },
          {
            value: 'TERRAIN',
            text: 'TERRAIN',
          },
          {
            value: 'TOPKICK C4500',
            text: 'TOPKICK C4500',
          },
          {
            value: 'TYPHOON',
            text: 'TYPHOON',
          },
          {
            value: 'YUKON',
            text: 'YUKON',
          },
          {
            value: 'YUKON XL',
            text: 'YUKON XL',
          },
          {
            value: 'OTHERS',
            text: 'OTHERS',
          },
        ],
        alfaRomeo: [
          {
            value: '164',
            text: '164',
          },
          {
            value: '4C',
            text: '4C',
          },
          {
            value: '4C SPIDER',
            text: '4C SPIDER',
          },
          {
            value: '8C SPIDER',
            text: '8C SPIDER',
          },
          {
            value: 'GIULIA',
            text: 'GIULIA',
          },
          {
            value: 'SPIDER',
            text: 'SPIDER',
          },
          {
            value: 'STELVIO',
            text: 'STELVIO',
          },
        ],
        amGeneral: [
          {
            text: 'HUMMBER',
            value: 'hummber',
          },
        ],
        astonMartin: [
          {
            value: 'DB AR1 ZAGATO',
            text: 'DB AR1 ZAGATO',
          },
          {
            value: 'DB11',
            text: 'DB11',
          },
          {
            value: 'DB7',
            text: 'DB7',
          },
          {
            value: 'DB7 VANTAGE',
            text: 'DB7 VANTAGE',
          },
          {
            value: 'DB9',
            text: 'DB9',
          },
          {
            value: 'DBS',
            text: 'DBS',
          },
          {
            value: 'DBX',
            text: 'DBX',
          },
          {
            value: 'RAPIDE',
            text: 'RAPIDE',
          },
          {
            value: 'RAPIDE S',
            text: 'RAPIDE S',
          },
          {
            value: 'V12 VANQUISH',
            text: 'V12 VANQUISH',
          },
          {
            value: 'V12 VANTAGE',
            text: 'V12 VANTAGE',
          },
          {
            value: 'V12 VANTAGE S',
            text: 'V12 VANTAGE S',
          },
          {
            value: 'V8 VANTAGE',
            text: 'V8 VANTAGE',
          },
          {
            value: 'V8 VANTAGE S',
            text: 'V8 VANTAGE S',
          },
          {
            value: 'VANQUISH',
            text: 'VANQUISH',
          },
          {
            value: 'VANTAGE',
            text: 'VANTAGE',
          },
          {
            value: 'VANTAGE GT',
            text: 'VANTAGE GT',
          },
          {
            value: 'VIRAGE',
            text: 'VIRAGE',
          },
        ],
        audi: [
          {
            value: 'A3',
            text: 'A3',
          },
          {
            value: 'A4',
            text: 'A4',
          },
          {
            value: 'A5',
            text: 'A5',
          },
          {
            value: 'A6',
            text: 'A6',
          },
          {
            value: 'A7',
            text: 'A7',
          },
          {
            value: 'A8',
            text: 'A8',
          },
          {
            value: 'ALLROAD',
            text: 'ALLROAD',
          },
          {
            value: 'CABRIOLET',
            text: 'CABRIOLET',
          },
          {
            value: 'COUPE',
            text: 'COUPE',
          },
          {
            value: 'E-TRON',
            text: 'E-TRON',
          },
          {
            value: 'Q3',
            text: 'Q3',
          },
          {
            value: 'Q5',
            text: 'Q5',
          },
          {
            value: 'Q7',
            text: 'Q7',
          },
          {
            value: 'QUATTRO',
            text: 'QUATTRO',
          },
          {
            value: 'R8',
            text: 'R8',
          },
          {
            value: 'RS 3',
            text: 'RS 3',
          },
          {
            value: 'RS 4',
            text: 'RS 4',
          },
          {
            value: 'RS 5',
            text: 'RS 5',
          },
          {
            value: 'RS 6',
            text: 'RS 6',
          },
          {
            value: 'RS 7',
            text: 'RS 7',
          },
          {
            value: 'RS Q8',
            text: 'RS Q8',
          },
          {
            value: 'S3',
            text: 'S3',
          },
          {
            value: 'S4',
            text: 'S4',
          },
          {
            value: 'S5',
            text: 'S5',
          },
          {
            value: 'S6',
            text: 'S6',
          },
          {
            value: 'S7',
            text: 'S7',
          },
          {
            value: 'S8',
            text: 'S8',
          },
          {
            value: 'SQ5',
            text: 'SQ5',
          },
          {
            value: 'SQ7',
            text: 'SQ7',
          },
          {
            value: 'SQ8',
            text: 'SQ8',
          },
          {
            value: 'TT',
            text: 'TT',
          },
          {
            value: 'TT RS',
            text: 'TT RS',
          },
          {
            value: 'TTS',
            text: 'TTS',
          },
          {
            value: 'V8 QUATTRO',
            text: 'V8 QUATTRO',
          },
          {
            value: '100',
            text: '100',
          },
          {
            value: '200',
            text: '200',
          },
          {
            value: '4000',
            text: '4000',
          },
          {
            value: '5000',
            text: '5000',
          },
          {
            value: '80',
            text: '80',
          },
          {
            value: '90',
            text: '90',
          },
          {
            value: 'OTHERS',
            text: 'OTHERS',
          },
        ],
        bentley: [
          {
            value: 'ARNAGE',
            text: 'ARNAGE',
          },
          {
            value: 'AZURE',
            text: 'AZURE',
          },
          {
            value: 'BENTAYGA',
            text: 'BENTAYGA',
          },
          {
            value: 'BENTAYGA HYBRID',
            text: 'BENTAYGA HYBRID',
          },
          {
            value: 'BROOKLANDS',
            text: 'BROOKLANDS',
          },
          {
            value: 'CONTINENTAL',
            text: 'CONTINENTAL',
          },
          {
            value: 'CONTINENTAL FLYING SPUR',
            text: 'CONTINENTAL FLYING SPUR',
          },
          {
            value: 'CONTINENTAL GT',
            text: 'CONTINENTAL GT',
          },
          {
            value: 'CONTINENTAL GTC',
            text: 'CONTINENTAL GTC',
          },
          {
            value: 'CONTINENTAL SUPERSPORTS',
            text: 'CONTINENTAL SUPERSPORTS',
          },
          {
            value: 'FLYING SPUR',
            text: 'FLYING SPUR',
          },
          {
            value: 'MULSANNE',
            text: 'MULSANNE',
          },
          {
            value: 'R-TYPE',
            text: 'R-TYPE',
          },
          {
            value: 'TURBO R',
            text: 'TURBO R',
          },
          {
            value: 'TURBO RL',
            text: 'TURBO RL',
          },
          {
            value: 'TURBO RT',
            text: 'TURBO RT',
          },
          {
            value: 'TURBO S',
            text: 'TURBO S',
          },
          {
            value: 'OTHERS',
            text: 'OTHERS',
          },
        ],
        bmw: [
          {
            value: '1 SERIES',
            text: '1 SERIES',
          },
          {
            value: '128i',
            text: '128i',
          },
          {
            value: '135i',
            text: '135i',
          },
          {
            value: '135is',
            text: '135is',
          },
          {
            value: '1 SERIES M',
            text: '1 SERIES M',
          },
          {
            value: '2 SERIES',
            text: '2 SERIES',
          },
          {
            value: '228i',
            text: '228i',
          },
          {
            value: '228i Drive',
            text: '228i Drive',
          },
          {
            value: '228i Drive Gran Coupe',
            text: '228i Drive Gran Coupe',
          },
          {
            value: '230i',
            text: '230i',
          },
          {
            value: '230i xDrive',
            text: '230i xDrive',
          },
          {
            value: 'M235i xDrive',
            text: 'M235i xDrive',
          },
          {
            value: 'M235i xDrive GRAN COUPE',
            text: 'M235i xDrive GRAN COUPE',
          },
          {
            value: 'M240i',
            text: 'M240i',
          },
          {
            value: 'M240i xDrive',
            text: 'M240i xDrive',
          },
          {
            value: '3 SERIES',
            text: '3 SERIES',
          },
          {
            value: '318i',
            text: '318i',
          },
          {
            value: '318iS',
            text: '318iS',
          },
          {
            value: '318ti',
            text: '318ti',
          },
          {
            value: '320i',
            text: '320i',
          },
          {
            value: '320i x Drive',
            text: '320i x Drive',
          },
          {
            value: '323ci',
            text: '323ci',
          },
          {
            value: '323i',
            text: '323i',
          },
          {
            value: '323is',
            text: '323is',
          },
          {
            value: '325',
            text: '325',
          },
          {
            value: '325Ci',
            text: '325Ci',
          },
          {
            value: '325e',
            text: '325e',
          },
          {
            value: '325es',
            text: '325es',
          },
          {
            value: '325i',
            text: '325i',
          },
          {
            value: '325is',
            text: '325is',
          },
          {
            value: '325iX',
            text: '325iX',
          },
          {
            value: '325xi',
            text: '325xi',
          },
          {
            value: '328Ci',
            text: '328Ci',
          },
          {
            value: '328d',
            text: '328d',
          },
          {
            value: '328dxDrive',
            text: '328dxDrive',
          },
          {
            value: '328i',
            text: '328i',
          },
          {
            value: '328 Gran Turismo xDrive',
            text: '328 Gran Turismo xDrive',
          },
          {
            value: '328 x Drive',
            text: '328 x Drive',
          },
          {
            value: '328iS',
            text: '328iS',
          },
          {
            value: '328xi',
            text: '328xi',
          },
          {
            value: '330Ci',
            text: '330Ci',
          },
          {
            value: '330e',
            text: '330e',
          },
          {
            value: '330e xDrive',
            text: '330e xDrive',
          },
          {
            value: '330i',
            text: '330i',
          },
          {
            value: '330i Gran Turismo xDrive',
            text: '330i Gran Turismo xDrive',
          },
          {
            value: '330i xDrive',
            text: '330i xDrive',
          },
          {
            value: '330xi',
            text: '330xi',
          },
          {
            value: '335d',
            text: '335d',
          },
          {
            value: '335i',
            text: '335i',
          },
          {
            value: '335i Gran Turismo xDrive',
            text: '335i Gran Turismo xDrive',
          },
          {
            value: '335 xDrive',
            text: '335 xDrive',
          },
          {
            value: '335is',
            text: '335is',
          },
          {
            value: '335xi',
            text: '335xi',
          },
          {
            value: '340i',
            text: '340i',
          },
          {
            value: '340i Gran Turismo xDrive',
            text: '340i Gran Turismo xDrive',
          },
          {
            value: '340i xDrive',
            text: '340i xDrive',
          },
          {
            value: 'ActiveHybrid3',
            text: 'ActiveHybrid3',
          },
          {
            value: 'M340i',
            text: 'M340i',
          },
          {
            value: 'M340i xDrive',
            text: 'M340i xDrive',
          },
          {
            value: '4 SERIES',
            text: '4 SERIES',
          },
          {
            value: '428i',
            text: '428i',
          },
          {
            value: '428i Gran Coupe',
            text: '428i Gran Coupe',
          },
          {
            value: '428i Gran Coupe xDrive',
            text: '428i Gran Coupe xDrive',
          },
          {
            value: '428i xDrive',
            text: '428i xDrive',
          },
          {
            value: '430i',
            text: '430i',
          },
          {
            value: '535d xDrive',
            text: '535d x Drive',
          },
          {
            value: '535i',
            text: '535i',
          },
          {
            value: '535i Gran Turismo',
            text: '535i Gran Turismo',
          },
          {
            value: '535i Gran Turismo xDrive',
            text: '535i Gran Turismo xDrive',
          },
          {
            value: '535 x Drive',
            text: '535 x Drive',
          },
          {
            value: '535xi',
            text: '535xi',
          },
          {
            value: '540d x Drive',
            text: '540d x Drive',
          },
          {
            value: '540i',
            text: '540i',
          },
          {
            value: '540i x Drive',
            text: '540i x Drive',
          },
          {
            value: '545i',
            text: '545i',
          },
          {
            value: '550i',
            text: '550i',
          },
          {
            value: '550i Gran Turismo',
            text: '550i Gran Turismo',
          },
          {
            value: '550i Gran Turismo xDrive',
            text: '550i Gran Turismo xDrive',
          },
          {
            value: '550i xDrive',
            text: '550i xDrive',
          },
          {
            value: 'ActiveHybrid5',
            text: 'ActiveHybrid5',
          },
          {
            value: 'M550i x Drive',
            text: 'M550i x Drive',
          },
          {
            value: '6 SERIES',
            text: '6 SERIES',
          },
          {
            value: '633CSi',
            text: '633CSi',
          },
          {
            value: '635CSi',
            text: '635CSi',
          },
          {
            value: '640i',
            text: '640i',
          },
          {
            value: '640i Gran Coupe',
            text: '640i Gran Coupe',
          },
          {
            value: '640I Gran Coupe xDrive',
            text: '640I Gran Coupe xDrive',
          },
          {
            value: '640i Gran Turismo xDrive',
            text: '640i Gran Turismo xDrive',
          },
          {
            value: '640i xDrive',
            text: '640i xDrive',
          },
          {
            value: '645Ci',
            text: '645Ci',
          },
          {
            value: '650i',
            text: '650i',
          },
          {
            value: '650i Gran Coupe',
            text: '650i Gran Coupe',
          },
          {
            value: '650i Gran Coupe xDrive',
            text: '650i Gran Coupe xDrive',
          },
          {
            value: '650i xDrive',
            text: '650i xDrive',
          },
          {
            value: 'ALPINA B6 xDrive Gran Coupe',
            text: 'ALPINA B6 xDrive Gran Coupe',
          },
          {
            value: 'L6',
            text: 'L6',
          },
          {
            value: '7 SERIES',
            text: '7 SERIES',
          },
          {
            value: '733i',
            text: '733i',
          },
          {
            value: '735i',
            text: '735i',
          },
          {
            value: '734iL',
            text: '734iL',
          },
          {
            value: '740e xDrive',
            text: '740e xDrive',
          },
          {
            value: '740i',
            text: '740i',
          },
          {
            value: '740i x Drive',
            text: '740i x Drive',
          },
          {
            value: '740iL',
            text: '740iL',
          },
          {
            value: '740Ld xDrive',
            text: '740Ld xDrive',
          },
          {
            value: '740Li',
            text: '740Li',
          },
          {
            value: '740Li xDrive',
            text: '740Li xDrive',
          },
          {
            value: '745e xDrive',
            text: '745e xDrive',
          },
          {
            value: '745i',
            text: '745i',
          },
          {
            value: '745Li',
            text: '745Li',
          },
          {
            value: '750i',
            text: '750i',
          },
          {
            value: '750i xDrive',
            text: '750i xDrive',
          },
          {
            value: '750iL',
            text: '750iL',
          },
          {
            value: '750Li',
            text: '750Li',
          },
          {
            value: '750Li xDrive',
            text: '750Li xDrive',
          },
          {
            value: '760i',
            text: '760i',
          },
          {
            value: '760Li',
            text: '760Li',
          },
          {
            value: 'ActiveHybrid 7',
            text: 'ActiveHybrid 7',
          },
          {
            value: 'ALPINA B7',
            text: 'ALPINA B7',
          },
          {
            value: 'ALPINA B7 xDrive',
            text: 'ALPINA B7 xDrive',
          },
          {
            value: 'L7',
            text: 'L7',
          },
          {
            value: 'M760i xDrive',
            text: 'M760i xDrive',
          },
          {
            value: '8 SERIES',
            text: '8 SERIES',
          },
          {
            value: '840Ci',
            text: '840Ci',
          },
          {
            value: '840i',
            text: '840i',
          },
          {
            value: '840i Gran Coupe',
            text: '840i Gran Coupe',
          },
          {
            value: '840i Gran Coupe xDrive',
            text: '840i Gran Coupe xDrive',
          },
          {
            value: '840i xDrive',
            text: '840i xDrive',
          },
          {
            value: '850Ci',
            text: '850Ci',
          },
          {
            value: '850CSi',
            text: '850CSi',
          },
          {
            value: '850i',
            text: '850i',
          },
          {
            value: 'M850i Gran Coupe xDrive',
            text: 'M850i Gran Coupe xDrive',
          },
          {
            value: 'M850i xDrive',
            text: 'M850i xDrive',
          },
          {
            value: 'ActiveHybrid X6',
            text: 'ActiveHybrid X6',
          },
          {
            value: 'i3',
            text: 'i3',
          },
          {
            value: 'i8',
            text: 'i8',
          },
          {
            value: 'M Coupe',
            text: 'M Coupe',
          },
          {
            value: 'M Roadster',
            text: 'M Roadster',
          },
          {
            value: 'M2',
            text: 'M2',
          },
          {
            value: 'M3',
            text: 'M3',
          },
          {
            value: 'M4',
            text: 'M4',
          },
          {
            value: 'M5',
            text: 'M5',
          },
          {
            value: 'M6',
            text: 'M6',
          },
          {
            value: 'M6 Gran Coupe',
            text: 'M6 Gran Coupe',
          },
          {
            value: 'M8',
            text: 'M8',
          },
          {
            value: 'M8 Gran Coupe xDrive',
            text: 'M8 Gran Coupe xDrive',
          },
          {
            value: 'X1',
            text: 'X1',
          },
          {
            value: 'X2',
            text: 'X2',
          },
          {
            value: 'X3',
            text: 'X3',
          },
          {
            value: 'X3 M',
            text: 'X3 M',
          },
          {
            value: 'X4',
            text: 'X4',
          },
          {
            value: 'X4 M',
            text: 'X4 M',
          },
          {
            value: 'X5',
            text: 'X5',
          },
          {
            value: 'X5 M',
            text: 'X5 M',
          },
          {
            value: 'X6',
            text: 'X6',
          },
          {
            value: 'X6 M',
            text: 'X6 M',
          },
          {
            value: 'X7',
            text: 'X7',
          },
          {
            value: 'Z3',
            text: 'Z3',
          },
          {
            value: 'Z4',
            text: 'Z4',
          },
          {
            value: 'Z8',
            text: 'Z8',
          },
        ],
        bugatti: [
          {
            value: 'chiron',
            text: 'CHIRON',
          },
          {
            value: 'veyron',
            text: 'VEYRON',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        buick: [
          {
            value: 'cascada',
            text: 'CASCADA',
          },
          {
            value: 'century',
            text: 'CENTURY',
          },
          {
            value: 'electra',
            text: 'ELECTRA',
          },
          {
            value: 'enclave',
            text: 'ENCLAVE',
          },
          {
            value: 'encore',
            text: 'ENCORE',
          },
          {
            value: 'encoreGx',
            text: 'ENCORE GX',
          },
          {
            value: 'envision',
            text: 'ENVISION',
          },
          {
            value: 'lacrosse',
            text: 'LACROSSE',
          },
          {
            value: 'leSabre',
            text: 'LE SABRE',
          },
          {
            value: 'lucerne',
            text: 'LUCERNE',
          },
          {
            value: 'parkAvenue',
            text: 'PARK AVENUE',
          },
          {
            value: 'rainier',
            text: 'RAINIER',
          },
          {
            value: 'reatta',
            text: 'REATTA',
          },
          {
            value: 'regal',
            text: 'REGAL',
          },
          {
            value: 'rendezvous',
            text: 'RENDEZVOUS',
          },
          {
            value: 'riviera',
            text: 'RIVIERA',
          },
          {
            value: 'roadmaster',
            text: 'ROADMASTER',
          },
          {
            value: 'skyhawk',
            text: 'SKYHAWK',
          },
          {
            value: 'skylark',
            text: 'SKYLARK',
          },
          {
            value: 'somerset',
            text: 'SOMERSET',
          },
          {
            value: 'terraza',
            text: 'TERRAZA',
          },
          {
            value: 'verano',
            text: 'VERANO',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        cadillac: [
          {
            value: 'allante',
            text: 'ALLANTE',
          },
          {
            value: 'ats',
            text: 'ATS',
          },
          {
            value: 'brougham',
            text: 'BROUGHAM',
          },
          {
            value: 'catera',
            text: 'CATERA',
          },
          {
            value: 'cimarron',
            text: 'CIMARRON',
          },
          {
            value: 'ct4',
            text: 'CT4',
          },
          {
            value: 'ct5',
            text: 'CT5',
          },
          {
            value: 'ct6',
            text: 'CT6',
          },
          {
            value: 'cts',
            text: 'CTS',
          },
          {
            value: 'deVille',
            text: 'DE VILLE',
          },
          {
            value: 'dts',
            text: 'DTS',
          },
          {
            value: 'eldorado',
            text: 'ELDORADO',
          },
          {
            value: 'elr',
            text: 'ELR',
          },
          {
            value: 'escalade',
            text: 'ESCALADE',
          },
          {
            value: 'escaladeEsv',
            text: 'ESCALADE ESV',
          },
          {
            value: 'escaladeExt',
            text: 'ESCALADE EXT',
          },
          {
            value: 'fleetwood',
            text: 'FLEETWOOD',
          },
          {
            value: 'seville',
            text: 'SEVILLE',
          },
          {
            value: 'srx',
            text: 'SRX',
          },
          {
            value: 'sts',
            text: 'STS',
          },
          {
            value: 'xlr',
            text: 'XLR',
          },
          {
            value: 'xt4',
            text: 'XT4',
          },
          {
            value: 'xt5',
            text: 'XT5',
          },
          {
            value: 'xt6',
            text: 'XT6',
          },
          {
            value: 'xts',
            text: 'XTS',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        chrysler: [
          {
            value: '200',
            text: '200',
          },
          {
            value: '300',
            text: '300',
          },
          {
            value: '300M',
            text: '300M',
          },
          {
            value: 'aspen',
            text: 'ASPEN',
          },
          {
            value: 'cirrus',
            text: 'CIRRUS',
          },
          {
            value: 'concorde',
            text: 'CONCORDE',
          },
          {
            value: 'conquest',
            text: 'CONQUEST',
          },
          {
            value: 'cordoba',
            text: 'CORDOBA',
          },
          {
            value: 'crossfire',
            text: 'CROSSFIRE',
          },
          {
            value: 'eClass',
            text: 'E CLASS',
          },
          {
            value: 'fifthAvenue',
            text: 'FIFTH AVENUE',
          },
          {
            value: 'grandVoyager',
            text: 'GRAND VOYAGER',
          },
          {
            value: 'imperial',
            text: 'IMPERIAL',
          },
          {
            value: 'intrepid',
            text: 'INTREPID',
          },
          {
            value: 'laser',
            text: 'LASER',
          },
          {
            value: 'lebaron',
            text: 'LEBARON',
          },
          {
            value: 'lebaronGTS',
            text: 'LEBARON GTS',
          },
          {
            value: 'lhs',
            text: 'LHS',
          },
          {
            value: 'newYorker',
            text: 'NEW YORKER',
          },
          {
            value: 'newport',
            text: 'NEWPORT',
          },
          {
            value: 'pacifica',
            text: 'PACIFICA',
          },
          {
            value: 'prowler',
            text: 'PROWLER',
          },
          {
            value: 'ptCruiser',
            text: 'PT CRUISER',
          },
          {
            value: 'sebring',
            text: 'SEBRING',
          },
          {
            value: 'tcByMaserati',
            text: 'TC BY MASERATI',
          },
          {
            value: 'townCountry',
            text: 'TOWN & COUNTRY',
          },
          {
            value: 'voyager',
            text: 'VOYAGER',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        dodge: [
          {
            value: '400',
            text: '400',
          },
          {
            value: '600',
            text: '600',
          },
          {
            value: 'aries',
            text: 'ARIES',
          },
          {
            value: 'avenger',
            text: 'AVENGER',
          },
          {
            value: 'b150',
            text: 'B150',
          },
          {
            value: 'b1500',
            text: 'B1500',
          },
          {
            value: 'b250',
            text: 'B250',
          },
          {
            value: 'b2500',
            text: 'B2500',
          },
          {
            value: 'b350',
            text: 'B350',
          },
          {
            value: 'b3500',
            text: 'B3500',
          },
          {
            value: 'caliber',
            text: 'CALIBER',
          },
          {
            value: 'caravan',
            text: 'CARAVAN',
          },
          {
            value: 'challenger',
            text: 'CHALLENGER',
          },
          {
            value: 'charger',
            text: 'CHARGER',
          },
          {
            value: 'colt',
            text: 'COLT',
          },
          {
            value: 'conquest',
            text: 'CONQUEST',
          },
          {
            value: 'dWTruck',
            text: 'D/W TRUCK',
          },
          {
            value: 'dakota',
            text: 'DAKOTA',
          },
          {
            value: 'dart',
            text: 'DART',
          },
          {
            value: 'daytona',
            text: 'DAYTONA',
          },
          {
            value: 'diplomat',
            text: 'DIPLOMAT',
          },
          {
            value: 'durango',
            text: 'DURANGO',
          },
          {
            value: 'dynasty',
            text: 'DYNASTY',
          },
          {
            value: 'grandCaravan',
            text: 'GRAND CARAVAN',
          },
          {
            value: 'intrepid',
            text: 'INTREPID',
          },
          {
            value: 'journey',
            text: 'JOURNEY',
          },
          {
            value: 'lancer',
            text: 'LANCER',
          },
          {
            value: 'magnum',
            text: 'MAGNUM',
          },
          {
            value: 'mirada',
            text: 'MIRADA',
          },
          {
            value: 'monaco',
            text: 'MONACO',
          },
          {
            value: 'neon',
            text: 'NEON',
          },
          {
            value: 'nitro',
            text: 'NITRO',
          },
          {
            value: 'omni',
            text: 'OMNI',
          },
          {
            value: 'raider',
            text: 'RAIDER',
          },
          {
            value: 'ram1500Truck',
            text: 'RAM 1500 TRUCK',
          },
          {
            value: 'ram2500Truck',
            text: 'RAM 2500 TRUCK',
          },
          {
            value: 'ram3500Truck',
            text: 'RAM 3500 TRUCK',
          },
          {
            value: 'ram4500Truck',
            text: 'RAM 4500 TRUCK',
          },
          {
            value: 'ram50Truck',
            text: 'RAM 50 TRUCK',
          },
          {
            value: 'ram5500Truck',
            text: 'RAM 5500 TRUCK',
          },
          {
            value: 'ramSRT10',
            text: 'RAM SRT-10',
          },
          {
            value: 'ramcharger',
            text: 'RAMCHARGER',
          },
          {
            value: 'rampage',
            text: 'RAMPAGE',
          },
          {
            value: 'shadow',
            text: 'SHADOW',
          },
          {
            value: 'spirit',
            text: 'SPIRIT',
          },
          {
            value: 'sprinter',
            text: 'SPRINTER',
          },
          {
            value: 'srt4',
            text: 'SRT-4',
          },
          {
            value: 'stRegis',
            text: 'ST. REGIS',
          },
          {
            value: 'stealth',
            text: 'STEALTH',
          },
          {
            value: 'straus',
            text: 'STRAUS',
          },
          {
            value: 'viper',
            text: 'VIPER',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        ferrari: [
          {
            value: '308',
            text: '308',
          },
          {
            value: '328',
            text: '328',
          },
          {
            value: '348',
            text: '348',
          },
          {
            value: '360',
            text: '360',
          },
          {
            value: '456GT',
            text: '456 GT',
          },
          {
            value: '456MGT',
            text: '456M GT',
          },
          {
            value: '458Italia',
            text: '458 ITALIA',
          },
          {
            value: '488GTB',
            text: '488 GTB',
          },
          {
            value: '488Pista',
            text: '488 PISTA',
          },
          {
            value: '488Spider',
            text: '488 SPIDER',
          },
          {
            value: '512M',
            text: '512M',
          },
          {
            value: '512TR',
            text: '512TR',
          },
          {
            value: '550Maranello',
            text: '550 MARANELLO',
          },
          {
            value: '599GTBFiorano',
            text: '599 GTB FIORANO',
          },
          {
            value: '599GTO',
            text: '599 GTO',
          },
          {
            value: '599SAAperta',
            text: '599 SA APERTA',
          },
          {
            value: '612Scaglietti',
            text: '612 SCAGLIETTI',
          },
          {
            value: '812Superfast',
            text: '812 SUPERFAST',
          },
          {
            value: 'california',
            text: 'CALIFORNIA',
          },
          {
            value: 'enzo',
            text: 'ENZO',
          },
          {
            value: 'f12Berlinetta',
            text: 'F12 BERLINETTA',
          },
          {
            value: 'f12Tdf',
            text: 'F12 TDF',
          },
          {
            value: 'f355',
            text: 'F355',
          },
          {
            value: 'f40',
            text: 'F40',
          },
          {
            value: 'f430',
            text: 'F430',
          },
          {
            value: 'f50',
            text: 'F50',
          },
          {
            value: 'f8Tributo',
            text: 'F8 TRIBUTO',
          },
          {
            value: 'ff',
            text: 'FF',
          },
          {
            value: 'gtc4Lusso',
            text: 'GTC4LUSSO',
          },
          {
            value: 'laferrari',
            text: 'LAFERRARI',
          },
          {
            value: 'mondial',
            text: 'MONDIAL',
          },
          {
            value: 'portofino',
            text: 'PORTOFINO',
          },
          {
            value: 'testarossa',
            text: 'TESTAROSSA',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        fiat: [
          {
            value: '124Spider',
            text: '124 SPIDER',
          },
          {
            value: '2000Spider',
            text: '2000 SPIDER',
          },
          {
            value: '500',
            text: '500',
          },
          {
            value: '500L',
            text: '500L',
          },
          {
            value: '500X',
            text: '500X',
          },
          {
            value: 'BertoneX19',
            text: 'BERTONE X1/9',
          },
          {
            value: 'Brava',
            text: 'BRAVA',
          },
          {
            value: 'PininfarinaSpider',
            text: 'PININFARINA SPIDER',
          },
          {
            value: 'Strada',
            text: 'STRADA',
          },
          {
            value: 'X19',
            text: 'X1/9',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        fisker: [
          {
            text: 'KARMA',
            value: 'karma',
          },
        ],
        genesis: [
          {
            value: 'G70',
            text: 'G70',
          },
          {
            value: 'G80',
            text: 'G80',
          },
          {
            value: 'G90',
            text: 'G90',
          },
          {
            value: 'GV80',
            text: 'GV80',
          },
        ],
        yugo: [
          {
            value: 'GV',
            text: 'GV',
          },
          {
            value: 'GVL',
            text: 'GVL',
          },
          {
            value: 'GVX',
            text: 'GVX',
          },
          // Add any other models here as needed
        ],
        smart: [
          {
            value: 'fortwo',
            text: 'FORTWO',
          },
        ],
        honda: [
          {
            value: 'ACCORD',
            text: 'ACCORD',
          },
          {
            value: 'ACCORD CROSSTOUR',
            text: 'ACCORD CROSSTOUR',
          },
          {
            value: 'CIVIC',
            text: 'CIVIC',
          },
          {
            value: 'CLARITY',
            text: 'CLARITY',
          },
          {
            value: 'CR-V',
            text: 'CR-V',
          },
          {
            value: 'CR-Z',
            text: 'CR-Z',
          },
          {
            value: 'CROSSTOUR',
            text: 'CROSSTOUR',
          },
          {
            value: 'CRX',
            text: 'CRX',
          },
          {
            value: 'DEL SOL',
            text: 'DEL SOL',
          },
          {
            value: 'ELEMENT',
            text: 'ELEMENT',
          },
          {
            value: 'FIT',
            text: 'FIT',
          },
          {
            value: 'HR-V',
            text: 'HR-V',
          },
          {
            value: 'INSIGHT',
            text: 'INSIGHT',
          },
          {
            value: 'ODYSSEY',
            text: 'ODYSSEY',
          },
          {
            value: 'PASSPORT',
            text: 'PASSPORT',
          },
          {
            value: 'PILOT',
            text: 'PILOT',
          },
          {
            value: 'PRELUDE',
            text: 'PRELUDE',
          },
          {
            value: 'RIDGELINE',
            text: 'RIDGELINE',
          },
          {
            value: 'S2000',
            text: 'S2000',
          },
          {
            value: 'OTHERS',
            text: 'OTHERS',
          },
        ],
        hummer: [
          {
            value: 'h1',
            text: 'H1',
          },
          {
            value: 'h2',
            text: 'H2',
          },
          {
            value: 'h3',
            text: 'H3',
          },
          {
            value: 'h3t',
            text: 'H3T',
          },
        ],
        hyundai: [
          {
            value: 'accent',
            text: 'Accent',
          },
          {
            value: 'azera',
            text: 'Azera',
          },
          {
            value: 'elantra',
            text: 'Elantra',
          },
          {
            value: 'elantraCoupe',
            text: 'Elantra Coupe',
          },
          {
            value: 'elantraTouring',
            text: 'Elantra Touring',
          },
          {
            value: 'entourage',
            text: 'Entourage',
          },
          {
            value: 'equus',
            text: 'Equus',
          },
          {
            value: 'excel',
            text: 'Excel',
          },
          {
            value: 'genesis',
            text: 'Genesis',
          },
          {
            value: 'genesisCoupe',
            text: 'Genesis Coupe',
          },
          {
            value: 'ioniq',
            text: 'Ioniq',
          },
          {
            value: 'kona',
            text: 'Kona',
          },
          {
            value: 'nexo',
            text: 'Nexo',
          },
          {
            value: 'palisade',
            text: 'Palisade',
          },
          {
            value: 'santaFe',
            text: 'Santa Fe',
          },
          {
            value: 'scoupe',
            text: 'Scoupe',
          },
          {
            value: 'sonata',
            text: 'Sonata',
          },
          {
            value: 'tiburon',
            text: 'Tiburon',
          },
          {
            value: 'tucson',
            text: 'Tucson',
          },
          {
            value: 'veloster',
            text: 'Veloster',
          },
          {
            value: 'venue',
            text: 'Venue',
          },
          {
            value: 'veracruz',
            text: 'Veracruz',
          },
          {
            value: 'xg300',
            text: 'XG300',
          },
          {
            value: 'xg350',
            text: 'XG350',
          },
          {
            value: 'others',
            text: 'Others',
          },
        ],
        infiniti: [
          {
            value: 'ex35',
            text: 'EX35',
          },
          {
            value: 'ex37',
            text: 'EX37',
          },
          {
            value: 'fx35',
            text: 'FX35',
          },
          {
            value: 'fx37',
            text: 'FX37',
          },
          {
            value: 'fx45',
            text: 'FX45',
          },
          {
            value: 'fx50',
            text: 'FX50',
          },
          {
            value: 'g20',
            text: 'G20',
          },
          {
            value: 'g25',
            text: 'G25',
          },
          {
            value: 'g35',
            text: 'G35',
          },
          {
            value: 'g37',
            text: 'G37',
          },
          {
            value: 'i30',
            text: 'I30',
          },
          {
            value: 'i35',
            text: 'I35',
          },
          {
            value: 'jx35',
            text: 'JX35',
          },
          {
            value: 'm30',
            text: 'M30',
          },
          {
            value: 'm35',
            text: 'M35',
          },
          {
            value: 'm35h',
            text: 'M35h',
          },
          {
            value: 'm37',
            text: 'M37',
          },
          {
            value: 'm45',
            text: 'M45',
          },
          {
            value: 'm56',
            text: 'M56',
          },
          {
            value: 'q40',
            text: 'Q40',
          },
          {
            value: 'q45',
            text: 'Q45',
          },
          {
            value: 'q50',
            text: 'Q50',
          },
          {
            value: 'q60',
            text: 'Q60',
          },
          {
            value: 'q70',
            text: 'Q70',
          },
          {
            value: 'qx30',
            text: 'QX30',
          },
          {
            value: 'qx4',
            text: 'QX4',
          },
          {
            value: 'qx50',
            text: 'QX50',
          },
          {
            value: 'qx56',
            text: 'QX56',
          },
          {
            value: 'qx60',
            text: 'QX60',
          },
          {
            value: 'qx70',
            text: 'QX70',
          },
          {
            value: 'qx80',
            text: 'QX80',
          },
          {
            value: 'others',
            text: 'Others',
          },
        ],
        isuzu: [
          {
            value: 'amigo',
            text: 'Amigo',
          },
          {
            value: 'ascender',
            text: 'Ascender',
          },
          {
            value: 'axiom',
            text: 'Axiom',
          },
          {
            value: 'hombre',
            text: 'Hombre',
          },
          {
            value: 'i-280',
            text: 'i-280',
          },
          {
            value: 'i-290',
            text: 'i-290',
          },
          {
            value: 'i-350',
            text: 'i-350',
          },
          {
            value: 'i-370',
            text: 'i-370',
          },
          {
            value: 'i-mark',
            text: 'I-Mark',
          },
          {
            value: 'impulse',
            text: 'Impulse',
          },
          {
            value: 'oasis',
            text: 'Oasis',
          },
          {
            value: 'pickup',
            text: 'Pickup',
          },
          {
            value: 'rodeo',
            text: 'Rodeo',
          },
          {
            value: 'stylus',
            text: 'Stylus',
          },
          {
            value: 'trooper',
            text: 'Trooper',
          },
          {
            value: 'trooper-ii',
            text: 'Trooper II',
          },
          {
            value: 'vehicross',
            text: 'Vehicross',
          },
        ],
        jaguar: [
          {
            value: 'e-pace',
            text: 'E-Pace',
          },
          {
            value: 'f-pace',
            text: 'F-Pace',
          },
          {
            value: 'f-type',
            text: 'F-Type',
          },
          {
            value: 'i-pace',
            text: 'I-Pace',
          },
          {
            value: 's-type',
            text: 'S-Type',
          },
          {
            value: 'x-type',
            text: 'X-Type',
          },
          {
            value: 'xe',
            text: 'XE',
          },
          {
            value: 'xf',
            text: 'XF',
          },
          {
            value: 'xj',
            text: 'XJ',
          },
          {
            value: 'xj-sport',
            text: 'XJ Sport',
          },
          {
            value: 'xj-vanden-plas',
            text: 'XJ Vanden Plas',
          },
          {
            value: 'xj12',
            text: 'XJ12',
          },
          {
            value: 'xj6',
            text: 'XJ6',
          },
          {
            value: 'xj8',
            text: 'XJ8',
          },
          {
            value: 'xj8l',
            text: 'XJ8L',
          },
          {
            value: 'xjr',
            text: 'XJR',
          },
          {
            value: 'xjr-s',
            text: 'XJR-S',
          },
          {
            value: 'xjs',
            text: 'XJS',
          },
          {
            value: 'xk',
            text: 'XK',
          },
          {
            value: 'xk8',
            text: 'XK8',
          },
          {
            value: 'xkr',
            text: 'XKR',
          },
        ],
        jeep: [
          {
            value: 'cherokee',
            text: 'Cherokee',
          },
          {
            value: 'cj',
            text: 'CJ',
          },
          {
            value: 'comanche',
            text: 'Comanche',
          },
          {
            value: 'commander',
            text: 'Commander',
          },
          {
            value: 'compass',
            text: 'Compass',
          },
          {
            value: 'gladiator',
            text: 'Gladiator',
          },
          {
            value: 'grand-cherokee',
            text: 'Grand Cherokee',
          },
          {
            value: 'grand-wagoneer',
            text: 'Grand Wagoneer',
          },
          {
            value: 'liberty',
            text: 'Liberty',
          },
          {
            value: 'patriot',
            text: 'Patriot',
          },
          {
            value: 'pickup',
            text: 'Pickup',
          },
          {
            value: 'renegade',
            text: 'Renegade',
          },
          {
            value: 'scrambler',
            text: 'Scrambler',
          },
          {
            value: 'wagoneer',
            text: 'Wagoneer',
          },
          {
            value: 'wrangler',
            text: 'Wrangler',
          },
        ],
        karma: [
          {
            text: 'REVERO',
            value: 'revero',
          },
        ],
        kia: [
          {
            value: 'amanti',
            text: 'Amanti',
          },
          {
            value: 'borrego',
            text: 'Borrego',
          },
          {
            value: 'cadenza',
            text: 'Cadenza',
          },
          {
            value: 'forte',
            text: 'Forte',
          },
          {
            value: 'forte-koup',
            text: 'Forte Koup',
          },
          {
            value: 'k5',
            text: 'K5',
          },
          {
            value: 'k900',
            text: 'K900',
          },
          {
            value: 'niro',
            text: 'Niro',
          },
          {
            value: 'optima',
            text: 'Optima',
          },
          {
            value: 'rio',
            text: 'Rio',
          },
          {
            value: 'rio5',
            text: 'Rio5',
          },
          {
            value: 'rondo',
            text: 'Rondo',
          },
          {
            value: 'sedona',
            text: 'Sedona',
          },
          {
            value: 'seltos',
            text: 'Seltos',
          },
          {
            value: 'sephia',
            text: 'Sephia',
          },
          {
            value: 'sorento',
            text: 'Sorento',
          },
          {
            value: 'soul',
            text: 'Soul',
          },
          {
            value: 'spectra',
            text: 'Spectra',
          },
          {
            value: 'spectra5',
            text: 'Spectra5',
          },
          {
            value: 'sportage',
            text: 'Sportage',
          },
          {
            value: 'stinger',
            text: 'Stinger',
          },
          {
            value: 'telluride',
            text: 'Telluride',
          },
          {
            value: 'others',
            text: 'Others',
          },
        ],
        landRover: [
          {
            value: 'defender',
            text: 'Defender',
          },
          {
            value: 'discovery',
            text: 'Discovery',
          },
          {
            value: 'discovery-sport',
            text: 'Discovery Sport',
          },
          {
            value: 'freelander',
            text: 'Freelander',
          },
          {
            value: 'lr2',
            text: 'LR2',
          },
          {
            value: 'lr3',
            text: 'LR3',
          },
          {
            value: 'lr4',
            text: 'LR4',
          },
          {
            value: 'range-rover',
            text: 'Range Rover',
          },
          {
            value: 'range-rover-evoque',
            text: 'Range Rover Evoque',
          },
          {
            value: 'range-rover-sport',
            text: 'Range Rover Sport',
          },
          {
            value: 'range-rover-velar',
            text: 'Range Rover Velar',
          },
          {
            value: 'others',
            text: 'Others',
          },
        ],
        lamborghini: [
          {
            value: 'aventador',
            text: 'Aventador',
          },
          {
            value: 'aventador-s',
            text: 'Aventador S',
          },
          {
            value: 'aventador-svj',
            text: 'Aventador SVJ',
          },
          {
            value: 'diablo',
            text: 'Diablo',
          },
          {
            value: 'gallardo',
            text: 'Gallardo',
          },
          {
            value: 'huracan',
            text: 'Huracan',
          },
          {
            value: 'huracan-evo',
            text: 'Huracan Evo',
          },
          {
            value: 'murcielago',
            text: 'Murcielago',
          },
          {
            value: 'urus',
            text: 'Urus',
          },
        ],
        lexus: [
          {
            value: 'ct-200h',
            text: 'CT 200h',
          },
          {
            value: 'es-250',
            text: 'ES 250',
          },
          {
            value: 'es-300',
            text: 'ES 300',
          },
          {
            value: 'es-300h',
            text: 'ES 300h',
          },
          {
            value: 'es-330',
            text: 'ES 330',
          },
          {
            value: 'es-350',
            text: 'ES 350',
          },
          {
            value: 'gs-200t',
            text: 'GS 200t',
          },
          {
            value: 'gs-300',
            text: 'GS 300',
          },
          {
            value: 'gs-350',
            text: 'GS 350',
          },
          {
            value: 'gs-400',
            text: 'GS 400',
          },
          {
            value: 'gs-430',
            text: 'GS 430',
          },
          {
            value: 'gs450h',
            text: 'GS450h',
          },
          {
            value: 'gs-460',
            text: 'GS 460',
          },
          {
            value: 'gs-f',
            text: 'GS F',
          },
          {
            value: 'gx-460',
            text: 'GX 460',
          },
          {
            value: 'gx-470',
            text: 'GX 470',
          },
          {
            value: 'hs-250h',
            text: 'HS 250h',
          },
          {
            value: 'is-200t',
            text: 'IS 200t',
          },
          {
            value: 'is-250',
            text: 'IS 250',
          },
          {
            value: 'is-250c',
            text: 'IS 250C',
          },
          {
            value: 'is-300',
            text: 'IS 300',
          },
          {
            value: 'is-350',
            text: 'IS 350',
          },
          {
            value: 'is-350c',
            text: 'IS 350C',
          },
          {
            value: 'is-f',
            text: 'IS F',
          },
          {
            value: 'lc-500',
            text: 'LC 500',
          },
          {
            value: 'lc-500h',
            text: 'LC 500h',
          },
          {
            value: 'lfa',
            text: 'LFA',
          },
          {
            value: 'ls-400',
            text: 'LS 400',
          },
          {
            value: 'ls-430',
            text: 'LS 430',
          },
          {
            value: 'ls-460',
            text: 'LS 460',
          },
          {
            value: 'ls-500',
            text: 'LS 500',
          },
          {
            value: 'ls-500h',
            text: 'LS 500h',
          },
          {
            value: 'ls-600h',
            text: 'LS 600h',
          },
          {
            value: 'lx-450',
            text: 'LX 450',
          },
          {
            value: 'lx-470',
            text: 'LX 470',
          },
          {
            value: 'lx-570',
            text: 'LX 570',
          },
          {
            value: 'nx-200t',
            text: 'NX 200t',
          },
          {
            value: 'nx-300',
            text: 'NX 300',
          },
          {
            value: 'nx-300h',
            text: 'NX 300h',
          },
          {
            value: 'rc-200t',
            text: 'RC 200t',
          },
          {
            value: 'rc-300',
            text: 'RC 300',
          },
          {
            value: 'rc-350',
            text: 'RC 350',
          },
          {
            value: 'rc-f',
            text: 'RC F',
          },
          {
            value: 'rx-300',
            text: 'RX 300',
          },
          {
            value: 'rx-330',
            text: 'RX 330',
          },
          {
            value: 'rx-350',
            text: 'RX 350',
          },
          {
            value: 'rx-350l',
            text: 'RX 350L',
          },
          {
            value: 'rx-400h',
            text: 'RX 400h',
          },
          {
            value: 'rx-450h',
            text: 'RX 450h',
          },
          {
            value: 'rx-450hl',
            text: 'RX 450hL',
          },
          {
            value: 'sc-300',
            text: 'SC 300',
          },
          {
            value: 'sc-400',
            text: 'SC 400',
          },
          {
            value: 'sc-430',
            text: 'SC 430',
          },
          {
            value: 'ux-200',
            text: 'UX 200',
          },
          {
            value: 'ux-250h',
            text: 'UX 250h',
          },
        ],
        lincoln: [
          {
            value: 'aviator',
            text: 'Aviator',
          },
          {
            value: 'blackwood',
            text: 'Blackwood',
          },
          {
            value: 'continental',
            text: 'Continental',
          },
          {
            value: 'corsair',
            text: 'Corsair',
          },
          {
            value: 'ls',
            text: 'LS',
          },
          {
            value: 'mark-lt',
            text: 'Mark LT',
          },
          {
            value: 'mark-vii',
            text: 'Mark VII',
          },
          {
            value: 'mark-viii',
            text: 'Mark VIII',
          },
          {
            value: 'mkc',
            text: 'MKC',
          },
          {
            value: 'mks',
            text: 'MKS',
          },
          {
            value: 'mkt',
            text: 'MKT',
          },
          {
            value: 'mkx',
            text: 'MKX',
          },
          {
            value: 'mkz',
            text: 'MKZ',
          },
          {
            value: 'mkz-hybrid',
            text: 'MKZ Hybrid',
          },
          {
            value: 'nautilus',
            text: 'Nautilus',
          },
          {
            value: 'navigator',
            text: 'Navigator',
          },
          {
            value: 'navigator-l',
            text: 'Navigator L',
          },
          {
            value: 'town-car',
            text: 'Town Car',
          },
          {
            value: 'zephyr',
            text: 'Zephyr',
          },
        ],
        lotus: [
          {
            value: 'elan',
            text: 'Elan',
          },
          {
            value: 'elise',
            text: 'Elise',
          },
          {
            value: 'esprit',
            text: 'Esprit',
          },
          {
            value: 'esprit-v8',
            text: 'Esprit V8',
          },
          {
            value: 'evora',
            text: 'Evora',
          },
          {
            value: 'evora-400',
            text: 'Evora 400',
          },
          {
            value: 'evora-gt',
            text: 'Evora GT',
          },
          {
            value: 'exige',
            text: 'Exige',
          },
          {
            value: 'exige-s',
            text: 'Exige S',
          },
        ],
        maserati: [
          {
            value: 'coupe',
            text: 'Coupe',
          },
          {
            value: 'ghibli',
            text: 'Ghibli',
          },
          {
            value: 'granSport',
            text: 'GranSport',
          },
          {
            value: 'granSportSpyder',
            text: 'GranSport Spyder',
          },
          {
            value: 'granTurismo',
            text: 'Gran Turismo',
          },
          {
            value: 'levante',
            text: 'Levante',
          },
          {
            value: 'quattroporte',
            text: 'Quattroporte',
          },
          {
            value: 'spyder',
            text: 'Spyder',
          },
          {
            value: 'others',
            text: 'Others',
          },
        ],
        mazda: [
          {
            value: '323',
            text: '323',
          },
          {
            value: '626',
            text: '626',
          },
          {
            value: '929',
            text: '929',
          },
          {
            value: 'b2200',
            text: 'B2200',
          },
          {
            value: 'b2300',
            text: 'B2300',
          },
          {
            value: 'b2500',
            text: 'B2500',
          },
          {
            value: 'b2600',
            text: 'B2600',
          },
          {
            value: 'b3000',
            text: 'B3000',
          },
          {
            value: 'b4000',
            text: 'B4000',
          },
          {
            value: 'pickup',
            text: 'Pickup',
          },
          {
            value: 'cx3',
            text: 'CX-3',
          },
          {
            value: 'cx30',
            text: 'CX-30',
          },
          {
            value: 'cx5',
            text: 'CX-5',
          },
          {
            value: 'cx7',
            text: 'CX-7',
          },
          {
            value: 'cx9',
            text: 'CX-9',
          },
          {
            value: 'mazda2',
            text: 'Mazda2',
          },
          {
            value: 'mazda3',
            text: 'Mazda3',
          },
          {
            value: 'mazda5',
            text: 'Mazda5',
          },
          {
            value: 'mazda6',
            text: 'Mazda6',
          },
          {
            value: 'mazdaspeedMiataMx5',
            text: 'Mazdaspeed Miata MX-5',
          },
          {
            value: 'mazdaspeedProtege',
            text: 'Mazdaspeed Protege',
          },
          {
            value: 'mazdaspeed3',
            text: 'Mazdaspeed3',
          },
          {
            value: 'mazdaspeed6',
            text: 'Mazdaspeed6',
          },
          {
            value: 'millenia',
            text: 'Millenia',
          },
          {
            value: 'mpv',
            text: 'MPV',
          },
          {
            value: 'mx3',
            text: 'MX-3',
          },
          {
            value: 'mx5Miata',
            text: 'MX-5 Miata',
          },
          {
            value: 'mx5MiataRf',
            text: 'MX-5 Miata RF',
          },
          {
            value: 'mx6',
            text: 'MX-6',
          },
          {
            value: 'navajo',
            text: 'Navajo',
          },
          {
            value: 'protege',
            text: 'Protege',
          },
          {
            value: 'protege5',
            text: 'Protege5',
          },
          {
            value: 'rx7',
            text: 'RX-7',
          },
          {
            value: 'rx8',
            text: 'RX-8',
          },
          {
            value: 'tribute',
            text: 'Tribute',
          },
          {
            value: 'tributeHybrid',
            text: 'Tribute Hybrid',
          },
        ],
        maybach: [
          {
            text: '57',
            value: '57',
          },
          {
            text: '62',
            value: '62',
          },
        ],
        mini: [
          {
            text: 'Cooper',
            value: 'cooper',
          },
          {
            text: 'Cooper Clubman',
            value: 'cooperClubman',
          },
          {
            text: 'Cooper Countryman',
            value: 'cooperCountryman',
          },
          {
            text: 'Cooper Coupe',
            value: 'cooperCoupe',
          },
          {
            text: 'Cooper Paceman',
            value: 'cooperPaceman',
          },
          {
            text: 'Cooper Roadster',
            value: 'cooperRoadster',
          },
        ],
        mercedesBenz: [
          {
            text: '190 D',
            value: '190D',
          },
          {
            text: '190 E',
            value: '190E',
          },
          {
            text: '240 D',
            value: '240D',
          },
          {
            text: '260E',
            value: '260E',
          },
          {
            text: '280 CE',
            value: '280CE',
          },
          {
            text: '280 E',
            value: '280E',
          },
          {
            text: '300 CD',
            value: '300CD',
          },
          {
            text: '300 CE',
            value: '300CE',
          },
          {
            text: '300 D',
            value: '300D',
          },
          {
            text: '300 E',
            value: '300E',
          },
          {
            text: '300 SD',
            value: '300SD',
          },
          {
            text: '300 SDL',
            value: '300SDL',
          },
          {
            text: '300 SE',
            value: '300SE',
          },
          {
            text: '300 SEL',
            value: '300SEL',
          },
          {
            text: '300 SL',
            value: '300SL',
          },
          {
            text: '300 TD',
            value: '300TD',
          },
          {
            text: '300 TE',
            value: '300TE',
          },
          {
            text: '350 SD',
            value: '350SD',
          },
          {
            text: '350 SDL',
            value: '350SDL',
          },
          {
            text: '380 SE',
            value: '380SE',
          },
          {
            text: '380 SEC',
            value: '380SEC',
          },
          {
            text: '380 SEL',
            value: '380SEL',
          },
          {
            text: '380 SL',
            value: '380SL',
          },
          {
            text: '380 SLC',
            value: '380SLC',
          },
          {
            text: '400 E',
            value: '400E',
          },
          {
            text: '400 SE',
            value: '400SE',
          },
          {
            text: '400 SEL',
            value: '400SEL',
          },
          {
            text: '420 SEL',
            value: '420SEL',
          },
          {
            text: '500 E',
            value: '500E',
          },
          {
            text: '500 SEC',
            value: '500SEC',
          },
          {
            text: '500 SEL',
            value: '500SEL',
          },
          {
            text: '500 SL',
            value: '500SL',
          },
          {
            text: '560 SEL',
            value: '560SEL',
          },
          {
            text: '560 SL',
            value: '560SL',
          },
          {
            text: '600 SEC',
            value: '600SEC',
          },
          {
            text: '600 SEL',
            value: '600SEL',
          },
          {
            text: '600 SL',
            value: '600SL',
          },
          {
            text: 'A-ClASS',
            value: 'aClass',
          },
          {
            text: 'A 220',
            value: 'a220',
          },
          {
            text: 'A 35 AMG',
            value: 'a35Amg',
          },
          {
            text: 'AMG GT',
            value: 'amgGt',
          },
          {
            text: 'B-Class',
            value: 'bClass',
          },
          {
            text: 'B 250e',
            value: 'b250e',
          },
          {
            text: 'B Class Electric Drive',
            value: 'bClassElectricDrive',
          },
          {
            text: 'C-CLASS',
            value: 'cClass',
          },
          {
            text: 'C 220',
            value: 'c220',
          },
          {
            text: 'C 230',
            value: 'c230',
          },
          {
            text: 'C 240',
            value: 'c240',
          },
          {
            text: 'C 250',
            value: 'c250',
          },
          {
            text: 'C 280',
            value: 'c280',
          },
          {
            text: 'C 300',
            value: 'c300',
          },
          {
            text: 'C 32 AMG',
            value: 'c32Amg',
          },
          {
            text: 'C 320',
            value: 'c320',
          },
          {
            text: 'C 350',
            value: 'c350',
          },
          {
            text: 'C 350e',
            value: 'c350e',
          },
          {
            text: 'C 36 AMG',
            value: 'c36Amg',
          },
          {
            text: 'C 400',
            value: 'c400',
          },
          {
            text: 'C 43 AMG',
            value: 'c43Amg',
          },
          {
            text: 'C 450',
            value: 'c450',
          },
          {
            text: 'C 55 AMG',
            value: 'c55Amg',
          },
          {
            text: 'C 63 AMG',
            value: 'c63Amg',
          },
          {
            text: 'CL-Class',
            value: 'clClass',
          },
        ],
        mclaren: [
          {
            text: '570GT',
            value: '570GT',
          },
          {
            text: '570S',
            value: '570S',
          },
          {
            text: '600LT',
            value: '600LT',
          },
          {
            text: '650S',
            value: '650S',
          },
          {
            text: '675LT',
            value: '675LT',
          },
          {
            text: '720S',
            value: '720S',
          },
          {
            text: 'GT',
            value: 'GT',
          },
          {
            text: 'MP4-12C',
            value: 'MP412C',
          },
          {
            text: 'P1',
            value: 'P1',
          },
          {
            text: 'SENNA',
            value: 'SENNA',
          },
          {
            text: 'OTHERS',
            value: 'OTHERS',
          },
        ],
        mercury: [
          {
            text: 'CAPRI',
            value: 'Capri',
          },
          {
            text: 'COUGAR',
            value: 'Cougar',
          },
          {
            text: 'GRAND MARQUIS',
            value: 'GrandMarquis',
          },
          {
            text: 'LN7',
            value: 'LN7',
          },
          {
            text: 'LYNX',
            value: 'Lynx',
          },
          {
            text: 'MARAUDER',
            value: 'Marauder',
          },
          {
            text: 'MARINER',
            value: 'Mariner',
          },
          {
            text: 'MARQUIS',
            value: 'Marquis',
          },
          {
            text: 'MILAN',
            value: 'Milan',
          },
          {
            text: 'MONTEGO',
            value: 'Montego',
          },
          {
            text: 'MONTEREY',
            value: 'Monterey',
          },
          {
            text: 'MOUNTAINEER',
            value: 'Mountaineer',
          },
          {
            text: 'MYSTIQUE',
            value: 'Mystique',
          },
          {
            text: 'SABLE',
            value: 'Sable',
          },
          {
            text: 'TOPAZ',
            value: 'Topaz',
          },
          {
            text: 'TRACER',
            value: 'Tracer',
          },
          {
            text: 'VILLAGER',
            value: 'Villager',
          },
          {
            text: 'ZEPHYR',
            value: 'Zephyr',
          },
          {
            text: 'OTHERS',
            value: 'Others',
          },
        ],
        mitsubishi: [
          {
            text: '3000GT',
            value: '3000GT',
          },
          {
            text: 'CORDIA',
            value: 'Cordia',
          },
          {
            text: 'DIAMANTE',
            value: 'Diamante',
          },
          {
            text: 'ECLIPSE',
            value: 'Eclipse',
          },
          {
            text: 'ECLIPSE CROSS',
            value: 'EclipseCross',
          },
          {
            text: 'ENDEAVOR',
            value: 'Endeavor',
          },
          {
            text: 'EXPO',
            value: 'Expo',
          },
          {
            text: 'GALANT',
            value: 'Galant',
          },
          {
            text: 'i',
            value: 'i',
          },
          {
            text: 'LANCER',
            value: 'Lancer',
          },
          {
            text: 'LANCER EVOLUTION',
            value: 'LancerEvolution',
          },
          {
            text: 'MIGHTY MAX',
            value: 'MightyMax',
          },
          {
            text: 'MIRAGE',
            value: 'Mirage',
          },
          {
            text: 'MIRAGE G4',
            value: 'MirageG4',
          },
          {
            text: 'MONTERO',
            value: 'Montero',
          },
          {
            text: 'MONTERO SPORT',
            value: 'MonteroSport',
          },
          {
            text: 'OUTLANDER',
            value: 'Outlander',
          },
          {
            text: 'OUTLANDER SPORT',
            value: 'OutlanderSport',
          },
          {
            text: 'PRECIS',
            value: 'Precis',
          },
          {
            text: 'RAIDER',
            value: 'Raider',
          },
          {
            text: 'SIGMA',
            value: 'Sigma',
          },
          {
            text: 'STARION',
            value: 'Starion',
          },
          {
            text: 'TREDIA',
            value: 'Tredia',
          },
          {
            text: 'VAN',
            value: 'Van',
          },
          {
            text: 'OTHERS',
            value: 'Others',
          },
        ],
        nissan: [
          {
            text: '200SX',
            value: '200SX',
          },
          {
            text: '240SX',
            value: '240SX',
          },
          {
            text: '300ZX',
            value: '300ZX',
          },
          {
            text: '350Z',
            value: '350Z',
          },
          {
            text: '370Z',
            value: '370Z',
          },
          {
            text: 'ALTIMA',
            value: 'Altima',
          },
          {
            text: 'ARMADA',
            value: 'Armada',
          },
          {
            text: 'AXXESS',
            value: 'Axxess',
          },
          {
            text: 'CUBE',
            value: 'Cube',
          },
          {
            text: 'FRONTIER',
            value: 'Frontier',
          },
          {
            text: 'GT-R',
            value: 'GT-R',
          },
          {
            text: 'JUKE',
            value: 'Juke',
          },
          {
            text: 'KICKS',
            value: 'Kicks',
          },
          {
            text: 'LEAF',
            value: 'Leaf',
          },
          {
            text: 'MAXIMA',
            value: 'Maxima',
          },
          {
            text: 'MURANO',
            value: 'Murano',
          },
          {
            text: 'MURANO CROSSCABRIOLET',
            value: 'MuranoCrossCabriolet',
          },
          {
            text: 'NV',
            value: 'NV',
          },
          {
            text: 'NV200',
            value: 'NV200',
          },
          {
            text: 'NX',
            value: 'NX',
          },
          {
            text: 'PATHFINDER',
            value: 'Pathfinder',
          },
          {
            text: 'PICKUP',
            value: 'Pickup',
          },
          {
            text: 'PULSAR',
            value: 'Pulsar',
          },
          {
            text: 'QUEST',
            value: 'Quest',
          },
          {
            text: 'ROGUE',
            value: 'Rogue',
          },
          {
            text: 'ROGUE SPORT',
            value: 'RogueSport',
          },
          {
            text: 'SENTRA',
            value: 'Sentra',
          },
          {
            text: 'STANZA',
            value: 'Stanza',
          },
          {
            text: 'TITAN',
            value: 'Titan',
          },
          {
            text: 'VAN',
            value: 'Van',
          },
          {
            text: 'VERSA',
            value: 'Versa',
          },
          {
            text: 'VERSA NOTE',
            value: 'VersaNote',
          },
          {
            text: 'XTERRA',
            value: 'Xterra',
          },
          {
            text: 'OTHERS',
            value: 'Others',
          },
        ],
        oldsmobile: [
          {
            text: 'ACHIEVA',
            value: 'Achieva',
          },
          {
            text: 'ALERO',
            value: 'Alero',
          },
          {
            text: 'AURORA',
            value: 'Aurora',
          },
          {
            text: 'BRAVADA',
            value: 'Bravada',
          },
          {
            text: 'CUSTOM CRUISER',
            value: 'CustomCruiser',
          },
          {
            text: 'CUTLASS',
            value: 'Cutlass',
          },
          {
            text: 'CUTLASS CALAIS',
            value: 'CutlassCalais',
          },
          {
            text: 'CUTLASS CIERA',
            value: 'CutlassCiera',
          },
          {
            text: 'CUTLASS SUPREME',
            value: 'CutlassSupreme',
          },
          {
            text: 'FIRENZA',
            value: 'Firenza',
          },
          {
            text: 'INTRIGUE',
            value: 'Intrigue',
          },
          {
            text: 'NINETY-EIGHT',
            value: 'NinetyEight',
          },
          {
            text: 'OMEGA',
            value: 'Omega',
          },
          {
            text: 'REGENCY',
            value: 'Regency',
          },
          {
            text: 'SILHOUETTE',
            value: 'Silhouette',
          },
          {
            text: 'TORONADO',
            value: 'Toronado',
          },
        ],
        plymouth: [
          {
            text: 'ACCLAIM',
            value: 'Acclaim',
          },
          {
            text: 'ARROW',
            value: 'Arrow',
          },
          {
            text: 'BREEZE',
            value: 'Breeze',
          },
          {
            text: 'CARAVELLE',
            value: 'Caravelle',
          },
          {
            text: 'CHAMP',
            value: 'Champ',
          },
          {
            text: 'COLT',
            value: 'Colt',
          },
          {
            text: 'CONQUEST',
            value: 'Conquest',
          },
          {
            text: 'GRAN FURY',
            value: 'GranFury',
          },
          {
            text: 'GRAND VOYAGER',
            value: 'GrandVoyager',
          },
          {
            text: 'HORIZON',
            value: 'Horizon',
          },
          {
            text: 'LASER',
            value: 'Laser',
          },
          {
            text: 'NEON',
            value: 'Neon',
          },
          {
            text: 'PROWLER',
            value: 'Prowler',
          },
          {
            text: 'RELIANT',
            value: 'Reliant',
          },
          {
            text: 'SAPPORO',
            value: 'Sapporo',
          },
          {
            text: 'SCAMP',
            value: 'Scamp',
          },
          {
            text: 'SUNDANCE',
            value: 'Sundance',
          },
          {
            text: 'TRAILDUSTER',
            value: 'Trailduster',
          },
          {
            text: 'TURISMO',
            value: 'Turismo',
          },
          {
            text: 'VOYAGER',
            value: 'Voyager',
          },
        ],
        pontiac: [
          {
            text: 'AZTEK',
            value: 'Aztek',
          },
          {
            text: 'BONNEVILLE',
            value: 'Bonneville',
          },
          {
            text: 'CATALINA',
            value: 'Catalina',
          },
          {
            text: 'FIERO',
            value: 'Fiero',
          },
          {
            text: 'FIREBIRD',
            value: 'Firebird',
          },
          {
            text: 'G3',
            value: 'G3',
          },
          {
            text: 'G5',
            value: 'G5',
          },
          {
            text: 'G6',
            value: 'G6',
          },
          {
            text: 'G8',
            value: 'G8',
          },
          {
            text: 'GRAND AM',
            value: 'GrandAm',
          },
          {
            text: 'GRAND PRIX',
            value: 'GrandPrix',
          },
          {
            text: 'GTO',
            value: 'GTO',
          },
          {
            text: 'J2000',
            value: 'J2000',
          },
          {
            text: 'LE MANS',
            value: 'LeMans',
          },
          {
            text: 'MONTANA',
            value: 'Montana',
          },
          {
            text: 'PARISIENNE',
            value: 'Parisiennne',
          },
          {
            text: 'PHOENIX',
            value: 'Phoenix',
          },
          {
            text: 'SAFARI',
            value: 'Safari',
          },
          {
            text: 'SOLSTICE',
            value: 'Solstice',
          },
          {
            text: 'SUNBIRD',
            value: 'Sunbird',
          },
          {
            text: 'SUNFIRE',
            value: 'Sunfire',
          },
          {
            text: 'TORRENT',
            value: 'Torrent',
          },
          {
            text: 'TRANS SPORT',
            value: 'TransSport',
          },
          {
            text: 'VIBE',
            value: 'Vibe',
          },
        ],
        porsche: [
          {
            text: '718 BOXSTER',
            value: 'Boxster 718',
          },
          {
            text: '718 CAYMAN',
            value: 'Cayman 718',
          },
          {
            text: '911',
            value: '911',
          },
          {
            text: '918 SPYDER',
            value: '918 Spyder',
          },
          {
            text: '924',
            value: '924',
          },
          {
            text: '928',
            value: '928',
          },
          {
            text: '950',
            value: '950',
          },
          {
            text: '968',
            value: '968',
          },
          {
            text: 'BOXSTER',
            value: 'Boxster',
          },
          {
            text: 'CARRERA GT',
            value: 'Carrera GT',
          },
          {
            text: 'CAYENNE',
            value: 'Cayenne',
          },
          {
            text: 'CAYMAN',
            value: 'Cayman',
          },
          {
            text: 'MACAN',
            value: 'Macan',
          },
          {
            text: 'PANAMERA',
            value: 'Panamera',
          },
          {
            text: 'TAYCAN',
            value: 'Taycan',
          },
        ],
        ram: [
          {
            text: '1500',
            value: '1500',
          },
          {
            text: '2500',
            value: '2500',
          },
          {
            text: '3500',
            value: '3500',
          },
          {
            text: '4500',
            value: '4500',
          },
          {
            text: '5500',
            value: '5500',
          },
          {
            text: 'C/V',
            value: 'CV',
          },
          {
            text: 'PROMASTER',
            value: 'ProMaster',
          },
          {
            text: 'PROMASTER CITY',
            value: 'ProMaster City',
          },
        ],
        rollsRoyce: [
          {
            text: 'Camargue',
            value: 'Camargue',
          },
          {
            text: 'Corniche',
            value: 'Corniche',
          },
          {
            text: 'Cullinan',
            value: 'Cullinan',
          },
          {
            text: 'Dawn',
            value: 'Dawn',
          },
          {
            text: 'Ghost',
            value: 'Ghost',
          },
          {
            text: 'Park Ward',
            value: 'ParkWard',
          },
          {
            text: 'Phantom',
            value: 'Phantom',
          },
          {
            text: 'Silver Dawn',
            value: 'SilverDawn',
          },
          {
            text: 'Silver Seraph',
            value: 'SilverSeraph',
          },
          {
            text: 'Silver Spirit',
            value: 'SilverSpirit',
          },
          {
            text: 'Silver Spur',
            value: 'SilverSpur',
          },
          {
            text: 'Wraith',
            value: 'Wraith',
          },
          {
            text: 'Others',
            value: 'Others',
          },
        ],
        srt: [
          {
            text: 'VIPER',
            value: 'viper',
          },
        ],
        saab: [
          {
            text: '9-2X',
            value: '9-2X',
          },
          {
            text: '9-3',
            value: '9-3',
          },
          {
            text: '9-4X',
            value: '9-4X',
          },
          {
            text: '9-5',
            value: '9-5',
          },
          {
            text: '9-7X',
            value: '9-7X',
          },
          {
            text: '900',
            value: '900',
          },
          {
            text: '9000',
            value: '9000',
          },
        ],
        saturn: [
          {
            text: 'Astra',
            value: 'astra',
          },
          {
            text: 'Aura',
            value: 'aura',
          },
          {
            text: 'Ion',
            value: 'ion',
          },
          {
            text: 'L-Series',
            value: 'lSeries',
          },
          {
            text: 'Outlook',
            value: 'outlook',
          },
          {
            text: 'Relay',
            value: 'relay',
          },
          {
            text: 'S-Series',
            value: 'sSeries',
          },
          {
            text: 'Sky',
            value: 'sky',
          },
          {
            text: 'Vue',
            value: 'vue',
          },
        ],
        scion: [
          {
            text: 'FR-S',
            value: 'frS',
          },
          {
            text: 'iA',
            value: 'iA',
          },
          {
            text: 'iM',
            value: 'iM',
          },
          {
            text: 'iQ',
            value: 'iQ',
          },
          {
            text: 'tC',
            value: 'tC',
          },
          {
            text: 'xA',
            value: 'xA',
          },
          {
            text: 'xB',
            value: 'xB',
          },
          {
            text: 'xD',
            value: 'xD',
          },
        ],
        subaru: [
          {
            text: 'ASCENT',
            value: 'ascent',
          },
          {
            text: 'BAJA',
            value: 'baja',
          },
          {
            text: 'BRAT',
            value: 'brat',
          },
          {
            text: 'BRZ',
            value: 'brz',
          },
          {
            text: 'CROSSTREK',
            value: 'crosstrek',
          },
          {
            text: 'FORESTER',
            value: 'forester',
          },
          {
            text: 'IMPREZA',
            value: 'impreza',
          },
          {
            text: 'IMPREZA WRX',
            value: 'imprezaWrx',
          },
          {
            text: 'JUSTY',
            value: 'justy',
          },
          {
            text: 'L SERIES',
            value: 'lSeries',
          },
          {
            text: 'LEGACY',
            value: 'legacy',
          },
          {
            text: 'LOYALE',
            value: 'loyale',
          },
          {
            text: 'OUTBACK',
            value: 'outback',
          },
          {
            text: 'SVX',
            value: 'svx',
          },
          {
            text: 'TRIBECA',
            value: 'tribeca',
          },
          {
            text: 'WRX',
            value: 'wrx',
          },
          {
            text: 'XT',
            value: 'xt',
          },
          {
            text: 'XV CROSSTREK',
            value: 'xvCrosstrek',
          },
        ],
        suzuki: [
          {
            text: 'AERIO',
            value: 'aerio',
          },
          {
            text: 'EQUATOR',
            value: 'equator',
          },
          {
            text: 'ESTEEM',
            value: 'esteem',
          },
          {
            text: 'FORENZA',
            value: 'forenza',
          },
          {
            text: 'GRAND VITARA',
            value: 'grandVitara',
          },
          {
            text: 'KIZASHI',
            value: 'kizashi',
          },
          {
            text: 'RENO',
            value: 'reno',
          },
          {
            text: 'SAMURAI',
            value: 'samurai',
          },
          {
            text: 'SIDEKICK',
            value: 'sidekick',
          },
          {
            text: 'SWIFT',
            value: 'swift',
          },
          {
            text: 'SX4',
            value: 'sx4',
          },
          {
            text: 'VERONA',
            value: 'verona',
          },
          {
            text: 'VITARA',
            value: 'vitara',
          },
          {
            text: 'X-90',
            value: 'x90',
          },
          {
            text: 'XL7',
            value: 'xl7',
          },
        ],
        tesla: [
          {
            text: 'MODEL 3',
            value: 'model3',
          },
          {
            text: 'MODEL S',
            value: 'modelS',
          },
          {
            text: 'MODEL X',
            value: 'modelX',
          },
          {
            text: 'MODEL Y',
            value: 'modelY',
          },
          {
            text: 'ROADSTER',
            value: 'roadster',
          },
        ],
        toyota: [
          {
            text: '4RUNNER',
            value: 'fourRunner',
          },
          {
            text: '86',
            value: 'eightySix',
          },
          {
            text: 'AVALON',
            value: 'avalon',
          },
          {
            text: 'C-HR',
            value: 'cHR',
          },
          {
            text: 'CAMRY',
            value: 'camry',
          },
          {
            text: 'CELICA',
            value: 'celica',
          },
          {
            text: 'COROLLA',
            value: 'corolla',
          },
          {
            text: 'CORONA',
            value: 'corona',
          },
          {
            text: 'CRESSIDA',
            value: 'cressida',
          },
          {
            text: 'ECHO',
            value: 'echo',
          },
          {
            text: 'FJ CRUISER',
            value: 'fjCruiser',
          },
          {
            text: 'HIGHLANDER',
            value: 'highlander',
          },
          {
            text: 'LAND CRUISER',
            value: 'landCruiser',
          },
          {
            text: 'MATRIX',
            value: 'matrix',
          },
          {
            text: 'MIRAI',
            value: 'mirai',
          },
          {
            text: 'MR2',
            value: 'mr2',
          },
          {
            text: 'MRS SPYDER',
            value: 'mrsSpyder',
          },
          {
            text: 'PASEO',
            value: 'paseo',
          },
          {
            text: 'PICKUP',
            value: 'pickup',
          },
          {
            text: 'PREVIA',
            value: 'previa',
          },
          {
            text: 'PRIUS',
            value: 'prius',
          },
          {
            text: 'PRIUS C',
            value: 'priusC',
          },
          {
            text: 'PRIUS PRIME',
            value: 'priusPrime',
          },
          {
            text: 'PRIUS V',
            value: 'priusV',
          },
          {
            text: 'RAV4',
            value: 'rav4',
          },
          {
            text: 'SEQUOIA',
            value: 'sequoia',
          },
          {
            text: 'SIENNA',
            value: 'sienna',
          },
          {
            text: 'SOLARA',
            value: 'solara',
          },
          {
            text: 'STARLET',
            value: 'starlet',
          },
          {
            text: 'SUPRA',
            value: 'supra',
          },
          {
            text: 'T100',
            value: 't100',
          },
          {
            text: 'TACOMA',
            value: 'tacoma',
          },
          {
            text: 'TERCEL',
            value: 'tercel',
          },
          {
            text: 'TUNDRA',
            value: 'tundra',
          },
          {
            text: 'VAN',
            value: 'van',
          },
          {
            text: 'VENZA',
            value: 'venza',
          },
          {
            text: 'YARIS',
            value: 'yaris',
          },
          {
            text: 'OTHERS',
            value: 'others',
          },
        ],
        volkswagen: [
          {
            text: 'ARTEON',
            value: 'arteon',
          },
          {
            text: 'ATLAS',
            value: 'atlas',
          },
          {
            text: 'BEETLE',
            value: 'beetle',
          },
          {
            text: 'CABRIO',
            value: 'cabrio',
          },
          {
            text: 'CABRIOLET',
            value: 'cabriolet',
          },
          {
            text: 'CC',
            value: 'cc',
          },
          {
            text: 'CORRADO',
            value: 'corrado',
          },
          {
            text: 'DASHER',
            value: 'dasher',
          },
          {
            text: 'E-GOLF',
            value: 'eGolf',
          },
          {
            text: 'EOS',
            value: 'eos',
          },
          {
            text: 'EUROVAN',
            value: 'eurovan',
          },
          {
            text: 'FOX',
            value: 'fox',
          },
          {
            text: 'GOLF',
            value: 'golf',
          },
          {
            text: 'GOLF R',
            value: 'golfR',
          },
          {
            text: 'GTI',
            value: 'gti',
          },
          {
            text: 'JETTA',
            value: 'jetta',
          },
          {
            text: 'PASSAT',
            value: 'passat',
          },
          {
            text: 'PHAETON',
            value: 'phaeton',
          },
          {
            text: 'PICKUP',
            value: 'pickup',
          },
          {
            text: 'QUANTUM',
            value: 'quantum',
          },
          {
            text: 'R32',
            value: 'r32',
          },
          {
            text: 'RABBIT',
            value: 'rabbit',
          },
          {
            text: 'ROUTAN',
            value: 'routan',
          },
          {
            text: 'SCIROCCO',
            value: 'scirocco',
          },
          {
            text: 'TIGUAN',
            value: 'tiguan',
          },
          {
            text: 'TOUREG',
            value: 'toureg',
          },
          {
            text: 'VANAGON',
            value: 'vanagon',
          },
          {
            text: 'OTHERS',
            value: 'others',
          },
        ],
        volvo: [
          {
            text: '240',
            value: 'twoForty',
          },
          {
            text: '260',
            value: 'twoSixty',
          },
          {
            text: '740',
            value: 'sevenForty',
          },
          {
            text: '760',
            value: 'sevenSixty',
          },
          {
            text: '780',
            value: 'sevenEighty',
          },
          {
            text: '850',
            value: 'eightFifty',
          },
          {
            text: '940',
            value: 'nineForty',
          },
          {
            text: '960',
            value: 'nineSixty',
          },
          {
            text: 'C30',
            value: 'cThirty',
          },
          {
            text: 'C70',
            value: 'cSeventy',
          },
          {
            text: 'S40',
            value: 'sForty',
          },
          {
            text: 'S60',
            value: 'sSixty',
          },
          {
            text: 'S70',
            value: 'sSeventy',
          },
          {
            text: 'S80',
            value: 'sEighty',
          },
          {
            text: 'S90',
            value: 'sNinety',
          },
          {
            text: 'V40',
            value: 'vForty',
          },
          {
            text: 'V50',
            value: 'vFifty',
          },
          {
            text: 'V60',
            value: 'vSixty',
          },
          {
            text: 'V70',
            value: 'vSeventy',
          },
          {
            text: 'V90',
            value: 'vNinety',
          },
          {
            text: 'XC40',
            value: 'xcForty',
          },
          {
            text: 'XC60',
            value: 'xcSixty',
          },
          {
            text: 'XC70',
            value: 'xcSeventy',
          },
          {
            text: 'XC90',
            value: 'xcNinety',
          },
          {
            text: 'OTHERS',
            value: 'others',
          },
        ],
      },
    },
    {
      label: 'Year',
      name: 'year',
      type: 'number',
      mask: [/\d/, /\d/, /\d/, /\d/],
    },

    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'select',
      data: titles,
    },
    {
      label: 'Miles',
      name: 'miles',
      type: 'number',
    },
    {
      label: 'Engine',
      name: 'engine',
      type: 'text',
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'select',

      data: condition,
    },
    {
      label: 'Features',
      name: 'features',
      type: 'select',
      isMultiSelect: true,
      data: features,
    },

    {
      label: 'Body Style',
      name: 'bodyStyle',
      type: 'select',

      data: bodyStyle,
    },
    {
      label: 'Fuel',
      name: 'fuel',
      type: 'select',

      data: fuels,
    },
    {
      label: 'Transmission',
      name: 'transmission',
      type: 'select',
      data: ['Auto', 'Manual', 'Semi Auto'],
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
  heavy_duty: [
    {
      label: 'Title Vehicle',
      name: 'titleVehicle',
      type: 'text',
      placeholder: 'example: 2012 ford mustang',
    },
    {
      label: 'Make',
      name: 'make',
      type: 'select',

      isMultiSelect: false,
      data: heavy_dutyMakes,
    },
    {
      label: 'Model',
      name: 'model',
      type: 'text',
      data: {
        caterpillar: [],
        chevrolet: [
          {
            value: 'astro',
            text: 'ASTRO',
          },
          {
            value: 'avalanche',
            text: 'AVALANCHE',
          },
          {
            value: 'aveo',
            text: 'AVEO',
          },
          {
            value: 'aveo5',
            text: 'AVEO5',
          },
          {
            value: 'beretta',
            text: 'BERETTA',
          },
          {
            value: 'blazer',
            text: 'BLAZER',
          },
          {
            value: 'bolt',
            text: 'BOLT',
          },
          {
            value: 'cKTruck',
            text: 'C/K TRUCK',
          },
          {
            value: 'camaro',
            text: 'CAMARO',
          },
          {
            value: 'caprice',
            text: 'CAPRICE',
          },
          {
            value: 'captivaSport',
            text: 'CAPTIVA SPORT',
          },
          {
            value: 'cavalier',
            text: 'CAVALIER',
          },
          {
            value: 'celebrity',
            text: 'CELEBRITY',
          },
          {
            value: 'chevette',
            text: 'CHEVETTE',
          },
          {
            value: 'citation',
            text: 'CITATION',
          },
          {
            value: 'cityExpress',
            text: 'CITY EXPRESS',
          },
          {
            value: 'cobalt',
            text: 'COBALT',
          },
          {
            value: 'colorado',
            text: 'COLORADO',
          },
          {
            value: 'corsica',
            text: 'CORSICA',
          },
          {
            value: 'corvette',
            text: 'CORVETTE',
          },
          {
            value: 'cruze',
            text: 'CRUZE',
          },
          {
            value: 'elCamino',
            text: 'EL CAMINO',
          },
          {
            value: 'equinox',
            text: 'EQUINOX',
          },
          {
            value: 'express1500',
            text: 'EXPRESS 1500',
          },
          {
            value: 'express2500',
            text: 'EXPRESS 2500',
          },
          {
            value: 'express3500',
            text: 'EXPRESS 3500',
          },
          {
            value: 'express4500',
            text: 'EXPRESS 4500',
          },
          {
            value: 'g10',
            text: 'G10',
          },
          {
            value: 'g20',
            text: 'G20',
          },
          {
            value: 'g30',
            text: 'G30',
          },
          {
            value: 'hhr',
            text: 'HHR',
          },
          {
            value: 'impala',
            text: 'IMPALA',
          },
          {
            value: 'kodiacC4500',
            text: 'KODIAC C4500',
          },
          {
            value: 'lumina',
            text: 'LUMINA',
          },
          {
            value: 'luminaApv',
            text: 'LUMINA APV',
          },
          {
            value: 'luv',
            text: 'LUV',
          },
          {
            value: 'malibu',
            text: 'MALIBU',
          },
          {
            value: 'metro',
            text: 'METRO',
          },
          {
            value: 'monteCarlo',
            text: 'MONTE CARLO',
          },
          {
            value: 'nova',
            text: 'NOVA',
          },
          {
            value: 'prizm',
            text: 'PRIZM',
          },
          {
            value: 's10Blazer',
            text: 'S10 BLAZER',
          },
          {
            value: 's10Pickup',
            text: 'S10 PICKUP',
          },
          {
            value: 'silverado1500',
            text: 'SILVERADO 1500',
          },
          {
            value: 'silverado2500',
            text: 'SILVERADO 2500',
          },
          {
            value: 'silverado3500',
            text: 'SILVERADO 3500',
          },
          {
            value: 'sonic',
            text: 'SONIC',
          },
          {
            value: 'spark',
            text: 'SPARK',
          },
          {
            value: 'spectrum',
            text: 'SPECTRUM',
          },
          {
            value: 'sprint',
            text: 'SPRINT',
          },
          {
            value: 'ss',
            text: 'SS',
          },
          {
            value: 'ssr',
            text: 'SSR',
          },
          {
            value: 'suburban',
            text: 'SUBURBAN',
          },
          {
            value: 'tahoe',
            text: 'TAHOE',
          },
          {
            value: 'tracker',
            text: 'TRACKER',
          },
          {
            value: 'trailblazer',
            text: 'TRAILBLAZER',
          },
          {
            value: 'trailblazerExt',
            text: 'TRAILBLAZER EXT',
          },
          {
            value: 'traverse',
            text: 'TRAVERSE',
          },
          {
            value: 'trax',
            text: 'TRAX',
          },
          {
            value: 'uplander',
            text: 'UPLANDER',
          },
          {
            value: 'venture',
            text: 'VENTURE',
          },
          {
            value: 'volt',
            text: 'VOLT',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        ford: [
          {
            value: 'aerostar',
            text: 'AEROSTAR',
          },
          {
            value: 'aspire',
            text: 'ASPIRE',
          },
          {
            value: 'bronco',
            text: 'BRONCO',
          },
          {
            value: 'broncoII',
            text: 'BRONCO II',
          },
          {
            value: 'cMax',
            text: 'C-MAX',
          },
          {
            value: 'contour',
            text: 'CONTOUR',
          },
          {
            value: 'courier',
            text: 'COURIER',
          },
          {
            value: 'crownVictoria',
            text: 'CROWN VICTORIA',
          },
          {
            value: 'e100',
            text: 'E-100',
          },
          {
            value: 'e150AndEconoline150',
            text: 'E-150 AND ECONOLINE 150',
          },
          {
            value: 'e250AndEconoline250',
            text: 'E-250 AND ECONOLINE 250',
          },
          {
            value: 'e350AndEconoline350',
            text: 'E-350 AND ECONOLINE 350',
          },
          {
            value: 'e450AndEconoline450',
            text: 'E-450 AND ECONOLINE 450',
          },
          {
            value: 'ecosport',
            text: 'ECOSPORT',
          },
          {
            value: 'edge',
            text: 'EDGE',
          },
          {
            value: 'escape',
            text: 'ESCAPE',
          },
          {
            value: 'escort',
            text: 'ESCORT',
          },
          {
            value: 'excursion',
            text: 'EXCURSION',
          },
          {
            value: 'exp',
            text: 'EXP',
          },
          {
            value: 'expedition',
            text: 'EXPEDITION',
          },
          {
            value: 'expeditionEl',
            text: 'EXPEDITION EL',
          },
          {
            value: 'expeditionMax',
            text: 'EXPEDITION MAX',
          },
          {
            value: 'explorer',
            text: 'EXPLORER',
          },
          {
            value: 'explrerSportTrac',
            text: 'EXPLRER SPORT TRAC',
          },
          {
            value: 'f100',
            text: 'F100',
          },
          {
            value: 'f150',
            text: 'F150',
          },
          {
            value: 'f250',
            text: 'F250',
          },
          {
            value: 'f350',
            text: 'F350',
          },
          {
            value: 'f450',
            text: 'F450',
          },
          {
            value: 'fairmont',
            text: 'FAIRMONT',
          },
          {
            value: 'festiva',
            text: 'FESTIVA',
          },
          {
            value: 'fiesta',
            text: 'FIESTA',
          },
          {
            value: 'fiveHundred',
            text: 'FIVE HUNDRED',
          },
          {
            value: 'flex',
            text: 'FLEX',
          },
          {
            value: 'focus',
            text: 'FOCUS',
          },
          {
            value: 'freestar',
            text: 'FREESTAR',
          },
          {
            value: 'freestyle',
            text: 'FREESTYLE',
          },
          {
            value: 'fusion',
            text: 'FUSION',
          },
          {
            value: 'granada',
            text: 'GRANADA',
          },
          {
            value: 'gt',
            text: 'GT',
          },
          {
            value: 'ltd',
            text: 'LTD',
          },
          {
            value: 'mustang',
            text: 'MUSTANG',
          },
          {
            value: 'probe',
            text: 'PROBE',
          },
          {
            value: 'ranger',
            text: 'RANGER',
          },
          {
            value: 'taurus',
            text: 'TAURUS',
          },
          {
            value: 'taurusX',
            text: 'TAURUS X',
          },
          {
            value: 'tempo',
            text: 'TEMPO',
          },
          {
            value: 'thunderbird',
            text: 'THUNDERBIRD',
          },
          {
            value: 'transit150',
            text: 'TRANSIT 150',
          },
          {
            value: 'transit250',
            text: 'TRANSIT 250',
          },
          {
            value: 'transit350',
            text: 'TRANSIT 350',
          },
          {
            value: 'transitConnect',
            text: 'TRANSIT CONNECT',
          },
          {
            value: 'windstar',
            text: 'WINDSTAR',
          },
          {
            value: 'zx2Escort',
            text: 'ZX2 ESCORT',
          },
          {
            value: 'others',
            text: 'OTHERS',
          },
        ],
        freightliner: [
          {
            text: 'SPRINTER',
            value: 'sprinter',
          },
        ],
        gmc: [
          {
            value: 'ACADIA',
            text: 'ACADIA',
          },
          {
            value: 'CABALLERO',
            text: 'CABALLERO',
          },
          {
            value: 'CANYON',
            text: 'CANYON',
          },
          {
            value: 'ENVOY',
            text: 'ENVOY',
          },
          {
            value: 'ENVOY XL',
            text: 'ENVOY XL',
          },
          {
            value: 'ENVOY XUV',
            text: 'ENVOY XUV',
          },
          {
            value: 'G1500',
            text: 'G1500',
          },
          {
            value: 'G2500',
            text: 'G2500',
          },
          {
            value: 'G3500',
            text: 'G3500',
          },
          {
            value: 'JIMMY',
            text: 'JIMMY',
          },
          {
            value: 'S15 JIMMY',
            text: 'S15 JIMMY',
          },
          {
            value: 'S15 PICKUP',
            text: 'S15 PICKUP',
          },
          {
            value: 'SAFARI',
            text: 'SAFARI',
          },
          {
            value: 'SAVANA 1500',
            text: 'SAVANA 1500',
          },
          {
            value: 'SAVANA 2500',
            text: 'SAVANA 2500',
          },
          {
            value: 'SAVANA 3500',
            text: 'SAVANA 3500',
          },
          {
            value: 'SAVANA 4500',
            text: 'SAVANA 4500',
          },
          {
            value: 'SIERRA 1500',
            text: 'SIERRA 1500',
          },
          {
            value: 'SIERRA 2500',
            text: 'SIERRA 2500',
          },
          {
            value: 'SIERRA 3500',
            text: 'SIERRA 3500',
          },
          {
            value: 'SONOMA',
            text: 'SONOMA',
          },
          {
            value: 'SUBURBAN',
            text: 'SUBURBAN',
          },
          {
            value: 'SYCLONE',
            text: 'SYCLONE',
          },
          {
            value: 'TERRAIN',
            text: 'TERRAIN',
          },
          {
            value: 'TOPKICK C4500',
            text: 'TOPKICK C4500',
          },
          {
            value: 'TYPHOON',
            text: 'TYPHOON',
          },
          {
            value: 'YUKON',
            text: 'YUKON',
          },
          {
            value: 'YUKON XL',
            text: 'YUKON XL',
          },
          {
            value: 'OTHERS',
            text: 'OTHERS',
          },
        ],
        hino: [],
        international: [],
        isuzu: [
          {
            value: 'amigo',
            text: 'Amigo',
          },
          {
            value: 'ascender',
            text: 'Ascender',
          },
          {
            value: 'axiom',
            text: 'Axiom',
          },
          {
            value: 'hombre',
            text: 'Hombre',
          },
          {
            value: 'i-280',
            text: 'i-280',
          },
          {
            value: 'i-290',
            text: 'i-290',
          },
          {
            value: 'i-350',
            text: 'i-350',
          },
          {
            value: 'i-370',
            text: 'i-370',
          },
          {
            value: 'i-mark',
            text: 'I-Mark',
          },
          {
            value: 'impulse',
            text: 'Impulse',
          },
          {
            value: 'oasis',
            text: 'Oasis',
          },
          {
            value: 'pickup',
            text: 'Pickup',
          },
          {
            value: 'rodeo',
            text: 'Rodeo',
          },
          {
            value: 'stylus',
            text: 'Stylus',
          },
          {
            value: 'trooper',
            text: 'Trooper',
          },
          {
            value: 'trooper-ii',
            text: 'Trooper II',
          },
          {
            value: 'vehicross',
            text: 'Vehicross',
          },
        ],
        kenworth: [],
        mack: [],
        peterbilt: [],
        sterling: [],
        volvo: [
          {
            text: '240',
            value: 'twoForty',
          },
          {
            text: '260',
            value: 'twoSixty',
          },
          {
            text: '740',
            value: 'sevenForty',
          },
          {
            text: '760',
            value: 'sevenSixty',
          },
          {
            text: '780',
            value: 'sevenEighty',
          },
          {
            text: '850',
            value: 'eightFifty',
          },
          {
            text: '940',
            value: 'nineForty',
          },
          {
            text: '960',
            value: 'nineSixty',
          },
          {
            text: 'C30',
            value: 'cThirty',
          },
          {
            text: 'C70',
            value: 'cSeventy',
          },
          {
            text: 'S40',
            value: 'sForty',
          },
          {
            text: 'S60',
            value: 'sSixty',
          },
          {
            text: 'S70',
            value: 'sSeventy',
          },
          {
            text: 'S80',
            value: 'sEighty',
          },
          {
            text: 'S90',
            value: 'sNinety',
          },
          {
            text: 'V40',
            value: 'vForty',
          },
          {
            text: 'V50',
            value: 'vFifty',
          },
          {
            text: 'V60',
            value: 'vSixty',
          },
          {
            text: 'V70',
            value: 'vSeventy',
          },
          {
            text: 'V90',
            value: 'vNinety',
          },
          {
            text: 'XC40',
            value: 'xcForty',
          },
          {
            text: 'XC60',
            value: 'xcSixty',
          },
          {
            text: 'XC70',
            value: 'xcSeventy',
          },
          {
            text: 'XC90',
            value: 'xcNinety',
          },
          {
            text: 'OTHERS',
            value: 'others',
          },
        ],
        westernstar: [],
        whiteGmc: [],
        tesla: [
          {
            text: 'Cyber Truck',
            value: 'cyberTruck',
          },
        ],
        nikola: [],
      },
    },
    {
      label: 'Year',
      name: 'year',
      type: 'number',
      mask: [/\d/, /\d/, /\d/, /\d/],
    },

    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'select',
      data: titles,
    },
    {
      label: 'Miles',
      name: 'miles',
      type: 'number',
    },
    {
      label: 'Engine',
      name: 'engine',
      type: 'text',
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'select',

      data: condition,
    },
    {
      label: 'Fuel',
      name: 'fuel',
      type: 'modal',

      data: fuels,
    },
    {
      label: 'Transmission',
      name: 'transmission',
      type: 'modal',

      data: ['Auto', 'Manual', 'Semi Auto'],
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
  motorcycle: [
    {
      label: 'Title Vehicle',
      name: 'titleVehicle',
      type: 'text',
      placeholder: 'example: 2012 ford mustang',
    },
    {
      label: 'Make',
      name: 'make',
      type: 'modal',

      isMultiSelect: false,
      data: motorcycleMakes,
    },
    {
      label: 'Model',
      name: 'model',
      type: 'text',
      data: {
        bmw: [],
        camAm: [],
        ducati: [],
        harleyDavidson: [],
        honda: [],
        husqvarna: [],
        indian: [],
        kawasaki: [],
        ktm: [],
        slingshot: [],
        ssrMotorsports: [],
        suzuki: [],
        triumph: [],
        vespa: [],
        yamaha: [],
      },
    },
    {
      label: 'Year',
      name: 'year',
      type: 'number',
      mask: [/\d/, /\d/, /\d/, /\d/],
    },
    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'select',
      data: titles,
    },
    {
      label: 'Miles',
      name: 'miles',
      type: 'number',
    },
    {
      label: 'Engine',
      name: 'engine',
      type: 'text',
    },
    {
      label: 'Fuel',
      name: 'fuel',
      type: 'modal',

      data: fuels,
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'modal',

      data: condition,
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
  boat: [
    {
      label: 'Title Vehicle',
      name: 'titleVehicle',
      type: 'text',
      placeholder: 'example: 2012 ford mustang',
    },
    {
      label: 'Make',
      name: 'make',
      type: 'modal',

      isMultiSelect: false,
      data: boatMakes,
    },
    {
      label: 'Model',
      name: 'model',
      type: 'text',
      data: {
        bayliner: [],
        bertram: [],
        beneteau: [],
        carver: [],
        chaparral: [],
        chrisCraft: [],
        formula: [],
        gradyWhite: [],
        hatterasYachts: [],
        hunterMarine: [],
        regal: [],
        seaRay: [],
        silverton: [],
        sunseeker: [],
        wellcraft: [],
      },
    },
    {
      label: 'Length',
      name: 'length',
      type: 'number',
    },
    {
      label: 'Year',
      name: 'year',
      type: 'number',
      mask: [/\d/, /\d/, /\d/, /\d/],
    },
    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'modal',
      data: titles,
    },
    {
      label: 'Hours',
      name: 'hours',
      type: 'number',
    },
    {
      label: 'Engine',
      name: 'engine',
      type: 'text',
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'modal',

      data: condition,
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
  machinery: [
    {
      label: 'Title Vehicle',
      name: 'titleVehicle',
      type: 'text',
      placeholder: 'example: 2012 ford mustang',
    },
    {
      label: 'Make',
      name: 'make',
      type: 'modal',

      isMultiSelect: false,
      data: machineryMakes,
    },
    {
      label: 'Model',
      name: 'model',
      type: 'text',
      data: {
        bmw: [],
        camAm: [],
        ducati: [],
        harleyDavidson: [],
        honda: [],
        husqvarna: [],
        indian: [],
        kawasaki: [],
        ktm: [],
        slingshot: [],
        ssrMotorsports: [],
        suzuki: [],
        triumph: [],
        vespa: [],
        yamaha: [],
      },
    },
    {
      label: 'Year',
      name: 'year',
      type: 'number',
      mask: [/\d/, /\d/, /\d/, /\d/],
    },
    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'modal',
      data: titles,
    },
    {
      label: 'Length',
      name: 'length',
      type: 'number',
    },
    {
      label: 'Hours',
      name: 'hours',
      type: 'number',
    },
    {
      label: 'Engine',
      name: 'engine',
      type: 'text',
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'modal',

      data: condition,
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
  trailer: [
    {
      label: 'Title Vehicle',
      name: 'titleVehicle',
      type: 'text',
      placeholder: 'example: 2012 ford mustang',
    },
    {
      label: 'Make',
      name: 'make',
      type: 'modal',

      isMultiSelect: false,
      data: trailerMakes,
    },
    {
      label: 'Model',
      name: 'model',
      type: 'text',
      data: {
        eastManufacturing: [],
        greatdane: [],
        hyundai: [],
        utility: [],
        vanguardNational: [],
        wabash: [],
        doepkerIndustries: [],
        fellingTrailers: [],
        fontaineTrailer: [],
        frehauf: [],
        heilTrailer: [],
        kentucky: [],
        macTrailer: [],
        manac: [],
        pitts: [],
      },
    },
    {
      label: 'Year',
      name: 'year',
      type: 'number',
      mask: [/\d/, /\d/, /\d/, /\d/],
    },
    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'modal',
      data: titles,
    },
    {
      label: 'Length',
      name: 'length',
      type: 'number',
    },
    {
      label: 'Hours',
      name: 'hours',
      type: 'number',
    },
    {
      label: 'Engine',
      name: 'engine',
      type: 'text',
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'modal',

      data: condition,
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
  part: [
    {
      label: 'Vehicle Parts',
      name: 'parts',
      type: 'modal',
      data: [
        'All',
        'Electric Parts',
        'Engine Parts',
        'Body Parts',
        'Interior Parts',
        'Accessories',
        'Other',
      ],
    },
    {
      label: 'Title Part',
      name: 'titleVehicle',
      type: 'text',
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'modal',
      data: condition,
    },
    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
  other: [
    {
      label: 'Vehicle types',
      name: 'parts',
      type: 'modal',
      data: [
        'All',
        'Bus',
        'Jet Ski',
        'Recreational Vehicle',
        'Snow Mobile',
        'Industrial',
        'Off-Road',
        'Scooter',
        'Bicycle',
        'Other',
      ],
    },
    {
      label: 'Title Vehicle',
      name: 'titleVehicle',
      type: 'text',
      placeholder: 'example: 2012 ford mustang',
    },
    {
      label: 'Condition',
      isMultiSelect: false,
      name: 'condition',
      type: 'modal',

      data: condition,
    },
    {
      label: 'Year',
      name: 'year',
      type: 'number',
      mask: [/\d/, /\d/, /\d/, /\d/],
    },
    {
      label: 'Price',
      name: 'price',
      type: 'number',
    },
    {
      label: 'Title',
      name: 'title',
      type: 'modal',
      data: titles,
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textArea',
    },
  ],
};
