import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

function Spinner({ size, color }) {
  return (
    <LoadingOutlined
      style={{
        fontSize: size,
        color,
      }}
      spin
    />
  );
}

Spinner.defaultProps = {
  size: 25,
  color: '#FFF',
};

export default Spinner;
