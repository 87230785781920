import React, { useContext, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Send } from 'react-feather';
import { Timestamp, collection, doc } from 'firebase/firestore';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';

import { Input, Spinner } from '../../common';
import { firestore } from '../../config/firebase';
import { client } from '../../config/react-query';
import { UserContext } from '../../Provider/UserProvider';
import { useSendNotification } from '../../notifications/api/mutations';

/* =============================================================================
<StoryReply />
============================================================================= */
const StoryReply = ({ story }) => {
  const storyId = story?.id;
  const { user, userId } = useContext(UserContext);
  const [text, setText] = useState('');

  // // REPLY CALL
  const repliesCollection = collection(firestore, `stories/${storyId}/replies`);
  const replyRef = useMemo(
    () => doc(repliesCollection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text],
  );
  const { mutateAsync: createStoryReplies, isLoading } = useFirestoreDocumentMutation(replyRef);
  const { mutateAsync: sendNotification } = useSendNotification();

  const _handleReply = async () => {
    try {
      if (text) {
        if (!user) {
          toast.error('Please Sign in first');
          return;
        }
        const newReply = {
          id: replyRef.id,
          text,
          author: userId,
          createdAt: Timestamp.now(),
        };
        await createStoryReplies(newReply);

        client.invalidateQueries(['story_replies', storyId]);

        sendNotification({
          to: story?.author,
          type: 'reply',
          from: userId,
        });

        setText('');
      } else {
        toast.error('Please Enter reply');
      }
    } catch (e) {
      toast.error('Something went wrong', e?.message);
    }
  };

  return (
    <div className="flex items-center">
      <Input
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Reply to this story..."
      />
      <button type="button" className="px-4 py-2" onClick={_handleReply}>
        {isLoading ? (
          <Spinner color="#f1f1f1" size={22} />
        ) : (
          <Send size={22} color="#f1f1f1" />
        )}
      </button>
    </div>
  );
};

export default StoryReply;
