import React from 'react';

import toast from 'react-hot-toast';
import { Avatar, Header } from '../../common';

/* =============================================================================
<ProfileHeader />
============================================================================= */
const ProfileHeader = ({ user, numberOfPosts }) => {
  const userType = user?.type === 'dealer' ? 'Verified Auto Dealer' : 'Verfied Private Seller';

  const userName = user?.type === 'dealer'
    ? user?.name
    : user?.name?.slice(0, user?.name?.indexOf(' '));

  if (user?.type === 'private seller') {
    return (
      <Header title="Profile" stack titleClass="xsm:text-3xl">
        <div className="flex relative right-2 xsm:right-3  items-center mb-3 justify-normal mt-10 xsm:mt-2 max-w-[965px] space-x-6 pl-7 md:pl-0 xsm:space-x-4 mx-5 xsm:mx-0 mr-auto ml-auto">
          <Avatar source={user?.avatar} size="large" name={userName} />
          <div>
            <h1 className="text-white sm:text-3xl text-semibold text-lg">
              {userName}
            </h1>
            <h2 className="text-white text-medium text-xs">
              {userType}
            </h2>
            <p className="text-white text-xs">
              {`Post published: ${numberOfPosts}`}
            </p>
          </div>
        </div>
      </Header>
    );
  }

  const _handleNumberClick = () => {
    navigator.clipboard.writeText(user?.business?.phone);
    toast.success('Number Copied');
  };

  return (
    <Header title="Profile" stack titleClass="xsm:text-3xl">
      <div className="flex items-center px-10 justify-center max-w-screen-lg mx-auto md:px-32 mt-10 space-x-3 md:space-x-6 xsm:mt-2 xsm:mr-8">
        <Avatar source={user?.avatar} size="large" name={userName} />
        <div>
          <div className="flex items-center justify-between space-x-2 md:space-x-10">
            <h1 className="text-white text-[26px] md:text-2xl text-semibold ">
              {userName}
            </h1>
          </div>
          <h2 className="text-white md:text-2xl text-medium">
            {userType}
          </h2>
          <p className="text-white md:text-xl">
            {`Address: ${user?.business?.address}`}
          </p>
          <div className="flex items-center space-x-2 md:space-x-5">
            <p className="text-white md:text-xl">
              Call:
            </p>
            <button type="button" className="border-secondaryDark border px-2 rounded-lg" onClick={_handleNumberClick}>
              <p className="text-white md:text-xl">
                {user?.business?.phone}
              </p>
            </button>
          </div>
        </div>
      </div>
    </Header>
  );
};

/* Export
============================================================================= */
export default ProfileHeader;
