import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import {
  limit,
  query,
  where,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';
import { setPostData as setPostDataAction, setQuery as setQueryAction } from '../redux/actions';
import PostListItem from '../components/PostListItem';
import { postCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';
import { Header, Container, PaginationList } from '../../common';

// import { } from '../redux/actions';

/* =============================================================================
<MyPostScreen />
============================================================================= */
const MyPostScreen = ({ setQuery, setPostData }) => {
  const history = useHistory();
  const { userId } = useContext(UserContext);

  const queryKey = ['myPosts'];
  const queryRef = query(
    postCollectionRef,
    limit(10),
    orderBy('createdAt', 'desc'),
    where('author', '==', userId),
  );

  // GET POST
  const {
    data,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    queryKey,
    queryRef,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }
      return query(queryRef, startAfter(lastDocument?.createdAt || ''));
    },
    {},
    {
      onError: (e) => {
        toast.error(e.message);
      },
    },
  );

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const posts = data?.pages
    ? data?.pages
      ?.flatMap(page => [...page])
      .sort((a, b) => Number(b?.isFeatured) - Number(a?.isFeatured))
    : [];

  const renderItem = (item, index) => (
    <PostListItem
      key={item?.id}
      post={item}
      onClick={() => {
        setPostData({ index, data: posts });
        history.push('/post/data');
        // setQuery({ index, queryKey, queryRef });
        // history.push('/post');
      }}
    />
  );

  return (
    <Container>
      <Header title="My Posts" stack />
      <PaginationList
        data={posts}
        loading={isLoading}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        emptyText="You haven't uploaded any posts yet!"
      />
    </Container>
  );
};

// const mapDispatchToProps = {
//   setQuery: setQueryAction,
// };
const mapDispatchToProps = {
  setPostData: setPostDataAction,
};
/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(MyPostScreen);
