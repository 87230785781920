import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import FavoritePostsScreen from './FavoritePostsScreen';
import FavoritePostScreen from './FavoritePostScreen';

/* =============================================================================
<FavoritePostsScreens />
============================================================================= */
function FavoritePostsScreens() {
  return (
    <>
      <Helmet>
        <title>Favorites | Carsnus</title>
      </Helmet>
      <Switch>
        <Route path="/favorites" exact>
          <FavoritePostsScreen />
        </Route>
        <Route path="/favorites/post" exact>
          <FavoritePostScreen />
        </Route>
        {/* <Route path="/settings/user-edit" exact>
          <EditProfileScreen />
        </Route> */}
        {/* <Route path="*">
          <Notfound />
        </Route> */}
      </Switch>
    </>
  );
}

/* Export
============================================================================= */
export default FavoritePostsScreens;
