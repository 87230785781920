/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { Search, X } from 'react-feather';
import Input from './Input';

/* =============================================================================
<FormDropDown />
============================================================================= */
const FormDropDown = ({
  placeholder,
  searchTxt,
  localSearch,
  onSearch,
  autoFocus,
  ...props
}) => {
  const [value, setValue] = useState(searchTxt);

  useEffect(() => {
    setValue(searchTxt);
  }, [searchTxt]);

  const _handleSearch = (e) => {
    e?.preventDefault();
    if (value?.length >= 3 && typeof onSearch === 'function') {
      onSearch(value);
    }
  };

  return (
    <form onSubmit={_handleSearch} className="w-full">
      <Input
        variant="search"
        iconBtnType="submit"
        placeholder={placeholder || 'Find any Vehicle'}
        customStyleClasses="!px-12"
        leftIcon={<Search color="#fff" size={18} />}
        rightIcon={(value || searchTxt) && <X color="#fff" size={18} />}
        value={localSearch ? searchTxt : value}
        onChange={(e) => (localSearch ? onSearch(e) : setValue(e.target.value))}
        onIconClick={_handleSearch}
        onRightIconClick={() => setValue('')}
        autoFocus={autoFocus}
        {...props}
      />
    </form>
  );
};

/* Export
============================================================================= */
export default FormDropDown;
