import {
  query,
  getDocs,
  orderBy,
  collection,
  updateDoc,
  doc as firestoreDoc,
} from 'firebase/firestore';
import { auth, firestore } from '../../config/firebase';

export const getMessages = async (conversationId, cb) => {
  const messages = [];
  let unseenCount = 0;
  try {
    const messagesCollection = collection(
      firestore,
      `conversations/${conversationId}/messages`,
    );
    const getMessageRef = query(messagesCollection, orderBy('createdAt', 'desc'));

    const snapshot = await getDocs(getMessageRef);

    await Promise.all(snapshot.forEach(async (doc) => {
      const data = doc.data();
      const userId = auth.currentUser?.uid;

      if (!data.system) {
        messages.push({
          type: 'text',
          text: data?.text,
          date: data?.createdAt,
          position: data?.author === userId ? 'right' : 'left',
        });
        if (!data.mark_as_seen && data?.author !== userId) {
          unseenCount += 1;
          const messageRef = firestoreDoc(messagesCollection, data?._id);

          await updateDoc(messageRef, { mark_as_seen: true });
        }
      }
    }));
  } catch (e) {
    // toast.error(e?.message);
  }
  return cb(messages, unseenCount);
};
