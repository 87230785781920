import React, { useRef, useMemo } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase'; // Your Firebase config file
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS

// Dynamically import ReactQuill to avoid SSR issues
const ReactQuill = React.lazy(() => import('react-quill'));

const MyEditor = ({ content, onChange, ...props }) => {
  const quillRef = useRef(null);

  // Image upload handler for Firebase Storage
  const imgHandler = () => {
    const quill = quillRef.current.getEditor(); // Access Quill instance
    let fileInput = quill.root.querySelector('input.ql-image[type=file]');

    if (fileInput === null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute(
        'accept',
        'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
      );
      fileInput.classList.add('ql-image');

      // Handle file upload when file input changes
      fileInput.addEventListener('change', async () => {
        const { files } = fileInput;
        const range = quill.getSelection(true);

        if (!files || !files.length) {
          console.log('No files selected');
          return;
        }

        // Upload the image to Firebase Storage
        const storageRef = ref(storage, `blogs/images/${files[0].name}`);
        await uploadBytes(storageRef, files[0]);
        const url = await getDownloadURL(storageRef);

        // Insert the image into the editor at the cursor's position
        quill.insertEmbed(range.index, 'image', url);
      });
    }

    // Trigger file selection dialog
    fileInput.click();
  };

  // Quill modules configuration
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          ['clean'],
          [{ table: [] }],
        ],
        handlers: {
          image: imgHandler, // Custom image handler for Firebase upload
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [],
  );

  return (
    <React.Suspense fallback={<div>Loading editor...</div>}>
      <ReactQuill
        ref={quillRef}
        modules={modules}
        value={content}
        onChange={onChange}
        {...props}
      />
    </React.Suspense>
  );
};

export default MyEditor;
