import React from 'react';
import { Controller } from 'react-hook-form';
import { Info } from 'react-feather';

import ImageUploadImageCard from './ImageUploadImageCard';

/* =============================================================================
<ImageMuiltipleUpload />
============================================================================= */
const ImageMuiltipleUpload = ({
  name, userName, errorText, control,
}) => (
  <Controller
    control={control}
    render={({ field: { onChange, value } }) => (
      <div className="flex justify-center items-center ">
        <ul
          className="flex space-x-2"
        >
          <ImageUploadImageCard
            value={value}
            name={userName}
            onChange={onChange}
          />
        </ul>
        {!!errorText && (
        <div className="flex items-center space-x-1 mt-2">
          <Info color="#dc2626" size={20} />
          <p className="text-md font-medium  py-white text-red-500">
            {errorText}
          </p>
        </div>
        )}
      </div>
    )}
    name={name}
  />
);
// const styles = StyleSheet.create({
//   list: {
//     height: 120,
//     marginBottom: 10,
//     width: window.width,
//     marginHorizontal: -22,
//   },

//   content: {
//     flexGrow: 1,
//     paddingHorizontal: 20,
//   },
// });

export default ImageMuiltipleUpload;
