import React, { useEffect, useState } from 'react';
import {
  limit,
  query,
  where,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import {
  Container, Header, PaginationList, SearchInput,
} from '../../common';
import ConversationCard from '../components/ConversationCard';
import { auth, conversationCollectionRef } from '../../config/firebase';

/* =============================================================================
<ChatsScreen />
============================================================================= */
const ChatsScreen = () => {
  const currentUserId = auth?.currentUser?.uid || 'none';
  const [searchTxt, setSearchTxt] = useState('');

  // GETTING CONVERSATIONS WITH PAGINATION
  const ref = query(
    conversationCollectionRef,
    limit(10),
    orderBy('createdAt', 'desc'),
    where('members', 'array-contains-any', [currentUserId]),
  );
  const {
    data,
    refetch,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    ['conversations', currentUserId],
    ref,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(ref, startAfter(lastDocument?.createdAt || ''));
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flattenData = data?.pages
    ? data?.pages?.flatMap(page => [...page])
    : [];

  const filteredData = searchTxt
    ? flattenData?.filter(c => c?.title?.toLowerCase()?.includes(searchTxt?.toLowerCase()))
    : flattenData;

  return (
    <Container>
      <Header title="Chats" stack>
        <div className="max-w-screen-md mx-auto mt-10 mx-10 xsm:mt-5">
          <SearchInput
            localSearch
            searchTxt={searchTxt}
            onSearch={(e) => setSearchTxt(e.target.value)}
            placeholder="Find Conversations"
          />
        </div>
      </Header>
      <PaginationList
        loading={isLoading}
        data={filteredData}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        emptyText="No Conversations Found"
        isFetchingNextPage={isFetchingNextPage}
      />
    </Container>
  );
};

const renderItem = (item) => (
  <ConversationCard key={item.id} conversation={item} />
);

/* Export
============================================================================= */
export default ChatsScreen;
