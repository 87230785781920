import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAuthUpdateProfile,
  useAuthUpdatePassword,
} from '@react-query-firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

import { Button, ImageUpload, Input } from '../../common';
import { auth, storage } from '../../config/firebase';
import { client } from '../../config/react-query';
import { getBlobFroUri } from '../../util/functions';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<EditSellerProfileForm />
============================================================================= */
const EditSellerProfileForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user, updateUser, userId } = useContext(UserContext);
  const { mutateAsync: updateProfile } = useAuthUpdateProfile();
  const { mutateAsync: updatePassword } = useAuthUpdatePassword();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      avatar: user?.avatar,
      name: user?.name,
      phone: user?.phone,
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(EditSellerForm),
  });

  const onSubmit = async values => {
    try {
      setLoading(true);
      const {
        avatar, name, phone, newPassword,
      } = values;

      const changes = {};

      if (avatar !== user?.avatar) {
        const storageRef = ref(storage, `profile_images/${name}`);

        const imageBlob = await getBlobFroUri(avatar);

        const snapshot = await uploadBytesResumable(storageRef, imageBlob);

        const url = await getDownloadURL(snapshot.ref);

        await updateProfile({
          user: auth?.currentUser,
          photoURL: url,
        });

        changes.avatar = url;
      }
      if (newPassword) {
        await updatePassword({
          user: auth?.currentUser,
          newPassword,
        });
      }

      if (name !== user?.name) {
        changes.name = name;
      }
      if (phone !== user?.phone) {
        changes.phone = phone;
      }

      await updateUser({
        ...changes,
        updatedAt: Timestamp.now(),
      });

      history.goBack();
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-screen-sm mx-5 md:mx-auto py-4 space-y-5">
      <ImageUpload control={control} errorText={errors?.avatar?.message} name="avatar" userName={user?.name} />
      <Input label="Full Name" formProps={register('name')} errorText={errors.name?.message} />
      <Input label="Phone" formProps={register('phone')} errorText={errors.phone?.message} />
      <Input
        type="password"
        label="New Password"
        formProps={register('newPassword')}
        errorText={errors?.newPassword?.message}
      />
      <Input
        type="password"
        label="Confirm New Password"
        formProps={register('confirmNewPassword')}
        errorText={errors?.confirmNewPassword?.message}
      />
      <Button
        title="Edit"
        type="submit"
        size="block"
        loading={loading}
      />
    </form>
  );
};

const EditSellerForm = yup.object({
  avatar: yup.string().required('Please add your profile image'),
  name: yup.string().required('Please add your full name'),
  phone: yup.string().required('Please enter phone number'),
  newPassword: yup.string(),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

/* Export
============================================================================= */
export default EditSellerProfileForm;
