import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { firestore } from '../../config/firebase';
import { Table } from '../../common/Table/Table';
import { Tooltip } from '../../common/ToolTip';

const Posts = () => {
  const columns = [
    { key: 'id', title: 'Post ID', dbColName: 'id' },
    { key: 'title', title: 'Title', dbColName: 'title' },
    {
      key: 'description',
      title: 'Description',
      dbColName: 'description',
      render: (description) => (
        <Tooltip message={description}>
          {description && description?.toString()?.slice(0, 40)}
        </Tooltip>
      ),
    },
    { key: 'by', title: 'Posted By', dbColName: 'authorName' },
    {
      key: 'featured',
      title: 'Featured',
      dbColName: 'isFeatured',
      render: (isFeatured) => <span>{isFeatured ? 'Yes' : 'No'}</span>,
    },
    {
      key: 'top',
      title: 'Make Top',
      render: (values) => (values.isFeatured ? (
        <button
          type="button"
          onClick={() => bringPost(values.id, values.isFeatured)}
          className="bg-primary focus:outline text-white px-3 py-2 rounded-lg"
        >
          Remove
        </button>
      ) : (
        <button
          type="button"
          onClick={() => bringPost(values.id, values.isFeatured)}
          className="focus:outline-none bg-green-500 text-white text-sm rounded-lg px-3 py-2 shadow-sm"
        >
          Bring top list
        </button>
      )),
    },
    { key: 'transmission', title: 'Transmission', dbColName: 'transmission' },
    {
      key: 'actions',
      title: 'Actions',
      render: (values) => (
        <button type="button" onClick={() => handleDelete(values.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
      ),
    },
  ];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchAllUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      const usersMap = {};
      usersSnapshot.forEach((d) => {
        usersMap[d.id] = { id: d.id, ...d.data() };
      });
      return usersMap;
    } catch (error) {
      console.error('Error fetching all users:', error);
      return {};
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const usersMap = await fetchAllUsers();
      const q = query(collection(firestore, 'posts'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const postsArray = [];

        querySnapshot.forEach((d) => {
          const postData = {
            id: d.id,
            ...d.data(),
          };
          const authorData = usersMap[postData.author];
          postsArray.push({
            ...postData,
            authorName: authorData?.name,
          });
        });

        setData(
          postsArray.map((item, index) => {
            const postId = (index + 1).toString().padStart(6, '0');
            return { ...item, id: postId };
          }),
        );
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete the post?')) {
      // Save it!
      await deleteDoc(doc(firestore, 'posts', id));
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  };

  const bringPost = async (id, isFeatured) => {
    await updateDoc(doc(firestore, 'posts', id), {
      isFeatured: !isFeatured,
    });
  };

  return (
    <div>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Posts
        </h1>

        <p className="text-gray-500 text-sm">View all posts</p>
      </div>

      <Table
        data={data}
        columns={columns}
        totalItems={data?.length}
        loadingData={loading}
      />
    </div>
  );
};

export default Posts;
