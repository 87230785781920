import React, { useContext } from 'react';
import {
  limit,
  query,
  orderBy,
  collection,
  startAfter,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import { Header, Container, PaginationList } from '../../common';
import { firestore } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';
import PaymentHistoryItem from '../components/PaymentHistoryItem';

/* =============================================================================
<PaymentHistoryScreen />
============================================================================= */
const PaymentHistoryScreen = () => {
  const { userId } = useContext(UserContext);

  // GETTING PaymentLog WITH PAGINATION
  const paymentHistoryRef = collection(
    firestore,
    `paymentLog/${userId}/history`,
  );
  const ref = query(paymentHistoryRef, limit(10), orderBy('createdAt', 'desc'));
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    ['paymentLogHistory', userId],
    ref,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(ref, startAfter(lastDocument?.createdAt || ''));
    },
  );

  const flattenData = data?.pages
    ? data?.pages?.flatMap(page => [...page])
    : [];

  return (
    <Container>
      <Header title="Payment History" stack />
      <PaginationList
        data={flattenData}
        loading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        emptyText="No Payment History Found"
        renderItem={renderItem}
      />
    </Container>
  );
};

const renderItem = (item) => <PaymentHistoryItem item={item} />;

/* Export
============================================================================= */
export default PaymentHistoryScreen;
