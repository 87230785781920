import React, { useContext, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Trash } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { doc, Timestamp } from 'firebase/firestore';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';

import { AlertModal, Spinner } from '../../common';
import { postCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<FavoritePostCard />
============================================================================= */
const FavoritePostCard = ({ id, onClick }) => {
  const [confirmationModal, setConfirmationModal] = useState(false);

  // GET CURRENT USER
  const { user, loading, updateUser } = useContext(UserContext);

  // GET POST
  const postRef = doc(postCollectionRef, id);
  const { data: post } = useFirestoreDocumentData(['posts', id], postRef);

  const _toggleConfirmationModal = () => setConfirmationModal(prev => !prev);

  const _handleDelete = async () => {
    try {
      updateUser({
        favoritePosts: user?.favoritePosts?.filter(f => f !== id),
        updatedAt: Timestamp.now(),
      });
    } catch (e) {
      toast.error(e?.message);
    }
  };

  if (!post) {
    return (
      <div className="flex w-full items-center justify-center space-x-6 bg-lightBlack pl-4 rounded-2xl pr-4 py-14">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row w-full items-center md:space-x-6 bg-lightBlack pl-4 rounded-2xl pr-4 py-2">
      <button type="button" onClick={onClick}>
        <img src={post?.photos[0]} className="w-full md:w-48 h-48 rounded-lg" alt={post?.id} />
      </button>
      <div className="w-full py-5 md:py-0 flex-1 flex justify-between items-center">
        <button type="button" onClick={onClick} className="flex-1 text-left flex flex-col justify-between space-y-4">
          <h1 className="text-white text-2xl font-semibold">
            {`${post.year} ${post.make} ${post.model}`}
          </h1>
          <p className="text-white text-lg">
            {moment(post?.createdAt.toDate()).format('L')}
          </p>
        </button>
        <div className="flex flex-col justify-between items-end space-y-4">
          <h2 className="text-white text-2xl font-bold">
            {`$ ${post?.price}`}
          </h2>
          <button
            type="button"
            className="border border-white p-2 rounded-md hover:bg-lightWhite"
            onClick={_toggleConfirmationModal}
          >
            <Trash color="#F5F5F5" size={18} />
          </button>
        </div>
      </div>
      <AlertModal
        heading="Alert"
        loading={loading}
        onSubmit={_handleDelete}
        visible={confirmationModal}
        onClose={_toggleConfirmationModal}
      >
        <h1 className="text-white text-center text-lg">
          Are you sure you want to delete this post from favorites
        </h1>
      </AlertModal>
    </div>
  );
};

/* Export
============================================================================= */
export default FavoritePostCard;
