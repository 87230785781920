import React, { useState } from 'react';
import { Check } from 'react-feather';
import toast from 'react-hot-toast';
import { doc } from 'firebase/firestore';
import { useParams, useHistory } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';

import { client } from '../../config/react-query';
import { useCreatePayment } from '../api/mutations';
import PaymentModal from '../components/PaymentModal';
import { postCollectionRef } from '../../config/firebase';
import { featuredPackages } from '../../static/payments-data';
import { Container, Header, Spinner } from '../../common';
import * as Colors from '../../config/colors';

/* =============================================================================
<PostFeatureScreen />
============================================================================= */
const PostFeatureScreen = () => {
  const { id } = useParams();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [loading, setLoading] = useState(false);

  const postRef = doc(postCollectionRef, id);
  const { data: post, isLoading } = useFirestoreDocumentData(
    ['post', id],
    postRef,
  );

  // UPDATE POST CALL
  const { mutateAsync: updatePost } = useFirestoreDocumentMutation(postRef, {
    merge: true,
  });
  const { mutateAsync: createdPackagePayment } = useCreatePayment();

  const _togglePaymentModal = () => setPaymentModal(prev => !prev);
  const _handlePackageSelect = _package => {
    _togglePaymentModal();
    setSelectedPackage(_package);
  };

  const _handlePayment = async (name) => {
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setLoading(true);

      const { clientSecret } = await createdPackagePayment(
        {
          priceId: selectedPackage?.priceId,
          type: 'featured',
        },
        () => {
          toast.error('Payment Successfull');
          _togglePaymentModal();
        },
      );

      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
          },
        },
      });

      if (error) {
        throw new Error(error?.localizedMessage);
      }

      if (paymentIntent.status === 'succeeded') {
        await updatePost({
          isFeatured: true,
          featuredPackage: {
            packageId: selectedPackage?.priceId,
            days: selectedPackage?.days,
          },
        });
        toast.success(
          `Your post is featured for ${selectedPackage?.days}`,
        );

        client.invalidateQueries(['posts']);
        client.invalidateQueries(['myPosts']);

        _togglePaymentModal();

        history.goBack();
      }
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header title="Payment" stack />
      <div className="max-w-6xl mx-auto space-y-5 my-10 px-4 sm:px-6 lg:px-8 xsm:my-6">
        <div className="flex items-center space-x-5 bg-lightBlack p-2 border border-secondaryDark rounded-xl md:mx-40">
          {isLoading ? (
            <div className="flex items-center justify-center py-6">
              <div>
                <Spinner />
              </div>
            </div>
          ) : (
            <>
              <img src={post?.photos[0]} alt="post-feature" className="w-48 h-48 rounded-xl" />
              <h1 className="flex-1 text-white text-xl">
                You will be Promoting this vehicle
              </h1>
            </>
          )}
        </div>

        <div className="pt-10 xsm:pt-5">
          <h2 className="text-white text-2xl">
            Select Package
          </h2>
          <div className="flex items-center justify-between space-x-10 py-6 px-16">
            {featuredPackages?.map(_package => (
              <button
                type="button"
                key={_package?.priceId}
                className="flex-1 border border-secondaryDark overflow-hidden bg-gradiantColor3 pb-2 rounded-lg hover:bg-gray-800"
                onClick={() => _handlePackageSelect(_package)}
              >
                <div className="mb-2 bg-secondaryDark">
                  <h1 className="text-center text-lg text-white">
                    {_package.title}
                  </h1>
                </div>
                <p className="text-center text-lg text-white">
                  {_package.description}
                </p>
              </button>
            ))}
          </div>
        </div>
        <div className="space-y-10">
          <h1 className="text-white text-xl">
            How Top List Works
          </h1>
          <div className="flex items-center space-x-5 ml-10">
            <Check color={Colors.secondary} size={30} />
            <p className="text-white text-lg font-medium">
              Get on top of Listing
            </p>
          </div>
          <div className="flex items-center space-x-5 ml-10">
            <Check color={Colors.secondary} size={30} />
            <p className="text-white text-lg font-medium">
              Appear in feature list
            </p>
          </div>
          <div className="flex items-center space-x-5 ml-10">
            <Check color={Colors.secondary} size={30} />
            <p className="text-white text-lg font-medium">
              Get more expositions
            </p>
          </div>
          <div className="flex items-center space-x-5 ml-10">
            <Check color={Colors.secondary} size={30} />
            <p className="text-white text-lg font-medium">
              Sell Faster
            </p>
          </div>
        </div>
        <PaymentModal
          isVisible={paymentModal}
          title={selectedPackage?.title}
          loading={loading}
          onCancel={_togglePaymentModal}
          onSubmit={_handlePayment}
        />
      </div>
    </Container>
  );
};

/* Export
============================================================================= */
export default PostFeatureScreen;
