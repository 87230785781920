import React, { useEffect, useState } from 'react';

import Login from './Login';
import Modal from '../Modal';
import Welcome from './Welcome';
import Register from './Register';
import DealerRegister from './DealerRegister';

/* =============================================================================
<AuthModal />
============================================================================= */
const AuthModal = ({ page, onPageOpen }) => {
  const [model, setModal] = useState(!!page);

  const _toggleModal = () => setModal(prev => !prev);

  useEffect(() => {
    if (page) {
      setModal(!!page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const description = page === 'Dealer Register' ? 'You will register as dealer'
    : page === 'WELCOME TO CARSNUS' ? 'The trusted community of buyers and sellers' : null;

  const _handleClose = () => {
    setModal(false);

    setTimeout(() => {
      onPageOpen(null);
    }, 600);
  };

  const _handlePageSelect = (_page) => {
    _toggleModal();

    setTimeout(() => {
      onPageOpen(_page);
    }, 600);
  };

  return (
    <Modal
      visible={model}
      heading={page}
      onClose={_handleClose}
      closeOnBackPress={false}
      varient={page === 'WELCOME TO CARSNUS' ? 'secondary' : 'primary'}
      description={description}
    >
      {page === 'WELCOME TO CARSNUS' && <Welcome onPageChange={_handlePageSelect} onClose={_handleClose} />}
      {page === 'Sign in' && <Login modal={model} onPageChange={_handlePageSelect} onClose={_handleClose} />}
      {page === 'Register' && <Register modal={model} onPageChange={_handlePageSelect} onClose={_handleClose} />}
      {page === 'Dealer Register' && <DealerRegister modal={model} onPageChange={_handlePageSelect} onClose={_handleClose} />}
    </Modal>
  );
};

/* Export
============================================================================= */
export default AuthModal;
