import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckSquare, Info, ChevronDown } from 'react-feather';

import EmptyState from './EmptyState';
import { classNames } from '../util/functions';

function DropDown({
  label, items, name, isMultiSelect, emptyIcon, errorText, emptyTxt, value, onChange,
  customContainerStyles,
  smallDropDown,
}) {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false); // Control the open status

  const filteredItems = query === ''
    ? items
    : items?.filter((item) => item?.toLowerCase().includes(query.toLowerCase()));

  const _handleChange = (selectedItem) => {
    if (isMultiSelect) {
      if (value?.find((i) => i === selectedItem)) {
        onChange(value?.filter((i) => i !== selectedItem));
      } else {
        onChange([...(value || []), selectedItem]);
      }
      setIsOpen(true); // Keep Combobox open after selection
    } else {
      onChange(selectedItem);
      setIsOpen(false); // Close Combobox in single selection mode
      if (!query) {
        setQuery(selectedItem);
      }
    }
  };

  return (
    <Combobox
      as="div"
      id={name}
      name={name}
      value={value && isMultiSelect ? value.join(', ') : value || query}
      onChange={_handleChange}
      className={customContainerStyles}
    >
      <Combobox.Label className="block text-lg font-medium text-white">
        {label}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Button className="w-full">
          <Combobox.Input
            data-disabled
            onFocus={() => setIsOpen(true)} // Open when input is focused
            className={`appearance-none block w-full px-3 py-2.5 text-white bg-gradient-to-b from-gray-900 to-black rounded-full shadow-sm placeholder-white focus:outline-none border focus:border-primary focus:ring-primary ${(errorText || Array.isArray(value) ? value.length > 0 : value) ? 'border-primary' : 'border-white'}`}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={isMultiSelect ? value?.join(', ') : query}
            autoComplete="off"
          />
        </Combobox.Button>
        <Combobox.Button className="absolute flex right-0 top-1 bottom-0 items-center mb-1 rounded-r-md px-4 focus:outline-none">
          <ChevronDown aria-hidden="true" color="#f1f1f1" />
        </Combobox.Button>

        {filteredItems?.length > 0 ? (
          <Combobox.Options
            className={classNames('absolute z-10 mt-1 w-full overflow-auto rounded-xl bg-black border border-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none', smallDropDown ? 'max-h-20' : 'max-h-60')}
          >
            {filteredItems?.map((item) => (
              <Combobox.Option
                open={isOpen}
                key={item.key || item?.id}
                value={item}
                className={({ active }) => classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? 'bg-priamry-500 text-white' : 'text-white',
                )}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block',
                        selected && 'font-semibold',
                      )}
                    >
                      {item}
                    </span>

                    {isMultiSelect ? (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary',
                        )}
                      >
                        <input type="checkbox" name={item?.id} className="text-red-600 border-gray-100 rounded focus:ring-red-500" checked={value?.find((i) => i === item)} />
                      </span>
                    ) : (
                      <>
                        {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-primary',
                          )}
                        >
                          <CheckSquare size={22} color="#999" />
                        </span>
                        )}
                      </>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        ) : (
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-black border border-white  py-1 text-base shadow-lg ring-1  ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="p-5">

              <EmptyState
                icon={emptyIcon || <Info size={22} color="#999" />}
                title={emptyTxt || 'No results'}
              />
            </div>
          </Combobox.Options>
        )}
      </div>
      {!!errorText && (
        <div className="flex items-center space-x-1 mt-2">
          <Info color="#dc2626" size={20} />
          <p className="text-md font-medium  py-white text-red-500">
            {errorText}
          </p>
        </div>
      )}
    </Combobox>
  );
}

export default DropDown;
