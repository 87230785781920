import React from 'react';
import { Link } from 'react-router-dom';
import categoriesData from '../../static/categories-data';

export const Categories = () => (
  <div className="mx-auto container py-6 sm:py-10 px-4 xl:px-0">
    <h2 className="text-2xl md:text-3xl text-gray-800 mb-8 font-semibold ">
      Vehicles for Sale
    </h2>
    <div className="container pb-4  mx-auto flex overflow-x-auto md:grid md:grid-cols-3 lg:grid-cols-4 gap-4">
      {categoriesData.map(item => (
        <Link key={item.id} to={`/category/${item.id}`} className="cursor-pointer border hover:shadow-lg border-zinc-200 rounded-xl  p-4">
          <p className="text-sm md:text-lg font-semibold text-zinc-800">{item.title}</p>
          <div className="w-32 md:w-full h-12 md:h-32 mt-2 md:mt-0">
            <img alt="" className="h-full w-full object-contain" src={item.image} />
          </div>
        </Link>
      ))}

      {/* <Link to="/" className="cursor-pointer
       border hover:shadow-lg border-zinc-200 rounded-xl  p-4">
        <p className="text-sm whitespace-nowrap md:text-lg font-semibold  text-zinc-800">
          Heavy Duty Trucks
        </p>
        <div className="w-32 md:w-full h-12 md:h-32 mt-2 md:mt-0">
          <img alt="" className="h-full w-full object-contain" src="/truck-removebg-preview.png" />
        </div>
      </Link>

      <Link to="/" className="cursor-pointer border
       hover:shadow-lg border-zinc-200 rounded-xl  p-4">
        <p className="text-sm md:text-lg font-semibold text-zinc-800">Motorcycles</p>
        <div className="w-32 md:w-full h-12 md:h-32 mt-2 md:mt-0">
          <img alt="" className="h-full w-full object-contain" src="/bike.png" />
        </div>
      </Link>

      <Link to="/" className="cursor-pointer border
      hover:shadow-lg border-zinc-200 rounded-xl  p-4">
        <p className="text-sm md:text-lg font-semibold text-zinc-800">Boats</p>
        <div className="w-32 md:w-full h-auto md:h-32 mt-2 md:mt-0">
          <img alt="" className="h-full w-full object-contain" src="/boat.png" />
        </div>
      </Link>

      <Link to="/" className="cursor-pointer border
      hover:shadow-lg border-zinc-200 rounded-xl  p-4">
        <p className="text-sm md:text-lg font-semibold text-zinc-800">Trailers</p>
        <div className="w-32 md:w-full h-12 md:h-32 mt-2 md:mt-0">
          <img alt="" className="h-full w-full object-contain" src="/pngimg.png" />
        </div>
      </Link>

      <Link to="/" className="cursor-pointer border
      hover:shadow-lg border-zinc-200 rounded-xl  p-4">
        <p className="text-sm md:text-lg font-semibold text-zinc-800">Machinery</p>
        <div className="w-32 md:w-full h-12 md:h-32 mt-2 md:mt-0">
          <img alt="" className="h-full w-full object-contain" src="/engine.png" />
        </div>
      </Link>
      <Link to="/" className="cursor-pointer border
      hover:shadow-lg border-zinc-200 rounded-xl  p-4">
        <p className="text-sm md:text-lg font-semibold text-zinc-800">Other Vehicles</p>
        <div className="w-32 md:w-full h-12 md:h-32 mt-2 md:mt-0">
          <img alt="" className="h-full w-full object-contain" src="/image.png" />
        </div>
      </Link>
      <Link to="/" className="cursor-pointer border
      hover:shadow-lg border-zinc-200 rounded-xl  p-4">
        <p className="text-sm md:text-lg font-semibold text-zinc-800">Parts</p>
        <div className="w-32 md:w-full h-12 md:h-32 mt-2 md:mt-0">
          <img alt="" className="h-full w-full object-contain" src="/parts-removebg-preview.png" />
        </div>
      </Link> */}
    </div>
  </div>
);
