import React from 'react';
import { classNames } from '../util/functions';

function TabsSwitch({
  tabs,
  left,
  isActive,
  onChange,
  contentStyles,
}) {
  return (
    <div className={classNames('flex items-center justify-center space-x-2', contentStyles)}>
      <div className="flex items-center justify-center bg-gray-600 rounded-full">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            type="button"
            className={classNames(
              isActive === tab.name
                ? 'bg-secondaryDark rounded-full text-white'
                : 'border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap  py-4 px-10 font-[Inter] font-medium text-sm',
            )}
            aria-current={isActive === tab.name ? 'page' : undefined}
            onClick={() => onChange(tab.name)}
          >
            <p className="text-lg">{tab.name}</p>
          </button>
        ))}
      </div>
    </div>
  );
}

export default TabsSwitch;
