import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Search } from 'react-feather';

import toast from 'react-hot-toast';
import { Input } from '../../common';
import storyTopics from '../../static/story-topics';
import { classNames } from '../../util/functions';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<StoriesListHeader />
============================================================================= */
const StoriesListHeader = ({ selectedTopic, onTopicSelect }) => {
  const { user } = useContext(UserContext);
  const navigation = useHistory();

  const _handleInputPress = () => {
    if (user) {
      navigation.push('/stories/create');
    } else {
      toast.error('Please Sign in to post a story');
    }
  };

  return (
    <div className="space-y-5">
      <button type="button" className="w-full" onClick={_handleInputPress}>
        <Input placeholder="What's on your mind?" icon={<Search color="#fff" size={22} />} />
      </button>
      <div className="space-y-3">
        <h1 className="text-white text-2xl font-semibold xsm:text-xl">Topics</h1>
        <div className="flex items-center space-x-2 pt-1 pb-3 md:space-x-5 overflow-x-auto">
          <button
            type="button"
            className={classNames(
              'px-5 py-1 border border-transparent rounded-2xl bg-gradient-to-b from-gradiantColor1 to-gradiantColor2',
              selectedTopic === 'All' && 'border-white',
            )}
            onClick={() => onTopicSelect('All')}
          >
            <p className="text-md text-white">All</p>
          </button>
          {storyTopics.map(topic => (
            <button
              type="button"
              key={topic}
              className={classNames(
                'px-5 py-1 border border-transparent rounded-2xl bg-gradient-to-b from-gradiantColor1 to-gradiantColor2',
                selectedTopic === topic && 'border-white',
              )}
              onClick={() => onTopicSelect(topic)}
            >
              <p className="text-md text-white whitespace-nowrap">{topic}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default StoriesListHeader;
