/* eslint-disable max-len */
import React, { Fragment, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { Avatar } from '../../../common';
import { classNames } from '../../../util/functions';
import { UserContext } from '../../../Provider/UserProvider';

function ProfileDropDown() {
  const { user } = useContext(UserContext);
  const history = useHistory();
  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <div>
        <Menu.Button
          onClick={() => history.push('/settings')}
          className="flex max-w-sm items-center rounded-full bg-white text-sm"
        >
          <Avatar
            size="small"
            name={user?.name}
            source={user?.avatar}
          />
        </Menu.Button>
      </div>
    </Menu>
  );
}

/* Export
============================================================================= */
export default ProfileDropDown;
