/* eslint-disable no-console */
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { collection, getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebase = initializeApp({
  apiKey: 'AIzaSyCMwbVA9IpxIge_nKCoSmzZ70KabQPSh5Q',
  authDomain: 'carsnus-1262c.firebaseapp.com',
  projectId: 'carsnus-1262c',
  storageBucket: 'carsnus-1262c.appspot.com',
  messagingSenderId: '1009521238443',
  appId: '1:1009521238443:web:5c5c00f864f99733bbf2ee',
});

export const auth = getAuth(firebase);

export const storage = getStorage(firebase);

export const firestore = getFirestore(firebase);

export const userCollectionRef = collection(firestore, 'users');
export const postCollectionRef = collection(firestore, 'posts');
export const storiesCollectionRef = collection(firestore, 'stories');
export const conversationCollectionRef = collection(firestore, 'conversations');
export const notificationsCollectionRef = collection(
  firestore,
  'notifications',
);
export const savedAlertsCollectionRef = collection(firestore, 'savedAlerts');
export const paymentLogCollectionRef = collection(firestore, 'paymentLog');
