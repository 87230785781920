/* eslint-disable camelcase */
import React, { useState } from 'react';
import { MapPin } from 'react-feather';

import toast from 'react-hot-toast';
import Select from 'react-select';
import Modal from './Modal';
import Input from './Input';
import Button from './Button';
import DropDown from './DropDown';
import { classNames, getLocation, saveData } from '../util/functions';
import { useLocation } from '../Provider/LocationProvider';
import ReactSelect from './ReactSelect';

const distanceData = ['25 m.', '50 m.', '100 m.', '500 m.'];

/* =============================================================================
<LocationButton />
============================================================================= */
const LocationButton = ({
  value, showDistance, onChange, locTitleStyle,
}) => {
  const [code, setCode] = useState('');
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { location, saveLocation } = useLocation();

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const _handleApply = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!code) {
        toast.error('Enter code');
        return;
      }
      const result = await getLocation(code);
      if (result && distance) {
        const loc = {
          ...result,
          radius: distance,
        };
        onChange(loc);
        await saveLocation(loc);
      } else {
        onChange(result);
      }

      toggleModal();
    } catch (err) {
      console.log('🚀 ~ const_handleApply= ~ err:', err);
      if (err?.message === 'Geocoding request failed: Geocoding failed: undefined. Server returned status code ZERO_RESULTS.') {
        toast.error('Zip code not found');
      } else {
        toast.error(err?.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const checkZipCode = () => {
    if (location?.filteredAddress) {
      if (location?.radius) {
        return `${location?.filteredAddress} - ${location?.radius} `;
      }
      return location?.filteredAddress;
    }
    return 'Location';
  };

  return (
    <div>
      <button type="button" onClick={toggleModal} className="flex space-x-2">
        <p className={classNames('text-white', locTitleStyle)}>{checkZipCode()}</p>
        <MapPin color="#fff" size={28} />
      </button>
      <Modal
        heading="Location"
        visible={modalVisible}
        onClose={toggleModal}
        customStyles="overflow-visible"
      >
        <div className="flex flex-col  justify-between space-y-5">
          <Input
            isFocused
            value={code}
            type="number"
            label="Zip Code"
            placeholder="Enter zip code"
            onChange={(e) => setCode(e.target.value)}
          />
          {showDistance && (
            <ReactSelect
              label="Distance"
              value={distance}
              items={distanceData}
              onChange={setDistance}
            />
          // <Select isSearchable={false} options={distanceData} />
          // <DropDown
          //   label="Distance"
          //   smallDropDown
          //   value={distance}
          //   items={distanceData}
          //   onChange={setDistance}
          // />
          )}
          <Button title="Apply" size="block" loading={loading} onClick={_handleApply} />
        </div>
      </Modal>
    </div>
  );
};

/* Export
============================================================================= */
export default LocationButton;
