/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import { useFirestoreInfiniteQueryData, useFirestoreQueryData } from '@react-query-firebase/firestore';
import {
  where,
  limit,
  query,
  orderBy,
  startAfter,
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Header,
  Spinner,
  Container,
  PhotosCarousel,
  PaginationList,
} from '../../common';
import { firestore, storiesCollectionRef } from '../../config/firebase';
import StoryReply from '../components/StoryReply';
import StoryAuthorInfo from '../components/StoryAuthorInfo';
import StoryLikeButton from '../components/StoryLikeButton';
import StoryReplyCard from '../components/StoryReplyCard';

/* =============================================================================
<StoryScreen />
============================================================================= */
const StoryScreen = () => {
  const { id } = useParams();
  const [story, setStory] = useState(null);

  // GETTING STORY
  const storyDoc = doc(storiesCollectionRef, id);

  useEffect(() => {
    onSnapshot(storyDoc, async (_storyDoc) => {
      setStory(_storyDoc.data());
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // GETTING STORY REPLIES
  const repliesCollection = collection(firestore, `stories/${id}/replies`);
  const repliesQuery = query(
    repliesCollection,
    orderBy('createdAt', 'desc'),
    limit(10),
  );
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    ['story_replies', id],
    repliesQuery,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(repliesQuery, startAfter(lastDocument?.createdAt || ''));
    },
  );

  const flattenData = data?.pages
    ? data?.pages?.flatMap(page => [...page])?.filter(reply => !reply?.system)
    : [];

  if (!story) {
    return (
      <Container classes="flex justify-center items-center">
        <div>
          <Spinner size={40} />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Header title="Story" stack />
      <div className="xsm:bg-gradient-to-b from-gradiantColor1 to-gradiantColor2 mx-5 py-1 my-2 rounded-xl px-3">
        <div className="max-w-screen-xl mx-auto my-3">
          <StoryAuthorInfo authorId={story?.author} createdAt={story?.createdAt} />
        </div>
        <div className="max-w-screen-xl mx-auto my-3 md:space-x-5 flex flex-col md:flex-row justify-between">
          {story?.photos?.length > 0 && (
          <PhotosCarousel photos={story?.photos} imgStyles="xsm:rounded-xl" />
          )}
          <div className="w-full md:w-1/2 space-y-2">
            <div className="flex items-center justify-between w-full xsm:mt-5">
              <ShowMoreText lines={5} className="text-white text-left mb-1 text-xl xsm:text-lg">
                <p className="text-white text-xl xsm:text-lg">
                  {`${story?.text}`}
                </p>
              </ShowMoreText>
              <StoryLikeButton authorId={story?.author} likes={story?.likes} storyId={id} />
            </div>
            <StoryReply story={story} />
          </div>
        </div>

      </div>
      <div className="w-full my-10 h-96 rounded-xl overflow-y-auto bg-gradient-to-b from-gradiantColor1 to-gradiantColor2">
        <PaginationList
          loading={isLoading}
          data={flattenData}
          renderItem={renderItem}
          emptyText="No Replies"
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </div>
    </Container>
  );
};

const renderItem = (item) => <StoryReplyCard reply={item} />;

export default StoryScreen;
