import React, { useId } from 'react';
import PropTypes from 'prop-types';

import { getInitials } from '../util/functions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<Avatar />
============================================================================= */
function Avatar({
  border, color, source, name, size, tooltipContent, tooltipPosition, showFullName,
}) {
  const id = useId();
  const extraLarge = 'h-24 w-24 sm:h-40 sm:w-40 xs:h-12';
  const large = 'h-16 md:h-24 w-16 md:w-24 xs:h-12';
  const semi = 'h-16 w-16 sm:h-32 sm:w-32 xs:h-12';
  const extraMedium = 'h-12 w-12 sm:h-14 sm:w-14 xs:h-14';
  const medium = 'h-10 w-10 sm:h-12 sm:w-12 xs:h-12';
  const small = 'h-10 w-10 sm:h-10 sm:w-10 xs:h-12';

  return (
    <div
      className="flex"
      id={id}
      data-tooltip-content={tooltipContent}
      data-tooltip-place={tooltipPosition}
    >
      {source ? (
        <div
          className={
            classNames(
              size === 'extraLarge' && extraLarge,
              size === 'large' && large,
              size === 'semi' && semi,
              size === 'extraMedium' && extraMedium,
              size === 'medium' && medium,
              size === 'small' && small,
              border && 'ring-4 ring-white',
              'rounded-full overflow-hidden',
            )
          }
        >
          <img
            src={source}
            alt=""
            className={
              classNames(
                size === 'extraLarge' && extraLarge,
                size === 'large' && large,
                size === 'semi' && semi,
                size === 'extraMedium' && extraMedium,
                size === 'medium' && medium,
                size === 'small' && small,
              )
            }
          />
        </div>
      ) : (
        <div
          style={{ backgroundColor: color }}
          className={
            classNames(
              size === 'extraLarge' && extraLarge,
              size === 'large' && large,
              size === 'semi' && semi,
              size === 'extraMedium' && extraMedium,
              size === 'medium' && medium,
              size === 'small' && small,
              border && 'ring-4 ring-white',
              'rounded-full flex justify-center items-center',
            )
          }
        >
          <span
            className={classNames(
              size === 'extraLarge' && 'text-5xl',
              size === 'large' && 'text-4xl',
              size === 'semi' && 'text-2xl lg:text-4xl',
              size === 'small' && 'text-xs',
              'font-semibold leading-none text-white select-none',
            )}
          >
            {showFullName ? name : getInitials(name)}
          </span>
        </div>
      )}
    </div>
  );
}

Avatar.defaultProps = {
  size: 'large',
  name: '',
  color: '#dc2626',
  tooltipPosition: 'bottom',
  showFullName: false,
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['extraLarge', 'large', 'semi', 'extraMedium', 'medium', 'small']),
  name: PropTypes.string,
  color: PropTypes.string,
  tooltipPosition: PropTypes.string,
  showFullName: PropTypes.bool,
};

/* Export
============================================================================= */
export default Avatar;
