import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteDoc, doc } from 'firebase/firestore';
import { Table } from '../../common/Table/Table';
import request from '../../util/request';
import { firestore } from '../../config/firebase';

export const BlogsList = ({ loading, blogs }) => {
  const { push } = useHistory();
  const columns = [
    {
      key: 'title',
      title: 'Title',
      dbColName: 'title',
      render: value => `${value.slice(0, 50)}...`,
    },
    {
      key: 'slug',
      title: 'Slug',
      dbColName: 'slug',
      render: value => `${value.slice(0, 50)}...`,
    },
    {
      key: 'category',
      title: 'Category',
      dbColName: 'category',
    },
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      dbColName: 'thumbnail',
      render: row => (
        <img alt="" className="h-10 w-10 rounded-full" src={row} />
      ),
    },

    {
      key: 'actions',
      title: 'Actions',
      render: values => (
        <div className="flex gap-1">
          <button
            type="button"
            onClick={() => push(`/dashboard/add-blog?id=${values.id}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 text-blue-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
          <button type="button" onClick={() => handleDelete(values.id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 text-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        </div>
      ),
    },
  ];

  const handleDelete = async id => {
    if (window.confirm('Are you sure you want to delete')) {
      try {
        await deleteDoc(doc(firestore, 'blogs', id));
        push('/dashboard/blogs');
      } catch (error) {
        console.error('Error deleting blog:', error);
      }
    }
  };
  return (
    <Table
      data={blogs}
      columns={columns}
      loadingData={loading}
      totalItems={blogs.length}
    />
  );
};
