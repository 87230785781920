import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { firestore } from '../../config/firebase';
import MyDisclosure from '../../common/MyDisclosure';
import { About } from '../../components/dashboard/footer-pages/About';
import { Privacy } from '../../components/dashboard/footer-pages/Privacy';
import { Faq } from '../../components/dashboard/footer-pages/Faq';
import { Terms } from '../../components/dashboard/footer-pages/Terms';
import { Contact } from '../../components/dashboard/footer-pages/Contact';
import withAuth from '../../util/withAuth';

const FooterLinks = () => {
  const [state, setState] = useState({
    name: '',
    link: '',
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    // Create a query against the collection
    const q = query(collection(firestore, 'footer'));
    // Real-time updates listener
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const array = [];

      for (const d of querySnapshot.docs) {
        const link = {
          id: d.id,
          ...d.data(),
        };
        array.push(link);
      }

      setData(array);
    });

    // Detach listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete the link?')) {
      await deleteDoc(doc(firestore, 'footer', id));

      alert('Link deleted successfully');
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  };

  const handleSubmit = async () => {
    if (!state.name) {
      alert('Please type link name');
      return;
    }
    try {
      await addDoc(collection(firestore, 'footer'), state);

      alert('Link added successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const renderComponent = (link, id, content) => {
    switch (link) {
      case 'About us':
        return <About content={content} id={id} />;
      case 'Privacy':
        return <Privacy content={content} id={id} />;
      case 'Terms & Conditions':
        return <Terms content={content} id={id} />;
      case 'Contact Us':
        return <Contact content={content} id={id} />;
      case 'Frequently Questions':
        return <Faq content={content} id={id} />;
      default:
        return '';
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="self-start flex items-center gap-2">
        <input
          placeholder="Link title"
          value={state.name}
          onChange={(e) => setState({
            ...state,
            name: e.target.value,
          })}
          className="appearance-none  w-96 px-3   py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
        <buton
          type="button"
          onClick={handleSubmit}
          className="bg-primary cursor-pointer text-white rounded-md px-4 py-2"
        >
          Add Link
        </buton>
      </div>
      {data.map((item) => (
        <MyDisclosure
          key={item.id}
          title={item.name}
          onClickDelete={() => handleDelete(item.id)}
        >
          {renderComponent(item.name, item.id, item.content)}
        </MyDisclosure>
      ))}
    </div>
  );
};

export default withAuth(FooterLinks);
