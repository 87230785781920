import React from 'react';
import { X } from 'react-feather';
import { Draggable } from 'react-beautiful-dnd';

const ImageUploadImageCard = ({
  index, onDelete, url,
}) => (
  <Draggable draggableId={url} index={index}>
    {(provided) => (
      <li
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="relative w-52 mt-2"
      >
        <img src={url} alt={url} className="min-w-[208px] h-44  rounded-lg" />
        <button
          type="button"
          className="w-9 h-9 z-50 absolute top-0 right-0 rounded-full flex items-center justify-center bg-gray-600"
          onClick={onDelete}
        >
          <X color="#fff" size={22} />
        </button>
      </li>
    )}
  </Draggable>
);

export default ImageUploadImageCard;
