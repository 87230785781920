/* eslint-disable no-sequences */
/* eslint-disable no-shadow */
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {
  collection,
  endAt,
  limit, orderBy, query, startAfter,
  startAt,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import { useInfiniteQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import HomeHeader from '../components/HomeHeader';
import { firestore, postCollectionRef } from '../../config/firebase';
import { Container, PaginationList, PostCard } from '../../common';

import { setQuery as setQueryAction } from '../../post/redux/actions';
import PostInteractionButtons from '../../post/components/PostInteractionButtons';
import {
  fetchItems,
} from '../../util/functions';
import { useLocation } from '../../Provider/LocationProvider';

/* =============================================================================
<HomeScreen />
============================================================================= */
const HomeScreen = ({ setQuery }) => {
  const history = useHistory();
  const { location } = useLocation();
  const queryKey = ['posts'];

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey,
    initialPageParam: 0,
    queryFn: (page) => fetchItems(page, location),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  useEffect(() => {
    if (location?.radius) {
      const fetchData = async () => {
        try {
          await refetch(); // First refetch to fetch the data
          await refetch(); // Second is ensure that fetched data is latest
        } catch (error) {
          toast.error(error);
        }
      };
      fetchData();
    }
  }, [location, location?.radius, fetchNextPage, refetch]);

  const flattenData = data?.pages
    ? data?.pages?.flatMap((page) => [...page?.data])
      .sort((a, b) => (!location?.radius ? Number(b?.isFeatured) - Number(a?.isFeatured) : 0))
    : [];

  const renderItem = useCallback(
    (item, index) => (
      <PostCard
        item={item}
        key={index?.toString()}
        onPress={() => {
          setQuery({ index, queryKey });
          history.push('/post');
        }}
      >
        <PostInteractionButtons postId={item.id} />
      </PostCard>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [],
  );

  return (
    <Container classes="h-[100vh]">
      <Helmet>
        <title>Carsnus</title>
      </Helmet>
      <HomeHeader />
      <PaginationList
        horizontal
        center={false}
        data={flattenData}
        loading={isLoading}
        styles="mx-5 xl:mx-12 mt-0 xsm:mt-0"
        emptyText="No post found"
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        renderItem={renderItem}
      />
    </Container>
  );
};

const mapDispatchToProps = {
  setQuery: setQueryAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(HomeScreen);
