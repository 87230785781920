/* eslint-disable react/react-in-jsx-scope */
import { IoIosBoat, IoMdCar } from 'react-icons/io';
import { Tool, Truck } from 'react-feather';
import { MdOutlineTwoWheeler, MdHandyman } from 'react-icons/md';
import { FaTrailer, FaToolbox } from 'react-icons/fa';

export default [
  {
    id: 'cars',
    title: 'Cars & Trucks ',
    slugs: ['car', 'truck', 'van', 'sports', 'racing'],
    icon: <IoMdCar className="w-8 h-8" color="#F5F5F5" />,
    image: '/image.png',
  },
  {
    id: 'heavy_duty',
    title: 'Heavy Duty Trucks',
    slugs: [
      'trucks',
      'jeep',
    ],
    icon: <Truck className="w-8 h-8" color="#F5F5F5" />,
    image: '/truck-removebg-preview.png',
  },
  {
    id: 'motorcycle',
    title: 'Motorcycles',
    slugs: [
      'bike',
      'bikes',
      'dirt',
      'touring',
      'racing',
    ],
    icon: <MdOutlineTwoWheeler className="w-8 h-8" color="#F5F5F5" />,
    image: '/bike.png',
  },
  {
    id: 'boat',
    title: 'Boats',
    slugs: [
      'boat',
      'boats',
      'yacht',
      'cruise',
      'jet ski',
      'sail boat',
    ],
    image: '/boat.png',
    icon: <IoIosBoat className="w-8 h-8" color="#F5F5F5" />,
  },
  {
    id: 'trailer',
    title: 'Trailers',
    slugs: ['van', 'trailer'],
    icon: (
      <FaTrailer className="w-8 h-8" color="#F5F5F5" />
    ),
    image: '/pngimg.png',
  },
  {
    id: 'machinery',
    title: 'Machinery',
    slugs: ['machinery'],
    icon: <Tool className="w-8 h-8" color="#F5F5F5" />,
    image: '/engine.png',
  },
  {
    id: 'other',
    title: 'Others',
    slugs: [],
    icon: (
      <FaToolbox className="w-8 h-8" color="#F5F5F5" />
    ),
    image: '/image.png',
  },
  {
    id: 'part',
    title: 'Parts',
    slugs: [],
    icon: (
      <MdHandyman className="w-8 h-8" color="#F5F5F5" />
    ),
    image: '/parts-removebg-preview.png',
  },
];
