import React, { useContext, useState } from 'react';
import moment from 'moment';
import { doc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { Edit2, Trash } from 'react-feather';
import { NavLink, useHistory } from 'react-router-dom';
import { useFirestoreDocumentDeletion } from '@react-query-firebase/firestore';

import { AlertModal, Button } from '../../common';
import { client } from '../../config/react-query';
import { postCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<PostListItem />
============================================================================= */
const PostListItem = ({ post, onClick }) => {
  const { userId } = useContext(UserContext);
  const navigation = useHistory();
  const [confirmationModal, setConfirmationModal] = useState(false);

  const isCurrentUserPost = post?.author === userId;
  const postTitle = `${post?.year || ''} ${post?.make || post?.titleVehicle} ${
    post?.model || ''
  }`;

  const ref = doc(postCollectionRef, post?.id);
  const { mutateAsync: deletePost, isLoading } = useFirestoreDocumentDeletion(
    ref,
    {
      onSuccess() {
        client.invalidateQueries(['posts']);
        client.invalidateQueries(['myPosts']);
        _toggleConfirmationModal();
      },
    },
  );

  const _toggleConfirmationModal = () => setConfirmationModal(prev => !prev);

  const _handleDelete = async () => {
    try {
      await deletePost();
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div type="button" className="flex flex-col md:flex-row w-full md:items-center md:space-x-6 bg-lightBlack pl-4 rounded-2xl pr-4 py-2 xsm:pr-0 xsm:pl-0 xsm:py-0 xsm:overflow-hidden">
      <button type="button" onClick={onClick}>
        <img src={post?.photos[0]} className="w-full md:w-[250px] h-48 xsm:w-full rounded-lg xsm:rounded-none xsm:object-cover" alt={post?.id} />
      </button>
      <div className=" block w-ful px-3 pt-3 pb-2 sm:hidden">
        <button type="button" onClick={onClick}>
          <h1 className="text-white text-2xl font-semibold xsm:text-xl">
            {`${postTitle}`}
          </h1>
        </button>
      </div>
      <div className="w-full pt-1 pb-5 sm:py-5 md:py-0 flex-1 flex justify-between items-center px-3 sm:px-0">
        <button type="button" onClick={onClick} className={`flex-1 text-left flex flex-col sm:justify-between  sm:space-y-4 ${isCurrentUserPost && 'mb-14'} sm:mb-6`}>
          <h1 className="hidden text-white text-2xl font-semibold xsm:text-xl sm:block">
            {`${postTitle}`}
          </h1>
          <p className="text-white text-lg">
            {moment(post?.createdAt.toDate()).format('L')}
          </p>
        </button>
        <div className="flex flex-col justify-between items-end space-y-4">
          <h2 className="text-white text-2xl font-bold xsm:text-xl xsm:font-semibold">
            {`$ ${post?.price}`}
          </h2>
          {isCurrentUserPost && (
            <div className="flex items-center space-x-2 md:space-x-4">
              <button
                type="button"
                className="border border-white p-2  rounded-md hover:bg-lightWhite"
                onClick={() => navigation.push(`/post/edit/${post?.id}`)}
              >
                <Edit2 color="#F5F5F5" size={18} />
              </button>
              <button
                type="button"
                className="border border-white p-2  rounded-md hover:bg-lightWhite"
                onClick={_toggleConfirmationModal}
              >
                <Trash color="#F5F5F5" size={18} />
              </button>
              {post?.isFeatured ? (
                <p className="text-secondary text-lg">Featured</p>
              ) : (
                <Button
                  title="Go Top"
                  variant="secondary"
                  customTxtStyles="xsm:text-base"
                  onClick={() => navigation.push(`/payment/featured/${post?.id}`)}
                />
              )}

            </div>
          )}
        </div>
      </div>
      <AlertModal
        heading="Alert"
        loading={isLoading}
        onSubmit={_handleDelete}
        visible={confirmationModal}
        onClose={_toggleConfirmationModal}
      >
        <h1 className="text-white text-center text-lg">
          Are you sure you want to delete this post
        </h1>
      </AlertModal>
    </div>
  );
};

/* Export
============================================================================= */
export default PostListItem;
