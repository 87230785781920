import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

// import { Notfound } from '../common';
import HomeScreen from '../home/screens/HomeScreen';
import PostScreens from '../post/screens/PostScreens';
import SearchScreen from '../home/screens/SearchScreen';
import ChatScreens from '../chat/screens/ChatScreens';
import ProfileScreen from '../profile/screens/ProfileScreen';
import StoriesScreens from '../stories/screens/StoriesScreens';
import PaymentScreens from '../payment/screens/PaymentScreens';
import SettingsScreens from '../settings/screens/SettingsScreens';
import NotificationsScreen from '../notifications/screens/NotificationsScreen';
import FavoritePostsScreens from '../favoritePosts/screens/FavoritePostsScreens';
import SavedNotificationsScreen from '../notifications/screens/SavedNotificationsScreen';
import HomeCategoryScreen from '../home/screens/HomeCategoryScreen';
import Register from '../common/AuthModal/Register';
import Login from '../common/AuthModal/Login';
import DealerRegister from '../common/AuthModal/DealerRegister';
import usePageTracking from '../hooks/usePageTracking';
import DashboardScreens from '../dashboard/DashboardScreens';
import LandingScreens from '../landing/LandingScreens';
import PostByPrice from '../post/screens/PostByPrice';
import ScrollToTop from '../components/ScrolltoTop';

/* =============================================================================
<AppNavigation />
============================================================================= */
function AppNavigation() {
  usePageTracking();

  return (
    <Switch>
      {/* <Redirect from="/" to="/home" exact /> */}
      <Route path="/" exact>
        <HomeScreen />
      </Route>

      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/register" exact>
        <Register />
      </Route>
      <Route path="/dealer/register" exact>
        <DealerRegister />
      </Route>
      <Route path="/category/:categoryId" exact>
        <HomeCategoryScreen />
      </Route>
      <Route path="/settings">
        <SettingsScreens />
      </Route>
      <Route path="/notifications">
        <NotificationsScreen />
      </Route>
      <Route path="/cars" exact>
        <PostByPrice />
      </Route>
      <Route path="/saved-alerts">
        <SavedNotificationsScreen />
      </Route>
      <Route path="/favorites">
        <FavoritePostsScreens />
      </Route>
      <Route path="/post">
        <PostScreens />
      </Route>
      <Route path="/profile/:id">
        <ProfileScreen />
      </Route>
      <Route path="/payment">
        <PaymentScreens />
      </Route>
      <Route path="/search">
        <SearchScreen />
      </Route>
      <Route path="/stories">
        <StoriesScreens />
      </Route>
      <Route path="/chat">
        <ChatScreens />
      </Route>

      {/* Dashboard Routes */}
      <Route path="/dashboard">
        <DashboardScreens />
      </Route>
      {/* Dashboard Routes End */}

      {/* Landing Page Routes */}
      <Route path="/">
        <LandingScreens />
      </Route>
      {/* Landig Page Routes End */}

      {/* <Route path="*">
            <Notfound />
          </Route> */}
    </Switch>
  );
}

/* Export
============================================================================= */
export default AppNavigation;
