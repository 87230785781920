import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Dealer = () => (
  <>
    <Helmet>
      <title>Carsnus - Dealer</title>
    </Helmet>
    <div className="bg-primary">
      <div className="max-w-4xl p-4 md:p-0 mx-auto">
        <div className="pt-8 flex items-center justify-center gap-6 md:gap-32">
          <div className="flex flex-col items-center gap-3">
            <p className="text-white text-3xl md:text-6xl drop-shadow-lg text-center md:leading-snug font-bold">
              Grow Your
              <br />
              {' '}
              Sales Inventory
              <br />
              {' '}
              With Us
            </p>
            <Link to="/dealer/register" className="bg-secondary w-auto flex items-center justify-center px-6 py-2.5 text-white rounded-md font-semibold border border-white">Become a dealer</Link>
          </div>
          <div className="w-44 md:w-72 -mb-24">
            <img src="/Screenshot1.png" alt="" className="h-full object-cover w-full" />
          </div>
        </div>

      </div>
    </div>
    <div className="container p-4 mx-auto m-24">
      <p className="text-center text-3xl font-semibold drop-shadow-sm">Our Services</p>
      <p className="text-center mt-2 text-xl">Empower your team with our tool designed for your dealership</p>
      <div className="flex flex-wrap justify-center items-center w-full mx-auto mt-3">
        <div className="flex-1 flex items-center justify-center flex-col ">
          <div className="h-32 w-32 flex-shrink-0">
            <img alt="" src="/1.png" className="object-cover h-full w-full" />
          </div>
          <p className="w-80 text-xl text-center">
            Maximize efficiency and minimize hassle using our optimized auto dealer
            services
          </p>
        </div>
        <div className="flex-1 flex items-center justify-center flex-col ">
          <div className="h-32 w-32 flex-shrink-0">
            <img alt="" src="/2.png" className="object-cover h-full w-full" />
          </div>
          <p className="w-80 text-xl text-center">
            Improve your sales results with our featured automotive
            services
          </p>
        </div>
        <div className="flex-1 flex items-center justify-center flex-col ">
          <div className="h-32 w-32 flex-shrink-0">
            <img alt="" src="/3.png" className="object-cover h-full w-full" />
          </div>
          <p className="w-80 text-xl text-center">Boost your profits using our cutting-edge service</p>
        </div>
      </div>

    </div>
    <div className="relative flex items-center justify-center">

      <div className="absolute inset-0 bg-white clip-top-tilt" />
      <div className="absolute inset-0 bg-gray-200 clip-bottom-tilt" />

      <div className="container pb-4 mx-auto">
        <div className="relative z-10 text-center">
          <div className="flex flex-col md:flex-row items-center justify-center gap-10">
            <div className="w-44 md:w-72">
              <img alt="" src="/pic2.png" className="h-full w-full object-cover" />
            </div>
            <p className="w-full md:w-1/2 px-4 md:px-0 text-center text-secondary drop-shadow-md text-2xl md:text-5xl font-semibold md:leading-snug">
              Navigate effortlessly and simplify your tasks using
              our intuitive tool
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="relative flex bg-gray-200 items-center justify-center ">

      <div className="container p-4 mx-auto mt-12">
        <div className="relative z-10 text-center">
          <div className="flex flex-col md:flex-row  items-center justify-center gap-10">

            <div className="w-full md:w-1/3 flex flex-col">
              <p className=" px-4 md:px-0  text-center text-secondary drop-shadow-md text-2xl md:text-5xl font-semibold md:leading-snug">
                Are you ready to explode your sales?
              </p>
              <p className="text-zinc-800 text-center text-xl font-medium mt-3">Select one of our package</p>
              <div className="flex mt-3 gap-2">
                <div className="flex-1">
                  <p className="text-2xl mb-1 md:text-4xl font-bold text-primary">$ 60</p>
                  <div className="bg-white rounded-lg border border-black px-5 py-3">
                    60 Vehicles
                  </div>
                </div>
                <div className="flex-1">
                  <p className="text-2xl mb-1 md:text-4xl font-bold text-primary">$ 100</p>
                  <div className="bg-white rounded-lg border border-black px-5 py-3">
                    120 Vehicles
                  </div>
                </div>
              </div>
            </div>
            <div className="w-44 md:w-80">
              <img alt="" src="/pic3.png" className="h-full w-full object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mx-auto  max-w-6xl mt-12">
      <div className=" max-w-3xl text-center sm:text-center md:mx-auto ">
        <h2 className="font-heading  font-bold tracking-tight text-gray-900  text-3xl sm:text-5xl">
          Contact us or Register Online
        </h2>
      </div>
      <div className="flex items-center flex-col md:flex-row">
        <div className="h-full w-full px-4 md:w-1/3 pr-6">
          <ul className="mb-6 md:mb-0">
            <li className="flex">
              <div className="flex h-10 w-10 items-center justify-center rounded bg-primary text-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-6 w-6"
                >
                  <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                  <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                </svg>
              </div>
              <div className="ml-4 mb-4">
                <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 ">
                  Our Address
                </h3>
                <p className="text-gray-600 dark:text-slate-400">
                  1230 Maecenas Street Donec Road
                </p>
                <p className="text-gray-600 dark:text-slate-400">
                  New York, EEUU
                </p>
              </div>
            </li>
            <li className="flex">
              <div className="flex h-10 w-10 items-center justify-center rounded bg-primary text-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-6 w-6"
                >
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                  <path d="M15 7a2 2 0 0 1 2 2" />
                  <path d="M15 3a6 6 0 0 1 6 6" />
                </svg>
              </div>
              <div className="ml-4 mb-4">
                <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                  Contact
                </h3>
                <p className="text-gray-600 dark:text-slate-400">
                  Mobile: +1 (123) 456-7890
                </p>
                <p className="text-gray-600 dark:text-slate-400">
                  Mail: tailnext@gmail.com
                </p>
              </div>
            </li>
            <Link to="/dealer/register" className="bg-primary text-white rounded-md px-4 py-2.5 mt-4">Register as a Dealer</Link>
          </ul>
        </div>
        <div className="card h-fit w-full md:flex-1 p-5 md:p-12" id="form">

          <form id="contactForm">
            <div className="mb-6">
              <div className="mx-0 mb-1 sm:mb-4">
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="name"
                    className="pb-1 text-xs uppercase tracking-wider"
                  >
                    <input
                      type="text"
                      id="name"
                      autoComplete="given-name"
                      placeholder="Dealer name"
                      className="mb-2 w-full rounded-md border bg-zinc-100 focus:outline-none focus:border-primary border-gray-400 py-2 pl-2 pr-4 shadow-sm sm:mb-0"
                      name="name"
                    />
                  </label>
                </div>
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="email"
                    className="pb-1 text-xs uppercase tracking-wider"
                  >
                    <input
                      type="email"
                      id="email"
                      autoComplete="email"
                      placeholder="Your email address"
                      className="mb-2 w-full rounded-md border bg-zinc-100 focus:outline-none focus:border-primary border-gray-400 py-2 pl-2 pr-4 shadow-sm  sm:mb-0"
                      name="email"
                    />
                  </label>
                </div>
              </div>
              <div className="mx-0 mb-1 sm:mb-4">
                <label
                  htmlFor="textarea"
                  className="pb-1 text-xs uppercase tracking-wider"
                >
                  <input
                    type="text"
                    id="ematextl"
                    autoComplete="text"
                    placeholder="Contact"
                    className="mb-2 w-full rounded-md border bg-zinc-100 focus:outline-none focus:border-primary border-gray-400 py-2 pl-2 pr-4 shadow-sm  sm:mb-0"
                    name="email"
                  />
                </label>
              </div>
              <div className="mx-0 mb-1 sm:mb-4">
                <label
                  htmlFor="textarea"
                  className="pb-1 text-xs uppercase tracking-wider"
                >
                  <input
                    type="text"
                    id="ematextl"
                    autoComplete="text"
                    placeholder="Phone"
                    className="mb-2 w-full rounded-md border bg-zinc-100 focus:outline-none focus:border-primary border-gray-400 py-2 pl-2 pr-4 shadow-sm  sm:mb-0"
                    name="phone"
                  />
                </label>
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="w-full bg-primary text-white px-6 py-3 font-xl rounded-full sm:mb-0"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
);

export default Dealer;
