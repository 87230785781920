import React, { useState } from 'react';
import {
  limit,
  query,
  where,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import StoryCard from '../components/StoryCard';
import { storiesCollectionRef } from '../../config/firebase';
import StoriesListHeader from '../components/StoriesListHeader';
import { Header, Container, PaginationList } from '../../common';

/* =============================================================================
<StoriesScreen />
============================================================================= */
const StoriesScreen = () => {
  const [selectedTopic, setSelectedTopic] = useState('All');

  // GETTING STORIES WITH PAGINATION
  const ref = query(
    storiesCollectionRef,
    limit(10),
    where('isDeleted', '==', false),
    orderBy('createdAt', 'desc'),
  );
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFirestoreInfiniteQueryData(
    ['stories'],
    ref,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(ref, startAfter(lastDocument?.createdAt || ''));
    },
    {},
  );

  const flattenData = data?.pages
    ? data?.pages?.flatMap(page => [...page])
    : [];

  const filteredData = selectedTopic === 'All'
    ? flattenData
    : flattenData?.filter(d => d?.topic === selectedTopic);

  return (
    <Container>
      <Header title="Latest Stories" stack />
      <div className="max-w-screen-lg my-5 mx-auto px-5 xsm:my-3 xsm:px-4">
        <StoriesListHeader selectedTopic={selectedTopic} onTopicSelect={setSelectedTopic} />
        <PaginationList
          center
          data={filteredData}
          loading={isLoading}
          renderItem={renderItem}
          hasNextPage={hasNextPage}
          emptyText="No Stories Found"
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          styles="xsm:px-0"
        />
      </div>
    </Container>
  );
};

const renderItem = (item) => (
  <StoryCard key={item?.id} storyId={item?.id} />
);

/* Export
============================================================================= */
export default StoriesScreen;
