/* eslint-disable camelcase */
export const carsMakes = [
  'Acura',
  'ALFA ROMEO',
  'AM GENERAL',
  'ASTON MARTIN',
  'AUDI',
  'BENTLEY',
  'BMW',
  'BUGATTI',
  'Buick',
  'CADILLAC',
  'Chevrolet',
  'CHRYSLER',
  'DODGE',
  'FERRARI',
  'FIAT',
  'FISKER',
  'Ford',
  'Freightliner',
  'GMC',
  'GENESIS',
  'HONDA',
  'HUMMER',
  'HYUNDAI',
  'INFINITI',
  'ISUZU',
  'JAGUAR',
  'JEEP',
  'KARMA',
  'KIA',
  'LAND ROVER',
  'LAMBORGHINI',
  'LEXUS',
  'LINCOLN',
  'LOTUS',
  'MAYBACH',
  'MASERATI',
  'MAZDA',
  'MCLAREN',
  'MERCEDES-BENZ',
  'MERCURY',
  'MINI',
  'MITSUBISHI',
  'NISSAN',
  'OLDSMOBILE',
  'PLYMOUTH',
  'PONTIAC',
  'PORSCHE',
  'RAM',
  'ROLLS-ROYCE',
  'SAAB',
  'SATURN',
  'SCION',
  'SMART',
  'SUBARU',
  'SUZUKI',
  'TESLA',
  'TOYOTA',
  'VOLKSWAGEN',
  'VOLVO',
  'YUGO',
];

export const heavy_dutyMakes = [
  'Caterpillar',
  'Chevrolet',
  'Ford',
  'Freightliner',
  'GMC',
  'Hino',
  'International',
  'Isuzu',
  'Kenworth',
  'Mack',
  'Nikola',
  'Peterbilt',
  'Sterling',
  'Tesla',
  'Volvo',
  'Western Star',
  'White GMC',
];

export const motorcycleMakes = [
  'BMW',
  'Can-Am',
  'Ducati',
  'Harley Davidson',
  'Honda',
  'Husqvarna',
  'Indian',
  'Kawasaki',
  'KTM',
  'Slingshot',
  'SSR Motorsports',
  'Suzuki',
  'Triumph',
  'Vespa',
  'Yamaha',
];

export const boatMakes = [
  'Bayliner',
  'Beneteau',
  'Bertram',
  'Carver',
  'Chaparral',
  'Chris-Craft',
  'Formula',
  'Grady-White',
  'Hatteras Yachts',
  'Hunter Marine',
  'Regal',
  'Sea Ray',
  'Silverton',
  'Sunseeker',
  'Wellcraft',
];

export const machineryMakes = [
  'Bobcat',
  'Caterpillar',
  'Case',
  'Genie',
  'Hyster',
  'International',
  'John Deere',
  'JLG',
  'Komatsu',
  'Others',
  'Volvo',
];

export const trailerMakes = [
  'Doepker Industries',
  'East Manufacturing',
  'Felling Trailers',
  'Fontaine Trailer',
  'Frehauf',
  'Great Dane',
  'Heil Trailer',
  'Hyundai',
  'Kentucky',
  'Mac Trailer',
  'Manac',
  'Pitts',
  'Utility',
  'Vanguard National',
  'Wabash',
];

export const allMakes = [
  ...carsMakes,
  ...heavy_dutyMakes,
  ...motorcycleMakes,
  ...boatMakes,
  ...machineryMakes,
  ...trailerMakes,
];
