import React, { useState } from 'react';
import { Sidebar } from './Sidebar';
import { Banner } from './Banner';
import { MobileSidebar } from './MobileSidebar';

export default function DashboardLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen bg-gray-50">
      <div className="block lg:hidden">
        <MobileSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:block">
        <Sidebar />
      </div>
      {/* <AuthProvider> */}
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Banner setSidebarOpen={setSidebarOpen} />
        <main className="focus:outline-none overflow-auto h-screen px-6 py-3">
          {children}
        </main>
      </div>
      {/* </AuthProvider> */}
    </div>
  );
}
