/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Container, Header } from '../../common';

/* =============================================================================
<TermsAndConditionScreen />
============================================================================= */
const TermsAndConditionScreen = () => (
  <Container>
    <Header title="Terms & Conditions" stack />
    <p className="text-white text-xl m-4 md:mx-20">
      We at Wasai LLC respect the privacy of your personal information and,
      as such, make every effort to ensure your information is protected and
      remains private. As the owner and operator of loremipsum.io (the
      "Website") hereafter referred to in this Privacy Policy as "Lorem
      Ipsum", "us", "our" or "we", we have provided this Privacy Policy to
      explain how we collect, use, share and protect information about the
      users of our Website (hereafter referred to as “user”, “you” or
      "your"). For the purposes of this Agreement, any use of the terms
      "Lorem Ipsum", "us", "our" or "we" includes Wasai LLC, without
      limitation. We will not use or share your personal information with
      anyone except as described in this Privacy Policy. This Privacy Policy
      will inform you about the types of personal data we collect, the
      purposes for which we use the data, the ways in which the data is
      handled and your rights with regard to your personal data.
      Furthermore, this Privacy Policy is intended to satisfy the obligation
      of transparency under the EU General Data Protection Regulation
      2016/679 ("GDPR") and the laws implementing GDPR. For the purpose of
      this Privacy Policy the Data Controller of personal data is Wasai LLC
      and our contact details are set out in the Contact section at the end
      of this Privacy Policy. Data Controller means the natural or legal
      person who (either alone or jointly or in common with other persons)
      determines the purposes for which and the manner in which any personal
      information are, or are to be, processed. For purposes of this Privacy
      Policy, "Your Information" or "Personal Data" means information about
      you, which may be of a confidential or sensitive nature and may
      include personally identifiable information ("PII") and/or financial
      information. PII means individually identifiable information that
      would allow us to determine the actual identity of a specific living
      person, while sensitive data may include information, comments,
      content and other information that you voluntarily provide. Lorem
      Ipsum collects information about you when you use our Website to
      access our services, and other online products and services
      (collectively, the “Services”) and through other interactions and
      communications you have with us. The term Services includes,
      collectively, various applications, websites, widgets, email
      notifications and other mediums, or portions of such mediums, through
      which you have accessed this Privacy Policy. We may change this
      Privacy Policy from time to time. If we decide to change this Privacy
      Policy, we will inform you by posting the revised Privacy Policy on
      the Site. Those changes will go into effect on the "Last updated" date
      shown at the end of this Privacy Policy. By continuing to use the Site
      or Services, you consent to the revised Privacy Policy. We encourage
      you to periodically review the Privacy Policy for the latest
      information on our privacy practices. BY ACCESSING OR USING OUR
      SERVICES, YOU CONSENT TO THE COLLECTION, TRANSFER, MANIPULATION,
      STORAGE, DISCLOSURE AND OTHER USES OF YOUR INFORMATION (COLLECTIVELY,
      "USE OF YOUR INFORMATION") AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU
      DO NOT AGREE WITH OR CONSENT TO THIS PRIVACY POLICY YOU MAY NOT ACCESS
      OR USE OUR SERVICES. Children's Privacy Lorem Ipsum does not knowingly
      collect personally identifiable information (PII) from persons under
      the age of 13. If you are under the age of 13, please do not provide
      us with information of any kind whatsoever. If you have reason to
      believe that we may have accidentally received information from a
      child under the age of 13, please contact us immediately at
      legal@wasai.co. If we become aware that we have inadvertently received
      Personal Information from a person under the age of 13, we will delete
      such information from our records. Information Provided Directly By
      You We collect information you provide directly to us, such as when
      you request information, create or modify your personal account,
      request Services, subscribe to our Services, complete a Lorem Ipsum
      form, survey, quiz or application, contact customer support, join or
      enroll for an event or otherwise communicate with us in any manner.
      This information may include, without limitation: name, date of birth,
      e-mail address, physical address, business address, phone number, or
      any other personal information you choose to provide.
    </p>
  </Container>
);

/* Export
============================================================================= */
export default TermsAndConditionScreen;
