import React, { useContext } from 'react';

import { Container, Header, Spinner } from '../../common';
import { UserContext } from '../../Provider/UserProvider';
import EditSellerProfileForm from '../components/EditSellerProfileForm';
import EditDealerProfileForm from '../components/EditDealerProfileForm';

/* =============================================================================
<EditProfileScreen />
============================================================================= */
const EditProfileScreen = () => {
  const { user } = useContext(UserContext);

  return (
    <Container>
      <Header
        stack
        title="Edit Profile"
        description="Edit your profile information"
      />
      {user?.type === 'private seller' && <EditSellerProfileForm />}
      {user?.type === 'dealer' && <EditDealerProfileForm />}
      {!user && (
        <div className="flex mt-80 justify-center items-center">
          <Spinner size={45} />
        </div>
      )}
    </Container>
  );
};

export default EditProfileScreen;
