/* eslint-disable react-hooks/rules-of-hooks */
import { Heart, Share2 } from 'react-feather';
import React, { useState, useEffect, useContext } from 'react';
import { Timestamp } from 'firebase/firestore';

import toast from 'react-hot-toast';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<PostInteractionButtons />
============================================================================= */
const PostInteractionButtons = ({ postId }) => {
  const { user, updateUser } = useContext(UserContext);

  if (!user) {
    return null;
  }

  // FAVORITE CHECK
  const [isFavorite, setIsFavorite] = useState(
    user?.favoritePosts?.find(f => f === postId),
  );

  useEffect(() => {
    setIsFavorite(user?.favoritePosts?.find(f => f === postId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  const _handleHeartPress = async () => {
    try {
      if (isFavorite) {
        setIsFavorite(false);
        updateUser({
          ...user,
          favoritePosts: user?.favoritePosts?.filter(f => f !== postId),
          updatedAt: Timestamp.now(),
        });
      } else {
        setIsFavorite(true);
        updateUser({
          ...user,
          favoritePosts: [...user?.favoritePosts, postId],
          updatedAt: Timestamp.now(),
        });
      }
    } catch (e) {
      setIsFavorite(prev => !prev);
    }
  };

  const _handleShareBtn = () => {
    navigator.clipboard.writeText(`https://carsnus-1262c.firebaseapp.com/post/${postId}`);
    toast.success('Link Copied');
  };

  return (
    <button
      type="button"
      className="absolute right-2 top-3 w-14 h-14 rounded-full flex items-center justify-center bg-lightWhite"
      onClick={_handleHeartPress}
    >
      {isFavorite ? (
        <Heart size={26} fill="red" color="transparent" />
      ) : (
        <Heart size={26} color="red" />
      )}
    </button>
  );
};

PostInteractionButtons.defaultProps = {};

const propsAreEqual = (prev, next) => prev?.postId === next?.postId;

export default React.memo(PostInteractionButtons, propsAreEqual);
