import React, { FC, Fragment, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../Provider/UserProvider';

export const Banner = ({ setSidebarOpen }) => {
  const { logout } = useContext(UserContext);
  return (
    <div className="relative z-10 flex-shrink-0 flex bg-white border-b shadow-sm p-3">
      <button
        type="button"
        className="pr-4 text-gray-500 focus:outline-none lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
      <div className="flex items-center justify-between w-full gap-3">
        {/* Profile dropdown */}
        <div />

        <Menu as="div" className=" relative">
          {({ open }) => (
            <>
              <Menu.Button className="max-w-xs gap-2 flex items-center focus:outline-none">

                <div className="h-11 w-11 bg-gray-500 rounded-full">
                  <img
                    alt="profile"
                    className="h-full w-full object-cover rounded-full"
                    src="https://via.placeholder.com/56x56"
                  />
                </div>
              </Menu.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right flex flex-col absolute right-0 mt-2 mr-3 w-52 rounded-md shadow-lg divide-y bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <Menu.Item>
                    <Link
                      to="/settings"
                      className="p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      Profile
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      type="button"
                      onClick={logout}
                      className="p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                        />
                      </svg>
                      Logout
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};
