import React, { useContext } from 'react';
import moment from 'moment';
import { Bell } from 'react-feather';
import { NavLink } from 'react-router-dom';

import { UserContext } from '../../Provider/UserProvider';
import { dealer, featuredPackages, seller } from '../../static/payments-data';

/* =============================================================================
<PaymentHistoryItem />
============================================================================= */
const PaymentHistoryItem = ({ item }) => {
  const { user } = useContext(UserContext);
  const type = item?.type;
  const paymentPackage = type === 'featured'
    ? featuredPackages.find(i => i?.priceId === item.priceId)
    : user?.type === 'private seller'
      ? seller.find(i => i?.priceId === item?.priceId)
      : Object.values(dealer)
        ?.flatMap(d => [...d])
        ?.find(d => d?.priceId === item.priceId);

  return (
    <NavLink
      to={`/payment/${item.id}`}
      className="flex items-center w-full space-x-10 shadow-md shadow-gray-700 rounded-full px-4 md:px-8 py-2 md:py-4 bg-neutral-700"
    >
      <div className="w-16 h-16 md:w-20 md:h-20 rounded-full bg-black flex items-center justify-center">
        <Bell color="#404040" fill="#404040" size={40} />
      </div>
      <div className="flex-1 h-full text-left">
        <h1 className="text-white text-xl md:text-3xl font-medium">
          {`${paymentPackage?.title || ''} Package $${paymentPackage?.price || ''}`}
        </h1>
        <p className="text-white text-sm md:text-lg">
          {moment(item.createdAt?.toDate()).fromNow()}
        </p>
      </div>
    </NavLink>
  );
};

/* Export
============================================================================= */
export default PaymentHistoryItem;
