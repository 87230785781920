import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { X } from 'react-feather';
import { collection, doc } from 'firebase/firestore';
import { useFirestoreDocumentDeletion } from '@react-query-firebase/firestore';

import { auth, firestore } from '../../../config/firebase';
import { UserContext } from '../../../Provider/UserProvider';
import { client } from '../../../config/react-query';
import { classNames } from '../../../util/functions';

/* =============================================================================
<HistorySearchHistoryItem />
============================================================================= */
const HistorySearchHistoryItem = ({ item, onSearchSelect, containerStyles }) => {
  const { guestId } = useContext(UserContext);
  const userId = auth?.currentUser?.uid;
  const searchHistoryCollection = collection(
    firestore,
    `searchHistory/${userId || guestId}/history`,
  );
  const historyItemRef = doc(searchHistoryCollection, item?.id);
  const { mutateAsync: deleteSearchHistory } = useFirestoreDocumentDeletion(historyItemRef);

  const _handleDelete = async () => {
    try {
      await deleteSearchHistory();

      client.invalidateQueries(['searchHistory']);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const _handlePress = () => {
    onSearchSelect(item?.searchTxt);
  };

  return (
    <div className={classNames('mt-5 w-full border-b border-white flex justify-between items-center ', containerStyles)}>
      <button type="button" className="flex-1 text-left px-2" onClick={_handlePress}>
        <h1 className="text-lg text-white">{item?.searchTxt}</h1>
      </button>
      <button type="button" className="h-full p-2 hover:bg-gray-800" onClick={_handleDelete}>
        <X color="#fff" size={22} />
      </button>
    </div>
  );
};

/* Export
============================================================================= */
export default HistorySearchHistoryItem;
