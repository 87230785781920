import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import { Button, Modal } from '../../common';

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#fff',
      fontSize: '18px',
      fontFamily: 'sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

/* =============================================================================
<PaymentModal />
============================================================================= */
const PaymentModal = ({
  title,
  loading,
  isVisible,
  onCancel,
  onSubmit,
}) => {
  const _handleClose = () => {
    if (!loading) {
      onCancel();
    }
  };

  const _handleSubmit = async (event) => {
    event.preventDefault();

    onSubmit(event.target.name.value);
  };

  return (
    <Modal heading={`${title} Package`} visible={isVisible} onClose={_handleClose}>
      <form onSubmit={_handleSubmit} className="space-y-10">
        <div>
          <input
            id="name"
            name="name"
            placeholder="Name on card"
            className="bg-black border-transparent focus:border-transparent focus:ring-0 text-lg px-8 w-full text-gray-200 placeholder-gray-300"
          />
        </div>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <Button
          title="Pay"
          type="submit"
          size="block"
          loading={loading}
          disabled={loading}
        />
      </form>
    </Modal>
  );
};

export default PaymentModal;
