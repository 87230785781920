/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Info } from 'react-feather';

import { classNames } from '../util/functions';

function TextArea({
  label, type, variant, errorText, icon, onIconClick, disabled, value, onChange, formProps, ...props
}) {
  console.log({ value });
  return (
    <div className="w-full">
      {label && (
        <div className="flex justify-between mb-1 ml-2">
          <label className="block text-lg font-medium text-white">
            {label}
          </label>
        </div>

      )}
      <div className="relative">
        <textarea
          type={type || 'text'}
          className={classNames(
            disabled ? 'text-gray-500' : null,
            variant === 'search' ? 'block w-full px-3  md:py-2.5 bg-lightWhite text-white rounded-full shadow-sm placeholder-white focus:outline-none  border focus:border-white focus:ring-white border-white '
              : `${errorText || value ? 'border border-primary focus:border-primary focus:ring-primary' : ' border focus:border-primary focus:ring-primary border-white'} appearance-none block w-full h-40 px-3 md:py-2.5 text-white bg-gradient-to-b from-gray-900 to-black  rounded-lg shadow-sm placeholder-white focus:outline-none  `,
          )}
          disabled={disabled}
          value={value}
          onChange={onChange}
          autoComplete={type === 'email' ? true : 'off'}
          {...formProps}
          {...props}
        />
        {icon && (
          <button
            type="button"
            onClick={onIconClick}
            className="absolute right-2 bottom-0 top-0 justify-center align-center px-2 py-1 rounded-sm hover:opacity-80"
            tabIndex="-1"
          >
            {icon}
          </button>
        )}
      </div>
      {!!errorText && (
        <div className="flex items-center space-x-1 mt-2">
          <Info color="#dc2626" size={20} />
          <p className="text-md font-medium  py-white text-red-500">
            {errorText}
          </p>
        </div>
      )}
    </div>
  );
}

export default TextArea;
